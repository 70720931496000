import {
  FETCH_ALLCOUPONS_REQUEST,
  FETCH_ALLCOUPONS_SUCCESS,
  FETCH_ALLCOUPONS_FALIURE,
  ADD_COUPON_REQUEST,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FALIURE
} from './AllCouponsConstants'
import {config} from '../config'

export const fetchAllCouponsRequest = () =>{
  return{
    type : FETCH_ALLCOUPONS_REQUEST
  }
}

export const fetchAllCouponsSuccess = (all_coupons_success) =>{
  return{
    type : FETCH_ALLCOUPONS_SUCCESS,
    payload : all_coupons_success
  }
}

export const fetchAllCouponsFaliure = (error) =>{
  return{
    type : FETCH_ALLCOUPONS_FALIURE,
    payload : error
  }
}

export const addCouponsRequest = () =>{
  return{
    type : ADD_COUPON_REQUEST
  }
}

export const addCouponsSuccess = (add_coupons_success) =>{
  return{
    type : ADD_COUPON_SUCCESS,
    payload : add_coupons_success
  }
}

export const addCouponsFaliure = (error) =>{
  return{
    type : ADD_COUPON_FALIURE,
    payload : error
  }
}

export const fetchAddCoupon = () =>{
    return(dispatch) => {
      dispatch(fetchAllCouponsRequest())
      const url =`${config.api_root}/api/v1/merchants/coupon/${config.mid}/build/setting`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(all_coupons_res =>{
      const all_coupons_success = all_coupons_res
      dispatch(fetchAllCouponsSuccess(all_coupons_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllCouponsFaliure(errorMsg))
    })
    }
}


export const fetchAllCouponsList = (currentPage) =>{
  return(dispatch) => {
    dispatch(fetchAllCouponsRequest())
    const url =`${config.api_root}/api/v1/merchants/coupon/describe/${config.mid}?pageNumber=${currentPage}&pageSize=10`;
    const request_option = {
    method: "GET",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(all_coupons_res =>{
    const all_coupons_success = all_coupons_res
    dispatch(fetchAllCouponsSuccess(all_coupons_success))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(fetchAllCouponsFaliure(errorMsg))
  })
  }
}

export const addcoupon = (timing_info) =>{
  return(dispatch) => {
    dispatch(addCouponsRequest(timing_info))
    const url =`${config.api_root}/api/v1/merchants/coupon/${config.mid}/build`;

    const request_option = {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+ config.token,
      'Content-Type': 'application/json'
  },
    body: JSON.stringify({
      code : timing_info.code,
      description : timing_info.description,
      validFrom : timing_info.validFrom,
      validTo :timing_info.validTo,
      minSpend : timing_info.minSpend,
      maxSpend :timing_info.maxSpend,
      minBenefit : timing_info.minBenefit,
      maxBenefit :timing_info.maxBenefit,
      fixed: timing_info.fixed,
      autoApply: timing_info.autoApply,
      amount : timing_info.amount,
      couponDiscountType: timing_info.couponDiscountType,
      couponType: timing_info.couponType
    })
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(all_coupons_res =>{
    const all_coupons_success = all_coupons_res
    console.log(all_coupons_success);
    if(all_coupons_success.request_status === false){
      dispatch(addCouponsFaliure(all_coupons_success))
    }else{
      dispatch(addCouponsSuccess(all_coupons_success))
      dispatch(fetchAllCouponsList(0))
    }
    
//    dispatch(addTimingSuccess(add_timing_success))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(addCouponsFaliure(errorMsg))
  })
}
}


// delete coupon
export const deleteCoupon = (addon_id) =>{
  return(dispatch) => {
  dispatch(fetchAllCouponsRequest(addon_id))
    const url =`${config.api_root}/api/v1/merchants/coupon/${config.mid}/${addon_id}`;
    const request_option = {
    method: "DELETE",
    headers: {
      'Authorization': 'Bearer '+ config.token,
      'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(exam_res =>{
    const all_rest_timings_success = exam_res
    //dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
    dispatch(fetchAllCouponsList(0))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(fetchAllCouponsFaliure(errorMsg))

  })
  }
}