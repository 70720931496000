import React,{useState}from 'react';
import { Link } from 'react-router-dom';
import UpdateRestSettings from './UpdateRestSettings'

function PrinterSettings() {

const [printerOnOff,setPrinterOnOff] = useState(false)



const setprinterStatus = (event) =>{
  setPrinterOnOff(!printerOnOff)
  //  dispatch(deliveryShutUpdate(!deliveryOnOff))
}

        return (
          <>
          <div className="breadcrumbs-area">
            <h3>Restaurant Settings</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Restaurant Settings</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
         <div className="row dashboard-card-six">
        <UpdateRestSettings />
           </div>
          </>      
        );
}

export default PrinterSettings;
