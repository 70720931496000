import React from 'react';
import { Link } from 'react-router-dom';
import DownloadReport from './DownloadReport';



function AllReports() {
        return (            
          <>
          <div className="breadcrumbs-area">
            <h3>Download Reports</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Download Reports</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
         <div className="row dashboard-card-six">
             <DownloadReport />

           </div>
          </>
        );
}

export default AllReports;
