import {
  FETCH_ALLBOOK_REQUEST,
  FETCH_ORDERDETAIL_REQUEST,
  FETCH_ORDERDETAIL_SUCCESS,
  FETCH_ORDERDETAIL_FALIURE,
  FETCH_ALLBOOK_SUCCESS,
  FETCH_ALLDETAIL_SUCCESS,
  FETCH_ALLBOOK_ADD_SUCCESS,
  FETCH_ALLBOOK_FALIURE,
  FETCH_SINGLEORDERSTATUS_REQUEST,
  FETCH_SINGLEORDERSTATUS_SUCCESS,
  FETCH_SINGLEORDERSTATUS_FALIURE,
  FETCH_SINGLEORDERSTATUS_CANCEL,
  FETCH_ALLADDON_REQUEST,
  FETCH_ALLADDON_SUCCESS,
  FETCH_ALLADDON_FALIURE,
  DELETE_ADDON_REQUEST,
  DELETE_ADDON_SUCCESS,
  DELETE_ADDON_FALIURE

} from './AllBookConstants'
import {config} from '../config'
import { useState } from 'react'

export const fetchAllBookRequest = (book_info) =>{
  return{
    type : FETCH_ALLBOOK_REQUEST,
    payload:book_info
  }
}

export const fetchOrderDetailRequest = (book_info) =>{
  return{
    type : FETCH_ORDERDETAIL_REQUEST,
    payload:book_info
  }
}

export const fetchAllBookSuccess = (all_book_success) =>{
  return{
    type : FETCH_ALLBOOK_SUCCESS,
    payload : all_book_success
  }
}

export const fetchAllDetailSuccess = (all_detail_success) =>{
  return{
    type : FETCH_ALLDETAIL_SUCCESS,
    payload : all_detail_success
  }
}

export const fetchAllBookAddSuccess = (all_book_success) =>{
  return{
    type : FETCH_ALLBOOK_ADD_SUCCESS,
    payload : all_book_success
  }
}


export const deleteAddonRequest = (delete_addon) =>{
  return{
    type : DELETE_ADDON_REQUEST,
    payload: delete_addon
  }
}

export const deleteAddonSuccess = (delete_addon_success) =>{
  return{
    type : DELETE_ADDON_SUCCESS,
    payload: delete_addon_success
  }
}

export const deleteAddonFaliure = (error) =>{
  return{
    type : DELETE_ADDON_FALIURE,
    payload : error
  }
}

export const fetchAllAddonRequest = (all_addon) =>{
  return {
    type : FETCH_ALLADDON_REQUEST,
    payload:all_addon
  }
}

export const fetchAllAddonSuccess = (all_addon_success) =>{
  return {
    type : FETCH_ALLADDON_SUCCESS,
    payload:all_addon_success
  }
}

export const fetchAllAddonFailure = (error) =>{
  return {
    type : FETCH_ALLADDON_FALIURE,
    payload : error
  }
}

export const fetchOrderDetailSuccess = (order_detail_success) =>{
  return{
    type : FETCH_ORDERDETAIL_SUCCESS,
    payload : order_detail_success
  }
}

export const fetchOrderDetailFaliure = (error) =>{
  return{
    type : FETCH_ORDERDETAIL_FALIURE,
    payload : error
  }
}

export const fetchAllBookFaliure = (error) =>{
  return{
    type : FETCH_ALLBOOK_FALIURE,
    payload : error
  }
}

export const fetchSingleOrderStatusRequest = (all_book_success) =>{
  return{
    type : FETCH_SINGLEORDERSTATUS_REQUEST,
    payload: all_book_success
  }
}

export const fetchSingleOrderStatusSuccess = (all_book_success) =>{
  return{
    type : FETCH_SINGLEORDERSTATUS_SUCCESS,
    payload : all_book_success
  }
}

export const fetchSingleOrderStatusFaliure = (error) =>{
  return{
    type : FETCH_SINGLEORDERSTATUS_FALIURE,
    payload : error
  }
}

export const fetchSingleOrderCancelStatus = (all_book_success) =>{
  return{
    type : FETCH_SINGLEORDERSTATUS_CANCEL,
    payload : all_book_success
  }
}


export const fetchAllBook = (page, isAdded, book_info) =>{
  // console.log('apireq', book_info );
    return(res) => {
      res(fetchAllBookRequest(book_info))
      // const writter = book_info != undefined ? book_info.writter != "undefined" ? book_info.writter : undefined :undefined
      // const classId = book_info != undefined ? book_info.classId != "undefined"  ? book_info.classId : undefined : undefined
      // const published = book_info != undefined ? book_info.published != "undefined" ? book_info.published : undefined : undefined
      // const subjectId = book_info != undefined ? book_info.subjectId != "undefined"  ? book_info.subjectId : undefined :undefined
      // const bookType = book_info != undefined ? book_info.bookType != "undefined"  ? book_info.bookType : undefined :undefined
      const selectedFilter = book_info != undefined && book_info.filter != "undefined" && book_info.filter != "all" ? book_info.filter : undefined

      const dateFilter = book_info && book_info.dateFilter ? `&start_date=${book_info.dateFilter.startDate}&end_date=${book_info.dateFilter.endDate}` : '';


      const url =`${config.api_root}/api/v1/users/get_orders_v2?type=RB&user_id=${config.user_id}&length=10&offset=${page}${selectedFilter != undefined ? `&order_status=${selectedFilter}`: '&order_status=2'}${dateFilter}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_book_success = book_res
      // console.log('orders==>'+JSON.stringify(all_book_success))
      if(isAdded) {
        res(fetchAllBookAddSuccess(all_book_success))
      } else {
        res(fetchAllBookSuccess(all_book_success))
      }
      if(!all_book_success.request_status) {
        res(fetchAllBookFaliure(all_book_success.object))
      }
    })
    .catch(error => {
      const errorMsg = error
      // console.log('error-->'+errorMsg)
      res(fetchAllBookFaliure(errorMsg))
    })
    }
}

export const fetchAllDetails = (page, isAdded, book_info) =>{
  // console.log('apireq', book_info );
    return(res) => {
      res(fetchAllBookRequest(book_info))
      // const writter = book_info != undefined ? book_info.writter != "undefined" ? book_info.writter : undefined :undefined
      // const classId = book_info != undefined ? book_info.classId != "undefined"  ? book_info.classId : undefined : undefined
      // const published = book_info != undefined ? book_info.published != "undefined" ? book_info.published : undefined : undefined
      // const subjectId = book_info != undefined ? book_info.subjectId != "undefined"  ? book_info.subjectId : undefined :undefined
      // const bookType = book_info != undefined ? book_info.bookType != "undefined"  ? book_info.bookType : undefined :undefined
      const selectedFilter = book_info != undefined && book_info.filter != "undefined" && book_info.filter != "all" ? book_info.filter : undefined

      const dateFilter = book_info && book_info.dateFilter ? `&from=${book_info.dateFilter.startDate}&to=${book_info.dateFilter.endDate}` : '';

      
      const url =`${config.api_root}/api/v1/merchants/sales_summary?user_id=${config.user_id}${dateFilter}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_detail_success = book_res
      // console.log('orders==>'+JSON.stringify(all_book_success))
      if(isAdded) {
        res(fetchAllDetailSuccess(all_detail_success))
      } else {
        res(fetchAllDetailSuccess(all_detail_success))
      }
      if(!all_detail_success.request_status) {
        res(fetchAllBookFaliure(all_detail_success.object))
      }
    })
    .catch(error => {
      const errorMsg = error
      // console.log('error-->'+errorMsg)
      res(fetchAllBookFaliure(errorMsg))
    })
    }
}

export const fetchOrderDetail = (order_id) =>{
  // console.log('apireq', order_id );
    return(dispatch) => {
      dispatch(fetchOrderDetailRequest(order_id))
      const url =`${config.api_root}/api/v1/users/get_order?user_id=${config.user_id}&order_id=${order_id}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const order_detail_success = book_res
      // console.log('order==>'+JSON.stringify(order_detail_success))
      dispatch(fetchOrderDetailSuccess(order_detail_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchOrderDetailFaliure(errorMsg))
    })
    }
}

export const fetchOrder = (book_info) =>{
  // console.log('apireq', book_info );
    return(res) => {
      res(fetchAllBookRequest(book_info))
      const writter = book_info != undefined ? book_info.writter != "undefined" ? book_info.writter : undefined :undefined
      const classId = book_info != undefined ? book_info.classId != "undefined"  ? book_info.classId : undefined : undefined
      const published = book_info != undefined ? book_info.published != "undefined" ? book_info.published : undefined : undefined
      const subjectId = book_info != undefined ? book_info.subjectId != "undefined"  ? book_info.subjectId : undefined :undefined
      const bookType = book_info != undefined ? book_info.bookType != "undefined"  ? book_info.bookType : undefined :undefined
      const selectedFilter = book_info != undefined && book_info.filter != "undefined" && book_info.filter != "all" ? book_info.filter : undefined

      const url =`${config.api_root}/api/v1/users/get_orders_v2?type=RB&user_id=${config.user_id}&length=15&offset=0${selectedFilter != undefined ? `&order_status=${selectedFilter}`: '&order_status=2'}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_book_success = book_res
      // console.log('orders==>'+JSON.stringify(all_book_success))
      res(fetchAllBookSuccess(all_book_success))
    })
    .catch(error => {
      const errorMsg = error
      res(fetchAllBookFaliure(errorMsg))
    })
    }
}

export const fetchAllAddonsInOne = (book_info) =>{
  // console.log('apireq', book_info );
    return(dispatch) => {
      dispatch(fetchAllBookRequest(book_info))
      const writter = book_info != undefined ? book_info.writter != "undefined" ? book_info.writter : undefined :undefined
      const classId = book_info != undefined ? book_info.classId != "undefined"  ? book_info.classId : undefined : undefined
      const published = book_info != undefined ? book_info.published != "undefined" ? book_info.published : undefined : undefined
      const subjectId = book_info != undefined ? book_info.subjectId != "undefined"  ? book_info.subjectId : undefined :undefined
      const bookType = book_info != undefined ? book_info.bookType != "undefined"  ? book_info.bookType : undefined :undefined
      const selectedFilter = book_info != undefined && book_info.filter != "undefined" && book_info.filter != "all" ? book_info.filter : undefined

      const url =`${config.api_root}/api/v1/merchants/groups/add_on?user_id=${config.user_id}&size=100&page=0`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_book_success = book_res
      dispatch(fetchAllBookSuccess(all_book_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllBookFaliure(errorMsg))
    })
    }
}

export const fetchAllAddons = (book_info,currentPage) =>{
  // console.log('apireq', book_info );
    return(dispatch) => {
      dispatch(fetchAllBookRequest(book_info))
      console.log(fetchAllBookRequest(book_info));
      const writter = book_info != undefined ? book_info.writter != "undefined" ? book_info.writter : undefined :undefined
      const classId = book_info != undefined ? book_info.classId != "undefined"  ? book_info.classId : undefined : undefined
      const published = book_info != undefined ? book_info.published != "undefined" ? book_info.published : undefined : undefined
      const subjectId = book_info != undefined ? book_info.subjectId != "undefined"  ? book_info.subjectId : undefined :undefined
      const bookType = book_info != undefined ? book_info.bookType != "undefined"  ? book_info.bookType : undefined :undefined
      const selectedFilter = book_info != undefined && book_info.filter != "undefined" && book_info.filter != "all" ? book_info.filter : undefined

      const url =`${config.api_root}/api/v1/merchants/groups/add_on?user_id=${config.user_id}&size=10&page=${currentPage}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_book_success = book_res
      dispatch(fetchAllBookSuccess(all_book_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllBookFaliure(errorMsg))
    })
    }
}


const currentPage = 0;
const totalPages = 0;

export const deleteAddon = (addon_id) =>{
    return(dispatch) => {
    dispatch(fetchAllBookRequest(addon_id))
      const url =`${config.api_root}/api/v1/merchants/group/add_on/${addon_id}?user_id=${config.user_id}`;
      const request_option = {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
      const all_rest_timings_success = exam_res
      //dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
      dispatch(fetchAllAddons(totalPages,currentPage))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllBookFaliure(errorMsg))
    })
    }
}


export const acceptOrder = (order_id, orderTime) =>{
    return(dispatch) => {
    //dispatch(fetchAllBookRequest(order_id))
      dispatch(fetchSingleOrderStatusRequest(order_id))
      const url =`${config.api_root}/api/v1/users/order_status/?user_id=${config.user_id}&confirm=true&preparation_time=${orderTime}&order_id=${order_id}`;
      const request_option = {
      method: "POST",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
      const all_rest_timings_success = exam_res
      dispatch(fetchSingleOrderStatusSuccess(all_rest_timings_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchSingleOrderStatusFaliure(errorMsg))
    })
    }
}



export const declineOrder = (order_id) =>{
    return(dispatch) => {
    //dispatch(fetchAllBookRequest(order_id))
    dispatch(fetchSingleOrderStatusRequest(order_id))
      const url =`${config.api_root}/api/v1/users/order_status/?user_id=${config.user_id}&confirm=false&preparation_time=45&order_id=${order_id}`;
      const request_option = {
      method: "POST",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
      const all_rest_timings_success = exam_res
      //dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
      dispatch(fetchSingleOrderCancelStatus(all_rest_timings_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchSingleOrderStatusFaliure(errorMsg))
    //  dispatch(fetchAllBookFaliure(errorMsg))
    })
    }
}


export const fetchSingleAddons = (addon_group_id) =>{
  // console.log('apireq', addon_group_id );
    return(dispatch) => {
      dispatch(fetchAllAddonRequest(addon_group_id))

      const url =`${config.api_root}/api/v1/merchants/group/add_on/${addon_group_id}?user_id=${config.user_id}&size=100&page=0`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_book_success = book_res
      dispatch(fetchAllAddonSuccess(all_book_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllAddonFailure(errorMsg))
    })
    }
}

export const deleteSingleAddon = (group_addon_id,addon_id) =>{
    return(dispatch) => {
      dispatch(deleteAddonRequest(addon_id))
      const url =`${config.api_root}/api/v1/merchants/group/add_on/${group_addon_id}/${addon_id}?user_id=${config.user_id}`;
      const request_option = {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
      }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
       const delete_addon_success = exam_res
       //dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
       dispatch(deleteAddonSuccess(delete_addon_success))
    })
    .catch(error => {
       const errorMsg = error
       dispatch(deleteAddonFaliure(errorMsg))
    })
    }
}
