import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';
import DateTimePicker from 'react-datetime-picker';
import {config} from '../Redux/config'
import * as FormData from 'form-data'

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux'
import moment from 'moment';
import { fetchAllNotices, addEvent } from '../Redux/AllNotices/AllNoticeActions';
function AddEvent(){
const fs = require('fs');
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)

const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [inputValues,setInputValues] = useState({
                                          start_time: moment().format('DD/MM/YYYY HH:mm:ss'),
                                          end_time : moment().format('DD/MM/YYYY HH:mm:ss'),
                                          name:"",
                                          description:"",
                                          event_type:"EVENT",
                                          file:""

  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)
    const [errorMsg,setErrorMsg] = useState('')

const format = 'HH:mm';

const now = moment().hour(0).minute(0);

  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
   useMemo(()=>{
     if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.error){
       setError(addExam_data.add_timing.error)
     }
     else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.success){
        setSuccessStatus(addExam_data.add_timing.success)
     }
   },[addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
    //  console.log("classInfo",event.target.value)
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
// examHandler function start
   const examHandler = (event) =>{
   event.preventDefault()
   
   if (inputValues.file) {
    // Validate file type
    if (inputValues.file.type !== 'image/jpeg') {
      setErrorMsg('Only JPG images are allowed.');
    } else {
      const formData = new FormData();
      formData.append('name', inputValues.name);
      formData.append('description', inputValues.description);
      formData.append('start_time', inputValues.start_time);
      formData.append('end_time', inputValues.end_time);
      formData.append('file', inputValues.file);
      formData.append('event_type', inputValues.event_type);
      formData.append('user_id', config.user_id);
      setErrorMsg('');
      const exam_info = inputValues;
      dispatch(addEvent(formData))
      setCurrentPage(0);
      //dispatch(fetchAllNotices(totalPages,currentPage))
    }
   }  
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


const getBase64Path = (e) => {
  const file = e.target.files[0]
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    setInputValues({...inputValues,file})
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  }
}

// resetHandler function start
   const handleDateChange = (value) =>{
     const selectedData = moment(value).format('DD/MM/YYYY hh:mm:ss');

    setInputValues({...inputValues,start_time:selectedData})
    setStartDate(value)
   }


   const handleClosingTimeChange = (value) =>{

     const selectedData = moment(value).format('DD/MM/YYYY hh:mm:ss');

    setInputValues({...inputValues,end_time:selectedData})
    setEndDate(value)
    //  console.log('selectedTime', selectedTime);
   }
// resetHandler function start

// component function end

const [show,setShow] = useState(true);

        return (
            <div className="col-4-xxxl col-4 d-lg-block d-md-none">
                <div className="card height-auto">
                  <div className="card-body">
                    <div className="heading-layout1 bg-darkblue">
                      <div className="item-title">
                        <h3 className='text-light'>Add Specials</h3>
                        
                      </div>

                    </div>
                    <div className="success-greeting1"><h2 className="addtimeerror cl-center">{errorMsg}</h2></div>
                    {addExam_data.add_addon_loading ?
                          <div className='mg-l-40'>
                          <Loader
                            className = "cl-center"
                            type="MutatingDots"
                            color="#fea801"
                            height={150}
                            width={150}    
                          /></div>
                         : addExam_data.add_addon && addExam_data.add_addon.request_status && addExam_data.add_addon.request_status === true && show ? (<div className="success-greeting1">
                             <h2 className="addtimesuccess cl-center">Specails Added Successfully!</h2>
                            
                          </div>) : addExam_data.add_addon && addExam_data.add_addon.object && addExam_data.add_addon.object && addExam_data.add_addon.object.error ? (<div className="success-greeting1">
                             <h2 className="addtimeerror cl-center">{addExam_data.add_addon.object.error}</h2>
                          
                           </div>): null }

                           <form className="new-added-form" onSubmit={(e) =>examHandler(e)} encType="multipart/form-data">
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Specials Name *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.name} onChange={(e) =>setInputValues({...inputValues,name:e.target.value})} required/>
                          {error != null && error.name ? (<h6 className="addStudent-error">*{JSON.stringify(error.name).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Description</label>
                          <input type="text" placeholder className="form-control" value={inputValues.description} onChange={(e) =>setInputValues({...inputValues,description:e.target.value})} required/>
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                        <div className="col-12-xxxl col-lg-12 col-12 form-group">
                          <label>Start Time</label>
                          <DateTimePicker
                              onChange={(date) => handleDateChange(date)}
                              value={startDate}
                              className="form-control"
                            />
                        </div>
                        <div className="col-12-xxxl col-lg-12 col-12 form-group">
                          <label>End Time</label>
                        <DateTimePicker
                              onChange={(date) => handleClosingTimeChange(date)}
                              value={endDate}
                              className="form-control"
                            />
                        </div>

                        <div className="col-12-xxxl col-lg-12 col-12 form-group">
                        <FormGroup>
                            <FormLabel>Select Type *</FormLabel>
                            <FormControl
                              required
                              type="text"
                              onChange={(e) =>setInputValues({...inputValues, event_type:e.target.value})}
                              as="select"
                            >
                            <option value="EVENT">Event</option>
                            <option value="SPECIAL_DEAL">Special Deal</option>
                            </FormControl>
                            {error != null && error.event_type ? (<h6 className="addStudent-error">*{JSON.stringify(error.event_type).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>


                        <FormGroup controlId="formFile" className="col-12-xxxl col-lg-6 col-12 form-group">
                        <label>Image</label>
                        <input type="file" placeholder className="form-control" onChange={(e) =>getBase64Path(e)} required/>

                      </FormGroup>

                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                        <button type="submit" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Save</button> 
                        <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        
                        </div>
                      </div>
                    </form>
                  </div>
                
                </div>
              </div>
        );
}

export default AddEvent;

