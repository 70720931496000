import React, {useState,useEffect,useMemo} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { fetchAllDriver } from '../Redux/AllDriver/AllDriverActions';
import { deleteHoliday } from '../Redux/AddHoliday/AddHolidayActions'
import { Link } from 'react-router-dom';

function Holidays() {

  const dispatch = useDispatch()  // for accessing the redux function

  const [allHolidays,setAllHolidays] = useState([])
  const allholidaysData = useSelector(state =>state.AllDriver);
  
  // fetch allBook  api hook start
  useEffect(() =>{
    dispatch(fetchAllDriver())
  },[dispatch])
  // fetch allBook api hook End

  useMemo(() =>{
    if(allholidaysData && allholidaysData.all_driver && allholidaysData.all_driver.data){
      setAllHolidays(allholidaysData.all_driver.data)
    }
  },[allholidaysData])

  const handledeleteHoliday =(e) => {
    dispatch(deleteHoliday(e))  
  }


    return (


        <div className="card-body">
          <div className="heading-layout1 mg-b-17">
            <div className="item-title">
              <h3>Upcoming Holidays</h3>
            </div>

          </div>
          <div className="notice-box-wrap">
          {allHolidays.map((item,index) => {
          return (  <div className="notice-list" key={item.id}>
                <div className="post-date bg-skyblue">{item.holiday_date}</div>
                <h6 className="notice-title"><Link to="#">{item.name}</Link></h6>
                <div className="entry-meta"> {item.description}</div>
                <div className="entry-delete"><button type="button" onClick={e=>handledeleteHoliday(item.holiday_id)} className="btn btn-danger restdelete"><i className="fa fa-trash" aria-hidden="true"></i></button></div>
              </div>)

          }) }


          </div>
        </div>


    );
}

export default Holidays;
