import React, {useState,useEffect,useMemo} from 'react';

import { useSelector,useDispatch } from 'react-redux';
import { fetchSingleAddons, fetchAllAddons, deleteAddon, deleteSingleAddon } from '../Redux/AllBook/AllBookActions';
import { restShut, restShutUpdate } from '../Redux/RestShut/RestShutActions';
import { deliveryShut, deliveryShutUpdate } from '../Redux/DeliveryShut/DeliveryShutActions';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AddSingleAddonGroup from './AddSingleAddonGroup';
import { Button, Pagination } from 'react-bootstrap';


function AllAddonGroups(){

const [restOnOff,setRestOnOff] = useState(false)
const [deliveryOnOff,setDeliveryOnOff] = useState(false)
const allBookData = useSelector(state =>state.AllBook)
const restStateData = useSelector(state =>state.RestShutStatus)
const deliveryStateData = useSelector(state =>state.DeliveryShutStatus)

// store data access End
const dispatch = useDispatch()  // for accessing the redux function

const [allBookResult,setBookResult] = useState([])
const [allAddonResult,setAddonResult] = useState([])
const [addonsGroupId,setAddonsGroupId] = useState([])
const [addonsGroupName,setAddonsGroupName] = useState([])

const [actionState,setActionState] = useState('')

const [data,setData] = useState([])
const [currentorderTab,setCurrentorderTab] = useState(0)
  const [singleOrder, setSingleOrder] = useState([])



// fetch allBook  api hook start
useEffect(() =>{
  dispatch(fetchAllAddons(totalPages,currentPage));
  dispatch(restShut())
  dispatch(deliveryShut())
},[dispatch])
// fetch allBook api hook End

// console.log('restdata', allBookResult);

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(allBookData && allBookData.all_book && allBookData.all_book.data && allBookData.all_book.data.length > 0){
    setBookResult(allBookData.all_book.data)
    setData(allBookData.all_book.data)
  }
  else{
    setBookResult([])
    setData(allBookData.all_book.data)
  }
},[allBookData])

useMemo(() =>{
  if(allBookData && allBookData.all_addon && allBookData.all_addon.data){
    setAddonResult(allBookData.all_addon.data)
  }
},[allBookData])

useMemo(() =>{
  if(allBookData && allBookData.delete_addon && allBookData.delete_addon.request_status && allBookData.delete_addon.object && actionState == 'delete'){
    setActionState('')
    dispatch(fetchSingleAddons(addonsGroupId))
  }
},[allBookData])
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(restStateData && restStateData.rest_shut && restStateData.rest_shut.status){
    setRestOnOff(restStateData.rest_shut.status)
  }
},[restStateData])
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(deliveryStateData && deliveryStateData.delivery_shut && deliveryStateData.delivery_shut.status){
    setDeliveryOnOff(deliveryStateData.delivery_shut.status)
  }
},[deliveryStateData])
// add data of allFees api into constant,hook End

const handlefilterSelect = (e) => {
    if(e === 'all') {
      setCurrentorderTab(0);
    }
    else {
      setCurrentorderTab(e);
    }

    dispatch(fetchAllAddons({filter:e}))
}
const fetchAddons = (addOnGroupId, addOnGroupName) => {
  if(addonsGroupId != addOnGroupId) {
     setAddonsGroupId(addOnGroupId)
     setAddonsGroupName(addOnGroupName)   

     dispatch(fetchSingleAddons(addOnGroupId))

  } else {
     setAddonsGroupId(null)
     setAddonsGroupName(null)  
     setAddonResult([]);
  }
}
const addonsGroupIdAction = (addOnGroupId, addOnGroupName) => {
   setAddonsGroupId(addOnGroupId)
   setAddonsGroupName(addOnGroupName)   
   dispatch(fetchSingleAddons(addOnGroupId))
}


const handleDeleteAddon = (groupId) => {
  //  console.log(groupId);
   dispatch(deleteAddon(groupId))

}

const deleteAddonAction = (groupId,addonId) => {
  //  console.log(addonId);
   setActionState('delete')
   dispatch(deleteSingleAddon(groupId, addonId))
}



const setrestStatus = (event) =>{
  setRestOnOff(!restOnOff)
    dispatch(restShutUpdate(!restOnOff))
}



const [show,setShow] = useState(true);

// pagination
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);

const [data1, setData1] = useState();
const [pageSize, setPageSize] = useState(10);
const allBook = useSelector(state => state.AllBook);
const [productData,setProductData] = useState('');

console.log(allBook);

useEffect(() => {
  if (data1 && data1.length > 0) {
  setTotalPages(Math.ceil(allBook.all_book.total / pageSize));
  }
  }, [data1, pageSize]);

  useMemo(() =>{
    if(allBook && allBook.all_book && allBook.all_book.data){
      setProductData(allBook.all_book.data)
      setData1(allBook.all_book.data)   
    } else {
      setProductData('')
    }
   
  },[allBook])


const onPageChange = (currentPage) => {
  setCurrentPage(currentPage);
// dispatch(fetchAllProducts(totalPages,currentPage))
dispatch(fetchAllAddons(totalPages,currentPage));
};



const renderPagination = () => {
  let items = [];
  for (let i = 0; i < totalPages; i++) {
  items.push(
  <Pagination.Item
  key={i}
  active={i === currentPage}
  onClick={() => onPageChange(i)}
  >
  {i+1}
  </Pagination.Item>
  );
  }
  return (
  <Pagination style={{ overflowX: "auto" }}>
  <Pagination.Prev
  disabled={currentPage === 1}
  onClick={() => onPageChange(currentPage - 1)}
  />
  {items}
  <Pagination.Next
  disabled={currentPage === totalPages}
  onClick={() => onPageChange(currentPage + 1)}
  />
  </Pagination>
  );
  };

        return (

          <div className="dashboard-content-one">



        {/* Dashboard Content Start Here */}
      <div className="row gutters-20">
        <div className="col-12 col-xl-12 col-12-xxxl">
          <div className="card dashboard-card-one pd-b-20">
            <div className="card-body">
              <div className="heading-layout1">


              </div>
              <div className="earning-report1">
              {singleOrder && singleOrder.length > 0 ? (
                  <div className="card height-auto">
                    <div className="card-body">
                      <div className="heading-layout1">
                        <div className="item-title">

                        </div>

                      </div>
                        <div className="single-info-details">

                          <div className="item-content">
                            <div className="header-inline item-header">

                            </div>
                            <div className="info-table table-responsive">
                              <table className="table text-nowrap">
                                <tbody>
                                  <tr>
                                    <td>Service Type:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Order ID:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Date:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Time:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Order By:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Order Item Details:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>SubTotal:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Tip:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Tax:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Total:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Note:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>


                    </div>
                  </div>

                ) : (
                  <div className="card">
                      <div className="card-body">

                        <div className="table-box-wrap">




                            {allBookData && allBookData.all_book_loading === true ?
                              (<div className="">
                              <div className="Student-Loader">
                              <Loader
                              className = "student-detail-loader"
                            type="ThreeDots"
                            color="#fea801"
                            height={150}
                            width={150}

               />
                              </div>
                            </div>) : (<div className="table-responsive expenses-table-box">

                          <table className="table data-table text-nowrap">
                              <thead>
                                <tr>
                                  <th>
                                    Name
                                  </th>
                                
                                  <th>
                                    Addon Type
                                  </th>
                                  <th>Required</th>
                                  <th>Action</th>

                                </tr>
                              </thead>
                              <tbody>
                              {allBookResult && allBookResult && allBookResult.length > 0 && allBookResult.map((item,index)=>{                                
                                return (<>
                                  <tr onClick={(e)=>fetchAddons(item.addOnGroupId, item.name)}>
                                    <td>{addonsGroupId == item.addOnGroupId ? <i className='fas fa-caret-down'></i> : <i className='fas fa-caret-right'></i>} {item.name}</td>
                                   
                                    <td>{item.type}</td>
                                    <td>{item.isRequired === true ? 'TRUE' : 'FALSE'}</td>
                                
                          <td className="">


                                    <button onClick={e=>handleDeleteAddon(item.addOnGroupId)} type="button" className="btn btn-danger restdelete"><i className="fa fa-trash" aria-hidden="true"></i></button></td>
                                  
                                  </tr>
                                  {addonsGroupId == item.addOnGroupId ?
                                  <tr><td colSpan={4}>                                                               
                                 <table className='table data-table text-nowrap'>
                                  <tbody><thead></thead>
                                  {allBookData.all_addon_loaded == false && allAddonResult.length > 0 ? <tr>
                                    <td style={{borderTop:'0px'}}></td>
                                    <td style={{borderTop:'0px'}}>Addon Name</td>
                                    <td style={{borderTop:'0px'}}>Price</td>
                                    <td style={{borderTop:'0px'}}>Action</td>
                                  </tr> : '' }
                                   {allBookData.all_addon_loaded == true ? 
                                     <Loader
                                       className = "student-detail-loader cl-center"
                                       type="ThreeDots"
                                       color="#fea801"
                                       height={90}
                                       width={150}/> : allAddonResult.length > 0 &&
                                       allAddonResult.map((addon, index) => {
                                          return (                              
                                               <tr>
                                                 <td style={{borderTop:'0px'}} className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                                 <td style={{borderTop:'0px'}} className='no-border'>{addon.name}</td>
                                                 <td style={{borderTop:'0px'}} className='no-border'>$ {addon.unitPrice}</td>                              
                                                 <td style={{borderTop:'0px'}} className='no-border'><span style={{cursor:"pointer"}} onClick={(e)=>deleteAddonAction(item.addOnGroupId, addon.addOnId)}><i className="fa fa-trash"></i></span></td>
                                               </tr>                              
                                          )
                                       })
                                  }
                                  <tr>
                                    {allBookData.all_addon_loaded == false ? <td colSpan={4}>
                                      <button className="btn-fill-lg bg-blue-dark btn-hover-yellow float-right" data-toggle="modal" data-target="#exampleModal1"><i className="fas fa-plus"></i> Addons</button></td> : ''}                                  
                                  </tr> 
                                  </tbody>
                                </table>

                               
                            
                            

                                </td>
                                  </tr> : ''}
                                  </>
                                )
                              })}


                              </tbody>
                            </table>

                            

                          </div>)}
                        </div>
                      </div>
                    </div>

                ) }
              </div>

            </div>
          </div>
        </div>



      </div>
      {/* Dashboard Content End Here */}
     
      {show &&  <div className="modal" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
         <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Add Addon</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ position: 'absolute', top: '15px', right: '15px', background: 'red', border: 'none', outline: 'none', cursor: 'pointer' }}>
            <span aria-hidden="true" style={{ color: 'white' }}>&times;</span>
          </button>
        </div>
        <div className="modal-body">
             <AddSingleAddonGroup addonsGroupIdAction={addonsGroupIdAction} currentAddOnGroupId={addonsGroupId} currentAddOnGroupName={addonsGroupName}/>
         </div>
          </div>
         </div>
      </div>
       
                            }
     
     
     <div className='row'>
        <div className="col-12-xxxl col-12">     
         <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
           
            </div>
       
          </div>

      </div>
        );
}

export default AllAddonGroups;
