import React from 'react';
import { Link } from 'react-router-dom';
import AddSingleAddonGroup from './AddSingleAddonGroup';
import SingleAddonGroups from './SingleAddonGroups';
function AddonGroupsAddons(props) {
 
const currentAddOnGroupId =
  props &&
  props.location &&
  props.location.state &&
  props.location.state.addOnGroupId
    ? props.location.state.addOnGroupId
    : null;

const currentAddOnGroupName =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.addOnGroupName
        ? props.location.state.addOnGroupName
        : null;

        return (           
          <>
            <div className="breadcrumbs-area">
              <h3>{currentAddOnGroupName} Addon</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/addongroups">{currentAddOnGroupName}</Link>
                </li>
                <li>Addon</li>
              </ul>
            </div>
            {/* Breadcubs Area End Here */}
            {/* Exam Schedule Area Start Here */}
            <div className="row">
               <SingleAddonGroups currentAddOnGroupId={currentAddOnGroupId} currentAddOnGroupName={currentAddOnGroupName}/>
               <AddSingleAddonGroup currentAddOnGroupId={currentAddOnGroupId} currentAddOnGroupName={currentAddOnGroupName}/>

            </div>
          </>
        );
}

export default AddonGroupsAddons;
