import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FALIURE,
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FALIURE
} from './AddressCommonsConstants'

const intialstate = {
  countries_loaded : false,
  countries : [],
  countries_error : '',
  countries_success : false,
  states_loaded : false,
  states : [],
  states_error : '',
  states_success : false
}

const AddressCommonsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case COUNTRIES_REQUEST : return{
      ...state,
      countries_loaded:false
    }
    case COUNTRIES_SUCCESS : return{
      ...state,
      countries_loaded:true,
      countries_success:true,
      countries:action.payload
    }
    case COUNTRIES_FALIURE : return{
      ...state,
      countries_loaded:true,
      countries_error:action.payload
    }
    case STATES_REQUEST : return{
      ...state,
      states_loaded:false
    }
    case STATES_SUCCESS : return{
      ...state,
      states_loaded:true,
      states_success:true,
      states:action.payload
    }
    case STATES_FALIURE : return{
      ...state,
      states_loaded:true,
      states_error:action.payload
    }
    default: return state
  }

}

export default AddressCommonsReducers
