import {
  REST_SETTINGS_REQUEST,
  REST_SETTINGS_SUCCESS,
  REST_SETTINGS_FALIURE,
  REST_SETTINGSUPDATE_REQUEST,
  REST_SETTINGSUPDATE_SUCCESS,
  REST_SETTINGSUPDATE_FALIURE
} from './RestSettingsConstants'
import {config} from '../config'

export const restShutRequest = (rest_settings_info) =>{
  return{
    type : REST_SETTINGS_REQUEST,
    payload : rest_settings_info
  }
}

export const restShutSuccess = (rest_settings_success) =>{
  return{
    type : REST_SETTINGS_SUCCESS,
    payload : rest_settings_success
  }
}

export const restShutFaliure = (error) =>{
  return{
    type : REST_SETTINGS_FALIURE,
    payload : error
  }
}
export const restUpdateRequest = (rest_settings_info) =>{
  return{
    type : REST_SETTINGSUPDATE_REQUEST,
    payload : rest_settings_info
  }
}

export const restUpdateSuccess = (rest_settings_success) =>{
  return{
    type : REST_SETTINGSUPDATE_SUCCESS,
    payload : rest_settings_success
  }
}
export const fetchRestSettings = (rest_settings_info) =>{
    return(dispatch) => {
      dispatch(restShutRequest(rest_settings_info))
      const url =`${config.api_root}/api/v1/merchants/communication_setting?user_id=${config.user_id}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(rest_settings_res =>{
      const rest_settings_success = rest_settings_res
      dispatch(restShutSuccess(rest_settings_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(restShutFaliure(errorMsg))
    })
    }
}

export const updateRestSettings = (rest_settings_info) =>{
    return(dispatch) => {
      dispatch(restUpdateRequest(rest_settings_info))
      const url =`${config.api_root}/api/v1/merchants/communication_setting`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
            form_id:"",
            user_id : config.user_id,
            fields:{
            robo_call: rest_settings_info.robo_call,
            sms_phone_number:rest_settings_info.sms_phone_number,
            merchant_phone_number:rest_settings_info.merchant_phone_number,
            merchant_emails:rest_settings_info.merchant_emails,
            pickup_time:rest_settings_info.pickup_time,
            delivery_time:rest_settings_info.delivery_time
            },
          })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(rest_settings_res =>{
      const rest_settings_success = rest_settings_res
      dispatch(restUpdateSuccess(rest_settings_success))
      dispatch(restShutSuccess(rest_settings_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(restShutFaliure(errorMsg))
    })
    }
}
