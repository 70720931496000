export const FETCH_ALLPRODUCTS_REQUEST = 'FETCH_ALLPRODUCTS_REQUEST'
export const FETCH_ALLPRODUCTS_SUCCESS = 'FETCH_ALLPRODUCTS_SUCCESS'
export const FETCH_ALLPRODUCTS_ADD_SUCCESS = 'FETCH_ALLPRODUCTS_ADD_SUCCESS'
export const FETCH_ALLPRODUCTS_FALIURE = 'FETCH_ALLPRODUCTS_FALIURE'
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FALIURE = 'FETCH_PRODUCTS_FALIURE'
export const UPDATE_ALLPRODUCT_REQUEST = 'UPDATE_ALLPRODUCT_REQUEST'
export const UPDATE_ALLPRODUCT_SUCCESS = 'UPDATE_ALLPRODUCT_SUCCESS'
export const UPDATE_ALLPRODUCT_FALIURE = 'UPDATE_ALLPRODUCT_FALIURE'
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FALIURE = 'UPDATE_PRODUCT_FALIURE'
export const UPDATE_PRODUCT_PRICE_REQUEST = 'UPDATE_PRODUCT_PRICE_REQUEST'
export const UPDATE_PRODUCT_PRICE_SUCCESS = 'UPDATE_PRODUCT_PRICE_SUCCESS'
export const UPDATE_PRODUCT_PRICE_FALIURE = 'UPDATE_PRODUCT_PRICE_FALIURE'
export const FETCH_SINGLEPRODUCTS_REQUEST = 'FETCH_SINGLEPRODUCTS_REQUEST'
export const FETCH_SINGLEPRODUCTS_SUCCESS = 'FETCH_SINGLEPRODUCTS_SUCCESS'
export const FETCH_SINGLEPRODUCTS_FALIURE = 'FETCH_SINGLEPRODUCTS_FALIURE'
export const STOCK_PRODUCT_REQUEST = 'STOCK_PRODUCT_REQUEST'
export const STOCK_PRODUCT_SUCCESS = 'STOCK_PRODUCT_SUCCESS'
export const STOCK_PRODUCT_FALIURE = 'STOCK_PRODUCT_FALIURE'
export const ADD_PRODUCT_GROUP_REQUEST = 'ADD_PRODUCT_GROUP_REQUEST'
export const ADD_PRODUCT_GROUP_SUCCESS = 'ADD_PRODUCT_GROUP_SUCCESS'
export const ADD_PRODUCT_GROUP_FALIURE = 'ADD_PRODUCT_GROUP_FALIURE'
export const DELETE_PRODUCT_GROUP_REQUEST = 'DELETE_PRODUCT_GROUP_REQUEST'
export const DELETE_PRODUCT_GROUP_SUCCESS = 'DELETE_PRODUCT_GROUP_SUCCESS'
export const DELETE_PRODUCT_GROUP_FALIURE = 'DELETE_PRODUCT_GROUP_FALIURE'
