import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { payByEGiftCard, checkEGiftCardBalance } from '../Redux/EGiftCardRedeem/EGiftCardRedeemActions'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from 'react-router-dom';

function EGiftCardRedeem() {

  const eGiftCardRedeemData = useSelector(state => state.EGiftCardRedeem)
  // console.log(eGiftCardRedeemData);
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  const [responseMessage, setResponseMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [eGiftCardloaded, setEGiftCardloaded] = useState(true)
  const [saveState, setSaveState] = useState('')
  const [eGiftCardBal, setEGiftCardBal] = useState('')
  const [error, setError] = useState({
    amount: '',
    card_number: '',
    pin: ''
  })
  const [inputValues, setInputValues] = useState({
    amount: "",
    card_number: "",
    pin: ""
  })

  // add data of allFees api into constant,hook start

  useMemo(() => {
    if (eGiftCardRedeemData && eGiftCardRedeemData.egift_card_redeem && eGiftCardRedeemData.egift_card_redeem.object && saveState == 'save') {      
      if (eGiftCardRedeemData.egift_card_redeem.object.orderId) {
         setEGiftCardloaded(true)
         setResponseMessage('EGift Card Order No:' + eGiftCardRedeemData.egift_card_redeem.object.orderId + " created successfully, " + eGiftCardRedeemData.egift_card_redeem.object.order_detail.shippingInstruction)
      } else if (eGiftCardRedeemData.egift_card_redeem.object.error) {
        setEGiftCardloaded(true)
        setErrorMessage(eGiftCardRedeemData.egift_card_redeem.object.error)
      }
    }
  }, [eGiftCardRedeemData])

  useMemo(() => {
    if (eGiftCardRedeemData && eGiftCardRedeemData.egift_card_redeem_error && saveState == 'save') {     
      if (eGiftCardRedeemData.egift_card_redeem_error.object && eGiftCardRedeemData.egift_card_redeem_error.error) {
         setEGiftCardloaded(true)
         setErrorMessage(eGiftCardRedeemData.egift_card_redeem_error.error)
      }
    }
  }, [eGiftCardRedeemData])

  useMemo(() => {
    if (eGiftCardRedeemData && eGiftCardRedeemData.egift_card_balance && eGiftCardRedeemData.egift_card_balance.object && saveState == 'balance') {     
      if (eGiftCardRedeemData.egift_card_balance.object.balance) {
         setEGiftCardloaded(true)
         setEGiftCardBal(eGiftCardRedeemData.egift_card_balance.object.balance)
      } else if (eGiftCardRedeemData.egift_card_balance.object.error) {
        setEGiftCardloaded(true)
        setErrorMessage(eGiftCardRedeemData.egift_card_balance.object.error)
      }
    }
  }, [eGiftCardRedeemData])
  

  useMemo(() => {
    if (error) {
      setError(error);
    }
  }, [error])


  const eGiftCardValidate = (isAmount) => {
    let error = {
      amount: "",
      card_number: "",
      pin: ""
    }
    let isError = false;
    if (!inputValues.amount && isAmount) {
      error.amount = 'please enter amount!';
      isError = true;
    }
    if (!inputValues.card_number) {
      error.card_number = 'please enter card_number!';
      isError = true;
    }
    if (inputValues.card_number && inputValues.card_number.length != 16) {
      error.card_number = 'invalid card_number!';
      isError = true;
    }
    if (!inputValues.pin) {
      error.pin = 'please enter pin!';
      isError = true;
    }
    if (inputValues.pin && (inputValues.pin.length < 4 || inputValues.pin.length > 6)) {
      error.pin = 'invalid pin!';
      isError = true;
    }
    setError(error);
    return isError;
  }

  const eGiftCardPay = () => {
    setSaveState("")
    if (!eGiftCardValidate(true)) {
      setEGiftCardBal('')
      setSaveState("save")
      setEGiftCardloaded(false)
      dispatch(payByEGiftCard(inputValues))
    }
  }

  const eGiftCardBalance = () => {
    setSaveState("")
    if (!eGiftCardValidate(false)) {
      setSaveState("balance")
      setEGiftCardloaded(false)
      dispatch(checkEGiftCardBalance(inputValues))
    }
  }


  const resetError = () => {
    const errorOb = {
      amount: "",
      card_number: "",
      pin: ""
    }
    setError(errorOb);
  }


  return (
    <>

      <div className="breadcrumbs-area">
        <h3>EGift Card Redeem</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>EGift Card Redeem</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}

      <div class="row dashboard-card-six" style={{display: "flex", justifyContent: "center"}}>
     
          
            <div class="card height-auto">
      <div className="card-body mx-width-600">

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>

        {responseMessage ?
          <p className="alert alert-success w-auto" role="alert">
            {responseMessage}
          </p> : <p></p>
        }

        {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }


        <div className="pad-5 bg-darkblue height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">E-GiftCard Details</a>
            </li>
          </ul>
        </div>
        <div className="tab-content single-info-details mx-width-600">
          <div role="tabpanel" className='tab-pane active show' id="billing">
          {eGiftCardloaded == false && (saveState == 'balance' || saveState == 'save') ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> : <span></span> }
                {
                eGiftCardBal ? (<>
                  <div className="font-italic">
                    <div className='cl-center font-medium text-dark-medium'>E-Gift Card Balance: {eGiftCardBal}</div>
                  </div>
                  </>) : <span></span>                
                }
            <div className="row">
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Amount</label>
                <input type="number" className="form-control" value={inputValues.amount} onChange={(e) => setInputValues({ ...inputValues, amount: e.target.value })} required />
                {error.amount ? (<span className="addStudent-error float-right">*{error.amount}</span>) : ''}
              </div>
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Coupon Number</label>
                <input type="number" className="form-control" value={inputValues.card_number} onChange={(e) => setInputValues({ ...inputValues, card_number: e.target.value })} required />
                {error.card_number ? (<span className="addStudent-error float-right">*{error.card_number}</span>) : ''}
              </div>
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Pin</label>
                <input type="number" className="form-control" value={inputValues.pin} onChange={(e) => setInputValues({ ...inputValues, pin: e.target.value })} required />
                {error.pin ? (<span className="addStudent-error float-right">*{error.pin}</span>) : ''}
              </div>
            </div>
            <div className='float-right mg-t-30'>
               <button type="button" onClick={(e) => eGiftCardBalance()} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Check Balance</button> &nbsp;&nbsp;
               <button type="button" onClick={(e) => eGiftCardPay()} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Pay</button>
            </div>
          </div>       


        </div>



      </div>
</div>


</div>
    </>
  );
};

export default EGiftCardRedeem;
