import {
  FETCH_ALLBOOK_REQUEST,
  FETCH_ALLBOOK_SUCCESS,
  FETCH_ALLDETAIL_SUCCESS,
  FETCH_ALLBOOK_ADD_SUCCESS,
  FETCH_ALLBOOK_FALIURE,
  FETCH_ORDERDETAIL_REQUEST,
  FETCH_ORDERDETAIL_SUCCESS,
  FETCH_ORDERDETAIL_FALIURE,
  FETCH_SINGLEORDERSTATUS_REQUEST,
  FETCH_SINGLEORDERSTATUS_SUCCESS,
  FETCH_SINGLEORDERSTATUS_FALIURE,
  FETCH_SINGLEORDERSTATUS_CANCEL,
  FETCH_ALLADDON_REQUEST,
  FETCH_ALLADDON_SUCCESS,
  FETCH_ALLADDON_FALIURE,
  DELETE_ADDON_REQUEST,
  DELETE_ADDON_SUCCESS,
  DELETE_ADDON_FALIURE
} from './AllBookConstants'

const intialstate = {
  all_book_loading : false,
  all_book : [],
  all_book_error : '',
  all_addon_loaded : true,
  all_addon : [],
  is_added : false,
  all_addon_error : '',
  order_detail_loading : false,
  order_detail : '',
  order_detail_error : '',
  single_order_status:false,
  order_update_loading: false,
  single_order_update: '',
  single_order_cancel_status:[],
  delete_addon_loading : false,
  delete_addon : [],
  delete_addon_error : ''
}

const AllBookReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_ALLBOOK_REQUEST : return{
      ...state,
      all_book_loading:true
    }
    case FETCH_ALLBOOK_SUCCESS : return{
      ...state,
      all_book_loading:false,
      all_book:action.payload,
      is_added: false,
      all_book_error: null,
      single_order_status:false,
      single_order_cancel_status:[]
    }
    case FETCH_ALLDETAIL_SUCCESS : return{
      ...state,
      all_book_loading:false,
      all_detail:action.payload,
      is_added: false,
      all_book_error: null,
      single_order_status:false,
      single_order_cancel_status:[]
    }
    case FETCH_ALLBOOK_ADD_SUCCESS : return{
      ...state,
      all_book_loading:false,
      all_book:action.payload,
      is_added: true,
      all_book_error: null,
      single_order_status:false,
      single_order_cancel_status:[]
    }
    case FETCH_ALLBOOK_FALIURE : return{
      ...state,
      all_book_loading:false,
      all_book_error:action.payload
    }

    case FETCH_ALLADDON_REQUEST : return{
      ...state,
      all_addon_loaded:true
    }
    case FETCH_ALLADDON_SUCCESS : return{
      ...state,
      all_addon_loaded:false,
      all_addon:action.payload
    }
    case FETCH_ALLADDON_FALIURE : return{
      ...state,
      all_addon_loaded:false,
      all_addon_error:action.payload
    }

    case DELETE_ADDON_REQUEST : return{
      ...state,
      delete_addon_loading:true
    }
    case DELETE_ADDON_SUCCESS : return{
      ...state,
      delete_addon_loading:false,
      delete_addon:action.payload
    }
    case DELETE_ADDON_FALIURE : return{
      ...state,
      delete_addon_loading:false,
      delete_addon_error:action.payload
    }

    case FETCH_ORDERDETAIL_REQUEST : return{
      ...state,
      order_detail_loading:true
    }
    case FETCH_ORDERDETAIL_SUCCESS : return{
      ...state,
      order_detail_loading:false,
      order_detail:action.payload
    }
    case FETCH_ORDERDETAIL_FALIURE : return{
      ...state,
      order_update_loading:false,
      order_detail_error:action.payload
    }

    case FETCH_SINGLEORDERSTATUS_REQUEST : return{
      ...state,
      order_update_loading:true
    }
    case FETCH_SINGLEORDERSTATUS_SUCCESS : return{
      ...state,
      single_order_status:true,
      order_update_loading: false,
      single_order_update: action.payload.order_detail,
      single_order_cancel_status:[]
    }
    case FETCH_SINGLEORDERSTATUS_CANCEL : return{
      ...state,
      single_order_update: action.payload.order_detail,
      order_update_loading: false,
      single_order_cancel_status:action.payload
    }
    case FETCH_SINGLEORDERSTATUS_FALIURE : return{
      ...state,
      order_update_loading:false,
      order_detail_error:action.payload
    }
    default: return state
  }

}

export default AllBookReducers
