import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from 'react-redux'

import { fetchproducts } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation } from "react-router-dom";


function SingleCategoryList() {
  // store data access start
  const categoryData = useSelector(state => state.AllCategories)
 
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  const { state } = useLocation();
  const history = useHistory();
  // component all states define start
  const [allProductResult, setAllProductResult] = useState([]);
  const [allproductsloaded, setAllproductsloaded] = useState('')
  const [editCategoryResp, setEditCategoryResp] = useState({
    type: '',
    message: ''
  })
  
  const [saveState, setSaveState] = useState(false)



  useMemo(() => {
    if (categoryData && categoryData.edit_category && saveState) {
      editCategoryResp.type = 'success'
      editCategoryResp.message = 'Updated Successfully!'
      setEditCategoryResp(editCategoryResp);
    }
  }, [categoryData])

  const resetEditCategory = () => {
    editCategoryResp.type = ''
    editCategoryResp.message = ''
    setEditCategoryResp(editCategoryResp);
  }


  // done edit part
  // const editProductClick = (item) => {  
  //   history.push({
  //      pathname: "/edit_product",
  //      state: editProduct(item)
  //   })
  // }

  const goToBack = () => {
      history.goBack();
  }

  const editProduct = (item,catID) => {
    let product = {
      catName: state.catName,
      catID: catID,
      productId: item.productId,
      name: item.name,
      description: item.descriptions,
      shortDescription: item.shortDescription,
      price: item.price,
      stockStatus: item.inStock
    };
    return product;
  }

  useMemo(() => {
    if (categoryData && categoryData.edit_category_error && categoryData.edit_category_error.object) {
      editCategoryResp.type = 'error'
      editCategoryResp.message = categoryData.edit_category_error.object.error
      setEditCategoryResp(editCategoryResp);
    }
  }, [categoryData])



  useMemo(() => {
    if (categoryData && categoryData.all_products) {
      setAllproductsloaded(false);
      setAllProductResult(categoryData.all_products.data);
      if (categoryData.all_products_loaded) {
        setAllproductsloaded(true);
      }
    }
  }, [categoryData]);


      useEffect(() => {    
        dispatch(fetchproducts(state.catID));
      }, [dispatch])
  

  return (
    <>
      {/* Breadcubs Area Start Here */}
      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e)=>goToBack()}>Back</button>
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link onClick={(e)=>goToBack()}>
            All Categories</Link>
          </li>
        <li>{state.catName}</li>
       
        </ul>
      </div>


      
             

{/* <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}> */}


<div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">


<div className="card-body2" style={{marginBottom: "15px", overflowX: "auto"}}>
  <div className="pad-10">
    <div className="float-left mg-t-5">
      <span><b>Category-Name:-</b>{state.catName}</span>
      <p><b>Category-Description:-</b>{state.catLongDes}</p>
      </div> 
 
        </div>
        </div>



          </div>
        </div>
      </div>
           
          


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">

            <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}>
            {!allproductsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div> :
               <><div style={{padding:'10px', color:'#000000'}}>Items:</div>
               <table className="table data-table text-nowrap">
                 <tbody><thead></thead>
                 {!allproductsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div>:<>{allproductsloaded && allProductResult &&
                        allProductResult.length > 0 &&
                        allProductResult.map((item, index) => {
                            return (                                                                                                         
                               <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                 <td className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                 <td className='no-border'>{item.name}</td>
                                 <td className='no-border'>$ {item.price}</td>
                                 {item.inStock ? <td className='no-border'>In Stock</td> : <td className='no-border'>Out of Stock</td>}                                 
                                 <td className='no-border'><i className="fas fa-ellipsis-v"></i></td>
                               </tr>                    
                            )
                        })
                    }</>}
                   
                 </tbody>
               </table></> }
            </div>
          
          </div>
        </div>
      </div>



    </>

  );
}

export default SingleCategoryList;
