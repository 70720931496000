import React, { useState } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch } from 'react-redux'

import { addCategory } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory } from "react-router-dom";
 

import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";


function AddCategory() {
 

    const history = useHistory();
  const goToBack = () => {
      history.goBack();
  }


  // add category
  const [inputValues,setInputValues] = useState({
    name:"",
    description:"",
    shortdescription:"",
    order:"",
    catStatus:"ACTIVE"
  })

  const [inputValuesdefault,setInputValuesdefault] = useState({
    name:"",
    description:"",
    shortdescription:"",
    order:"",
    catStatus:"ACTIVE"
  })
 
  const [msg,setMsg] = useState(false);
  const dispatch = useDispatch()
  const categoryHandler = (event) =>{
    event.preventDefault()
    const exam_info = inputValues;
    dispatch(addCategory(exam_info));
    setMsg(true)
    setInterval(() => {
      setMsg(false);
     
  }, 3000);
  setInputValues(inputValuesdefault)
  }
  
  return (
    <>
      {/* Breadcubs Area Start Here */}
      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e)=>goToBack()}>Back</button>
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li onClick={(e)=>goToBack()}>
            All Products
          </li>
          <li>
           Add Categories
          </li>
        </ul>
      </div>

 

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">

            <div className="card-body">

              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Add Category</h3>
                </div>

              </div>
              <div className="row">
        <div className="col-12-xxxl col-12">
{msg?( <p className="alert alert-success w-auto" role="alert">
      Category Added Successfully!</p>):('')}
          </div>
          </div>
              <form onSubmit={(e) =>categoryHandler(e)}>
               
                <div className="row">
                  <div className="col-lg-6 col-12 form-group">
                    <label>Category Name</label>
                    <input type="text" placeholder className="form-control" value={inputValues.name} onChange={(e) =>setInputValues({...inputValues,name:e.target.value})} required />
                  </div>
                  
                  <div className="col-lg-6 col-12 form-group">
                    <label>Category Description</label>
                    <input type="text" placeholder className="form-control" value={inputValues.description} onChange={(e) =>setInputValues({...inputValues,description:e.target.value})} required />
                  </div>
                
                  <div className="col-lg-6 col-12 form-group">
                    <label>Order Index</label>
                    <input type="number" placeholder className="form-control" value={inputValues.order} onChange={(e) =>setInputValues({...inputValues,order:e.target.value})} required />
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                  <FormGroup>
                      <FormLabel>Select Status</FormLabel>
                      <FormControl
                        required
                        type="text"
                        value={inputValues.catStatus} onChange={(e) =>setInputValues({...inputValues,catStatus:e.target.value})}
                      
                        as="select">
                       
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>

                      </FormControl>
                    </FormGroup>
                   
                     </div>
               
                  <div className="col-12 form-group mg-t-20">
                    <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow float-right">Save</button>            
                  </div>
                </div>
              </form>
            </div>


          </div>
        </div>
      </div>


   

    </>

  );
}

export default AddCategory;
