import React, {useState,useEffect,useMemo} from 'react';


import { useSelector,useDispatch } from 'react-redux'

import moment from 'moment';
function Marketing(){
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [plansubmitted,setPlansubmitted] = useState(false)
  const [allTeachersInfo,setTeachersInfo] = useState([])

  const [inputValues,setInputValues] = useState({
                                          openingTime:"00:00",
                                          closingTime:"18:00",
                                          timingName:"",
                                          openingDay:"",
                                          closingDay:"",
                                          timingType:""

  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)


const format = 'HH:mm';

const now = moment().hour(0).minute(0);

  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
   useMemo(()=>{
     if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.error){
       setError(addExam_data.add_timing.error)
     }
     else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.success){
        setSuccessStatus(addExam_data.add_timing.success)
     }
   },[addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
   
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End




// component function end

        return (
            <div className="col-12-xxxl col-12">
                {/* <div className="card height-auto">
                {plansubmitted != true  ? (
                  <div className="card-body">
                    <div className="heading-layout1">
                      <div className="item-title">

                        {addExam_data.add_timing && addExam_data.add_timing.request_status && addExam_data.add_timing.request_status === true ? (<div className="success-greeting1">
                        <h2 className="addtimesuccess">Timing Added Successfully!</h2>
                        </div>): null}
                        {addExam_data.add_timing && addExam_data.add_timing.object && addExam_data.add_timing.object && addExam_data.add_timing.object.error ? (<div className="success-greeting1">

                        <h2 className="addtimeerror">{addExam_data.add_timing.object.error}</h2>
                        </div>): null}
                      </div>

                    </div>
                    <div className="marketing-form">
                    <form onSubmit={examHandler}>



        <div className="form-group">
          <div classname="f-title">
            <h2>Select Marketing Type</h2>
          </div>
          <div className="form-check">
            <input className="powered-marketing" type="checkbox" id="PoweredMarketing" value="A / Powered Marketing (Beta)" />
            <label className="powered-marketing-label" htmlFor="PoweredMarketing">
              AI Powered Marketing (Beta)
            </label>
              <div className="powered-marketing-sub-option hide-checkbox">
              <span className="innerhead">Select marketing context</span>
                 <div className="form-check">
                   <input className="dine-in" type="checkbox" id="DineIn" value="Dine - In" />
                   <label className="dine-in-label" htmlFor="DineIn">
                     Dine - In
                   </label>
                 </div>
                 <div className="form-check">
                   <input className="online-ordering" type="checkbox" id="OnlineOrdering" value="Online Ordering" />
                   <label className="online-ordering-label" htmlFor="OnlineOrdering">
                     Online Ordering
                   </label>
                 </div>
                 <div className="form-check">
                   <input className="Catering" type="checkbox" id="Catering" value="Catering" />
                   <label className="Catering-label" htmlFor="Catering">
                     Catering
                   </label>
                 </div>
              </div>
          </div>
          <div className="form-check">
            <input className="close-loop-marketing" type="checkbox" id="CloseLoopMarketing" value="Close Loop Marketing" />
            <label className="close-loop-marketing-label" htmlFor="CloseLoopMarketing">
              Closed Loop Marketing
            </label>
              <div className="close-loop-marketing-sub-option hide-checkbox">
              <span className="innerhead">Select service types to outreach customers</span>
                  <div className="form-check">
                    <input className="reser" type="checkbox" id="Reser" value="Reser" />
                    <label className="reser-label" htmlFor="Reser">
                      Reservation
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="onling-ordering" type="checkbox" id="OnlingOrdering" value="Onling Ordering" />
                    <label className="onling-ordering" htmlFor="OnlingOrdering">
                      Online Ordering
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="catering" type="checkbox" id="Catering" value="Catering" />
                    <label className="catering-label" htmlFor="Catering">
                      Catering
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="gift-cerfiticates" type="checkbox" id="GiftCerfiticates" value="Gift Cerfiticates" />
                    <label className="gift-cerfiticates-label" htmlFor="GiftCerfiticates">
                      Gift Cerfiticates
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="specials-offers" type="checkbox" id="SpecialsOffers" value="Specials / Offers" />
                    <label className="specials-offers-label" htmlFor="SpecialsOffers">
                      Specials / Offers
                    </label>
                  </div>
              </div>
          </div>
          <div className="form-check">
            <input className="referential-marketing" type="checkbox" id="ReferentialMarketing" value="Referential Marketing" />
            <label className="referential-marketing-label" htmlFor="ReferentialMarketing">
              Referential Marketing

            </label>
               <div className="referential-marketing-sub-option hide-checkbox">
               <span className="innerhead">Select Incentive for current customers</span>
                  <div className="form-check">
                    <input className="%of" type="checkbox" id="%of" value="-% of on your next online order" />
                    <label className="%of-label" htmlFor="%of">
                      5% of on your next online order
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="$10off" type="checkbox" id="$10off" value="$10 off of on your next reservation online" />
                    <label className="$10off-label" htmlFor="$10off">
                      $10 off of on your next reservation dine-on
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="$50off" type="checkbox" id="$50off" value="$50 off on your next $500 or more catering oreder" />
                    <label className="$50off-label" htmlFor="$50off">
                      $50 off on your next $500 or more catering order
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="buy1" type="checkbox" id="Buy1" value="Buy1" />
                    <label className="buy1-label" htmlFor="Buy1">
                      Buy 1 entree get 2nd half price
                    </label>
                  </div>
              </div>
          </div>
          <div className="form-check">
            <input className="standard-marketing" type="checkbox" id="StandardMarketing" />
            <label className="standard-marketing-label" htmlFor="StandardMarketing">
              Standard Marketing
            </label>
              <div className="standard-marketing-sub-option hide-checkbox">
              <span className="innerhead">Select standard marketing channels</span>
              <div className="form-check">
                <input className="facebook" type="checkbox" id="Merchant" value="Merchant" />
                <label className="facebook-label" htmlFor="Merchant">
                  Merchant's Website
                </label>
              </div>
                   <div className="form-check">
                     <input className="facebook" type="checkbox" id="TripAdvisor" value="TripAdvisor" />
                     <label className="facebook-label" htmlFor="TripAdvisor">
                       TripAdvisor
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="google-posts" type="checkbox" id="GooglePosts" value="Google Posts" />
                     <label className="google-posts-label" htmlFor="GooglePosts">
                       Mailing
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="instagram" type="checkbox" id="Pinterst" value="Pinterst" />
                     <label className="instagram-label" htmlFor="Pinterst">
                       Pinterst
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="facebook" type="checkbox" id="DiningTekMarketplace" value="DiningTekMarketplace" />
                     <label className="facebook-label" htmlFor="DiningTekMarketplace">
                       DiningTek Marketplace
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="next-door" type="checkbox" id="NextDoor" value="Next Door" />
                     <label className="next-door-label" htmlFor="NextDoor">
                       Next Door
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="twitter" type="checkbox" id="Twitter" value="Twitter" />
                     <label className="twitter-label" htmlFor="Twitter">
                       Twitter
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="facebook" type="checkbox" id="Facebook" value="Facebook" />
                     <label className="facebook-label" htmlFor="Facebook">
                       Facebook
                     </label>
                   </div>
                   <div className="form-check">
                     <input className="facebook" type="checkbox" id="Google" value="Google" />
                     <label className="facebook-label" htmlFor="Google">
                       Google
                     </label>
                   </div>
              </div>
          </div>

        </div>
        <button type="submit" className="btn btn-primary">Finalize Plan</button>
      </form>

                    </div>




                  </div>
                ) : (<div className="card-body">
                      <div className="success-greeting">
                      <h2>Thank You!</h2>
                      <h2>Plan submitted Successfully!</h2>
                      </div>
                  </div>)}
                </div> */}
              </div>
        );
}
export default Marketing;
