import {
  ETICKET_REDEEM_REQUEST,
  ETICKET_REDEEM_SUCCESS,
  ETICKET_REDEEM_FALIURE
  // EGIFTCARD_BALANCE_REQUEST,
  // EGIFTCARD_BALANCE_SUCCESS,
  // EGIFTCARD_BALANCE_FALIURE
} from './ETicketRedeemConstants'
import {config} from '../config'

export const eTicketRedeemRequest = (eticket_redeem) =>{
  return{
    type : ETICKET_REDEEM_REQUEST,
    payload : eticket_redeem
  }
}

export const eTicketRedeemSuccess = (eticket_redeem_success) =>{
  return{
    type : ETICKET_REDEEM_SUCCESS,
    payload : eticket_redeem_success
  }
}

export const eTicketRedeemFaliure = (error) =>{
  return{
    type : ETICKET_REDEEM_FALIURE,
    payload : error
  }
}

/*
export const eGiftCardBalanceRequest = (egift_card_balance) =>{
  return{
    type : EGIFTCARD_BALANCE_REQUEST,
    payload : egift_card_balance
  }
}

export const eGiftCardBalanceSuccess = (egift_card_balance_success) =>{
  return{
    type : EGIFTCARD_BALANCE_SUCCESS,
    payload : egift_card_balance_success
  }
}

export const eGiftCardBalanceFaliure = (error) =>{
  return{
    type : EGIFTCARD_BALANCE_FALIURE,
    payload : error
  }
}*/

export const punchTicket = (eticket, eventId) =>{
    return(dispatch) => {
      dispatch(eTicketRedeemRequest())
      const url =`${config.api_root}/api/v1/merchants/event/${eventId}/punch`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
          user_id : config.user_id,
          fields:{
            giftCard:[{
               card_number: eticket.ticket_number,
               pin: eticket.pin
            }]
          },
      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(eticket_res =>{
      const eticket_res_success = eticket_res
      dispatch(eTicketRedeemSuccess(eticket_res_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(eTicketRedeemFaliure(errorMsg))
    })
    }
}

