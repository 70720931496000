import React, { useState, useEffect, useMemo } from "react";
import { MDBBadge } from "mdbreact";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Switch from "react-switch";

import { useSelector, useDispatch } from "react-redux";

import {  fetchAllAddons } from '../Redux/AllBook/AllBookActions';
import {
  updateCategory,
  fetchproducts,
  fetchsingleproduct,
  enableStock,
  disableStock,
  enableProduct,
  disableProduct,
  updateProductPrice,
  updateProductMemo,
  updateProductAddons,
  deleteProductAddons,
  updateProductCategory
} from "../Redux/AllCategories/AllCategoriesActions";
import { publishData } from '../Redux/PublishData/PublishDataActions';

import { Link } from "react-router-dom";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";

function AllProducts(props) {
 
  const selectedCat_id =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.cat_Id
      ? props.location.state.cat_Id
      : null;

  // store data access start

  const allCategoriesData = useSelector((state) => state.AllCategories);
  const allBookData = useSelector(state =>state.AllBook);
  
  const [allBookResult,setBookResult] = useState([])
  // store data access End
  const dispatch = useDispatch(); // for accessing the redux function

  // component all states define start
  const [allExpenseResult, setAllExpenseResult] = useState([]);
  const [allCategoriesResult, setAllCategoriesResult] = useState([]);
  const [allProductResult, setAllProductResult] = useState([]);
  const [singleProductResult, setSingleProductResult] = useState([]);
  const [allExpenseInfo, setAllExpenseInfo] = useState([]);
  const [row, setRow] = useState([]);
  const [editCatMode, setEditCatMode] = useState(false);
  const [editSelectedCat, setEditSelectedCat] = useState(null);
  const [editSelectedProduct, setEditSelectedProduct] = useState(null);
  const [editSelectedAddons, setEditSelectedAddons] = useState(null);
  const [newAddonsSelected, setNewAddonsSelected] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const [nameEditClick, setNameEditClick] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [descriptionLoading, setDescriptionLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const [descriptionEditClick, setDescriptionEditClick] = useState(false);
 
  const [priceEditClick, setPriceEditClick] = useState(false);
 
  const [imageEditClick, setImageEditClick] = useState(false);

  const [categoryEditClick, setCategoryEditClick] = useState(false);
 
  const [publishedData, setPublishedData] = useState(false);
  const [publishdataLoading, setPublishdataLoading] = useState(false);





  const [inputValues, setInputValues] = useState({
    catID: null,
    catName: "",
    catDes: "",
    orderIndex: "",
    catStatus: "",
  });


  const [productValues, setProductValues] = useState({
    productId: null,
    name: "",
    description: "",
    price: null,
    productStatus: false,
    stockStatus:false,
    cat_Id:null,
    addOns:[],
    categories:[],
    image:"",
  });



  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 20,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Expense Type",
        field: "expensetype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 1000,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  // component all states define End

  //hooks start
  // fetch allExpense  api hook start
  useEffect(() => {
    dispatch(fetchproducts(selectedCat_id));
    dispatch(fetchAllAddons())
  }, [dispatch]);
  // fetch allExpense  api hook End

  // add data of allAssignBook api into constant,hook start
  useMemo(() => {
    if (allCategoriesData && allCategoriesData.all_categories) {
      setAllCategoriesResult(allCategoriesData.all_categories.data);
    }
  }, [allCategoriesData && allCategoriesData.all_categories]);

  useMemo(() => {
    if (allCategoriesData && allCategoriesData.all_products) {
      setAllProductResult(allCategoriesData.all_products.data);
    }
  }, [allCategoriesData]);
  // add data of allAssignBook api into constant,hook End

  useMemo(() => {
    if (allCategoriesData && allCategoriesData.single_products) {
      setSingleProductResult(allCategoriesData.single_products.data);
      const selectedAddons = {};
      allCategoriesData.single_products.data && allCategoriesData.single_products.data.length > 0 && allCategoriesData.single_products.data.map((item, index) => {
  
        selectedAddons[item.addOnGroupId] = true;

           })
        setCheckedItems(selectedAddons);
   


    }
  }, [allCategoriesData]);

  // when allExpenseResult data change than data add into constant,hook start
  useMemo(() => {
    if (allExpenseResult && allExpenseResult.data) {
      setAllExpenseInfo(allExpenseResult.data);
    }
  }, [allExpenseResult]);
  // when allExpenseResult data change than data add into constant,hook End

  // add data of allFees api into constant,hook start
  useMemo(() =>{
    const finalAddons = [];
    if(allBookData && allBookData.all_book && allBookData.all_book.data && allBookData.all_book.data.length > 0){
      setBookResult(allBookData.all_book.data)
      allBookData.all_book.data.map((item, index) => {
        finalAddons.push({ value: item.addOnGroupId, label: item.name })

      })
    }
    else{
      setBookResult([])
    }
    setNewAddonsSelected(finalAddons)

  },[allBookData])

  //when allExpenseInfo data change than data add into constant,hook start
  useMemo(() => {
    if (allExpenseInfo && allExpenseInfo.length > 0) {
      let arrray = [];
      allExpenseInfo.map((item, index) => {
        let new_object = {
          id: item.Expenseid,
          name: item.Name,
          expensetype: item.ExpenseType,
          amount: item.Amount,
          status: (
            <MDBBadge
              pill
              color={item.Status == "Paid" ? "success" : "danger"}
              className={`p-7 ${
                item.Status == "Paid" ? "pl-17 pr-17" : "pl-10 pr-10"
              }`}
              key={index}
              searchvalue={item}
            >
              {item.Status}
            </MDBBadge>
          ),
          phone: item.Phone,
          email: item.Email,
          date: item.Date,
        };
     
        arrray.push(new_object);
      });
     
      setRow(arrray);
    }
  }, [allExpenseInfo]);
  //when allExpenseInfo data change than data add into constant,hook end

  //when row data change than data add into constant,hook start
  useMemo(() => {
    if (row && row.length > 0) {
      setDatatable({ ...datatable, rows: row });
    }
  }, [row]);
  //when row data change than data add into constant,hook end
  //hooks end

  // examHandler function start
  const editCatHandler = (event) => {
    event.preventDefault();
    const category_info = inputValues;
    dispatch(updateCategory(category_info));
    setEditCatMode(false);
    setEditSelectedCat(null);
 
  };
  // examHandler function End

  // examHandler function start
  const editCatClick = (item) => {
    setInputValues({
      catID: item.productId,
      catName: item.name,
      catDes: item.shortDescription,
      orderIndex: item.price,
      catStatus: item.productStatus,
    });
    setEditCatMode(true);
    setEditSelectedCat(item.categoryId);
   
  };
  // examHandler function End


  // examHandler function start
  const editProductClick = (item) => {

    dispatch(fetchsingleproduct(item.productId));

    setProductValues({
      productId: item.productId,
      name: item.name,
      description: item.shortDescription,
      price: item.price,
      productStatus: item.productStatus,
      stockStatus:item.inStock,
      cat_id:props &&
      props.location &&
      props.location.state &&
      props.location.state.cat_Id
        ? props.location.state.cat_Id
        : null,
      addOns:[]

    });
    //setEditCatMode(true);
  //  setEditSelectedCat(item.categoryId);
  setEditSelectedProduct(item.productId);
  setNameEditClick(false);
  setPriceEditClick(false);
  setDescriptionEditClick(false);
  setCategoryEditClick(false);
  setImageEditClick(false);

  };
  // examHandler function End


  // examHandler function start
  const saveProductClick = (item) => {

  //dispatch(updateProductPrice(productValues));
  //dispatch(updateProductMemo(productValues));
  setEditSelectedProduct(null);
   
  };
  // examHandler function End

  const editAddonClick = (item) => {
    setEditSelectedAddons(item.productId);

  
  };
  const editNameClick = (item) => {
    setNameEditClick(true);
  };
  const editDescriptionClick = (item) => {
    setDescriptionEditClick(true);
  };
  const editPriceClick = (item) => {
    setPriceEditClick(true);
  };
  const editCategoryClick = (item) => {
     setCategoryEditClick(true);
  };
  const editImageClick = (item) => {
     setImageEditClick(true);
  };

  const saveNameClick = (item) => {
    dispatch(updateProductMemo(productValues));
    setNameLoading(true);
    setTimeout( () => {
    setNameEditClick(false);
    setNameLoading(false);
  }, 2000);
  setPublishedData(false);
  const data = [...allProductResult];
  const index = data.findIndex(obj => obj.productId === editSelectedProduct);
  data[index].name = productValues.name;
  setAllProductResult(data);

  };
  const saveDescriptionClick = (item) => {

    dispatch(updateProductMemo(productValues));
    setDescriptionLoading(true);
    setTimeout( () => {
    setDescriptionEditClick(false);
    setDescriptionLoading(false);
  }, 2000);
  setPublishedData(false);
  const data = [...allProductResult];
  const index = data.findIndex(obj => obj.productId === editSelectedProduct);
  data[index].shortDescription = productValues.description;
  setAllProductResult(data);

  };
  const savePriceClick = (item) => {
    dispatch(updateProductPrice(productValues));
    setPriceLoading(true);
    setTimeout( () => {
    setPriceEditClick(false);
    setPriceLoading(false);
  }, 2000);
  setPublishedData(false);
  const data = [...allProductResult];
  const index = data.findIndex(obj => obj.productId === editSelectedProduct);
  data[index].price = productValues.price;
  setAllProductResult(data);
  };
  const saveCategoryClick = (item) => {
   
    dispatch(updateProductCategory(item.productId, productValues.cat_Id));
     setCategoryLoading(true);
     setTimeout( () => {
     setCategoryEditClick(false);
     setCategoryLoading(false);
   }, 2000);
   setPublishedData(false);
  };
  const saveImageClick = (item) => {
     setImageEditClick(false);
  };




  // examHandler function start
  const saveAddonClick = (item) => {
    
      checkedItems && checkedItems.length > 0 && checkedItems.map((item2, index) => {
      dispatch(updateProductAddons(item.productId, index));
    })

    dispatch(fetchsingleproduct(editSelectedProduct))

  setEditSelectedAddons(null);
  
  };
  // examHandler function End



    const publishDataClick = (item) => {
      setPublishdataLoading(true);
      setTimeout( () => {
        dispatch(publishData());
        setPublishedData(true);
        setPublishdataLoading(false);
    }, 2000);

    };

  // examHandler function start
  const selectAddon = (item) => {
    if(newAddonsSelected.indexOf(item) > -1) {
      const updatedAddons = newAddonsSelected.filter(currentitem => currentitem != item);
      setNewAddonsSelected(updatedAddons);
     
    }
    else {

    newAddonsSelected.push(item);
    
  }
  };
  // examHandler function End

  

  // resetHandler function start
  const resetHandler = (event) => {
    event.preventDefault();
    window.location.reload(false);
  };
  // resetHandler function start

 

  const setProductStatus = (id, status) => {
    if (status === true) {
      dispatch(disableProduct(id));
    } else {
      dispatch(enableProduct(id));
    }
  };

  const setStockStatus = (id, status) => {
    //  setStockOnOff(!stockOnOff)
    if (status === true) {
      dispatch(disableStock(id));
    } else {
      dispatch(enableStock(id));
    }
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  

  const handleChange2 = event => {

    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
    if(event.target.checked === true) {
    dispatch(updateProductAddons(editSelectedProduct, event.target.name))
    }
    else {
      dispatch(deleteProductAddons(editSelectedProduct, event.target.name))
    }
   
  };


  const Checkbox = ({ type = "checkbox", id, name, checked = false, onChange }) => {

  return (
    <input type={type} id={id} name={name} checked={checked} onChange={onChange} className="stv-radio-button" />
  );
};

  return (
    
        <>
          {/* Breadcubs Area Start Here */}
          <div className="breadcrumbs-area">
            <h3>Products</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/categories">
              {props && props.location && props.location.state && props.location.state.cat_Name ? props.location.state.cat_Name : null}
              </Link>
            </li>
              <li>Products</li>
            </ul>
          </div>
          {/* Breadcubs Area End Here */}
          {/* Expanse Table Area Start Here */}
          <div className="row">
            <div className="col-12-xxxl col-12">
              <div className="mainbox">
                {/* Edit Cat Data Starts*/}

                <div className="row">
                <div className="col-6"></div>
                {publishedData ? (<div className="col-6 publishchange success"><span>Changes pushed successfully!</span></div>) : (<div className="col-6 publishchange"><span>you have unsaved changes</span> <button className="btn btn-primary" onClick={(e) => publishDataClick(e)}>Publish Changes {publishdataLoading && publishdataLoading === true ? (<i className="fas fa-spinner fa-spin"></i>):null}</button></div>)}

                </div>

                {editCatMode &&
                editCatMode === true &&
                editSelectedCat !== null ? (
                  <div className="card-body">
                    <div className="heading-layout1">
                      <div className="item-title">
                        <h3>Edit Product</h3>
                      </div>
                    </div>
                    <form
                      className="new-added-form"
                      onSubmit={(e) => editCatHandler(e)}
                    >
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Product Name</label>
                          <input
                            type="text"
                            placeholder
                            className="form-control"
                            value={inputValues.catName}
                            onChange={(e) =>
                              setInputValues({
                                ...inputValues,
                                catName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Product Description</label>
                          <input
                            type="text"
                            placeholder
                            className="form-control"
                            value={inputValues.catDes}
                            onChange={(e) =>
                              setInputValues({
                                ...inputValues,
                                catDes: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Product Price</label>
                          <input
                            type="number"
                            placeholder
                            className="form-control"
                            value={inputValues.orderIndex}
                            onChange={(e) =>
                              setInputValues({
                                ...inputValues,
                                orderIndex: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <FormGroup>
                            <FormLabel>Stock Status</FormLabel>
                            <FormControl
                              required
                              type="text"
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  catStatus: e.target.value,
                                })
                              }
                              as="select"
                            >
                              <option value="">Select Stock Status</option>
                              <option value="ACTIVE">Enable</option>
                              <option value="INACTIVE">Disable</option>
                            </FormControl>
                          </FormGroup>
                        </div>
                        <div className="col-12 form-group mg-t-8">
                          <button
                            type="submit"
                            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                          >
                            Save
                          </button>
                          <button
                            type="reset"
                            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                            onClick={(e) => resetHandler(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : null}
                {/* Edit Cat Data Ends */}

                {allCategoriesData &&
                allCategoriesData.all_categories_loading === true ? (
                  <div
                    className={
                      editCatMode && editCatMode === true
                        ? "card-body hidden"
                        : "card-body"
                    }
                  >
                    <div className="Student-Loader">
                      <Loader
                        className="student-detail-loader"
                        type="ThreeDots"
                        color="#fea801"
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      editCatMode && editCatMode === true
                        ? "table-responsive expenses-table-box hidden"
                        : "table-responsive expenses-table-box"
                    }
                  >
                    <table className="table data-table text-nowrap hidetable">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Description</th>
                          <th>Stock</th>
                          <th>Product Status</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allCategoriesResult &&
                          allCategoriesResult.length > 0 &&
                          allCategoriesResult.map((item, index) => {
                            return (
                              <tr>
                                <td className="proname">{item.name}</td>

                                <td className="destd">{item.descriptions}</td>
                                <td>
                                  <Switch
                                    onChange={(e) =>
                                      setStockStatus(
                                        item.productId,
                                        item.inStock
                                      )
                                    }
                                    checked={item.inStock}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  />
                                </td>
                                <td>
                                  <Switch
                                    onChange={(e) =>
                                      setProductStatus(
                                        item.productId,
                                        item.productStatus
                                      )
                                    }
                                    checked={item.productStatus}
                                    uncheckedIcon={false}
                                    checkedIcon={true}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  />
                                </td>
                                <td className="destd">${item.price}</td>
                                <td>
                                  <a onClick={(e) => editCatClick(item)}>
                                    <i className="fas fa-edit"></i>{" "}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <div className="pizza1">
                      <div className="pizza1-contant">
                        <div className="row">
                          {allProductResult &&
                            allProductResult.length > 0 &&
                            allProductResult.map((item, index) => {
                              const Current_Addons = [];
                              const showEdit = editSelectedProduct && editSelectedProduct === item.productId ? "mainmode editable" : "mainmode";
                              const productEdit = editSelectedProduct && editSelectedProduct === item.productId ? "col-lg-12 col-lg-6 EditMode" : "col-lg-6";

                              const addonsEdit = editSelectedAddons && editSelectedAddons === item.productId ? "row unsaved addonsarea EditAddon" : "row unsaved addonsarea";


                              return (
                                // menu data of particular restaurant start

                                <div
                                  className={productEdit}
                                  key={index}
                                >
                                <div className="productbox">
                                  <div className="ppara">
                                    <span
                                      className={showEdit}
                                      onClick={(e) => editProductClick(item)}
                                    >
                                      <i className="fas fa-edit"></i>{" "}

                                    </span>
                                    <span
                                      className={showEdit}
                                      onClick={(e) => saveProductClick(item)}
                                    >
                                      <span className="savechanges">
                                      <i className="far fa-window-close"></i>
                                      </span>
                                    </span>
                                    <div className="pizza-img">
                                      {item.image && item.image[0] ? (
                                        <div className="pizza-photo">
                                          <img
                                            src={
                                              item.image &&
                                              item.image[0] &&
                                              urlPatternValidation(
                                                item.image[0]
                                              ) === true
                                                ? item.image[0]
                                                : "/img/default-food.png"
                                            }
                                            alt="images not found"
                                          />
                                        </div>
                                      ) : null}

                                      <div className="pprise">
                                        {
                                         
                                        }
                                        <h6 className={editSelectedProduct && editSelectedProduct === item.productId && nameEditClick === true ? 'unsaved' : 'saved'}>{item.name} <span
                                          className="innerEdit"
                                          onClick={(e) => editNameClick(item)}
                                        >
                                          <i className="fas fa-edit"></i>{" "}
                                        </span></h6>
                                        <h6 className={editSelectedProduct && editSelectedProduct === item.productId && nameEditClick === true ? 'saved' : 'unsaved'}>  <input
                                            type="text"
                                            placeholder
                                            className=""
                                            defaultValue={item.name}
                                            value={productValues.name}
                                            onChange={(e) =>
                                              setProductValues({
                                                ...productValues,
                                                name: e.target.value,
                                              })
                                            }

                                          />
                                          <span
                                            className="innerEdit save"
                                            onClick={(e) => saveNameClick(item)}
                                          >{nameLoading && nameLoading === true ? (<span><i className="fas fa-spinner fa-spin"></i>{" save"}</span>):(<span><i className="fas fa-save"></i>{" save"}</span>)}

                                          </span></h6>
                                        <p className={editSelectedProduct && editSelectedProduct === item.productId && descriptionEditClick === true ? 'unsaved' : 'saved'}>{item.shortDescription}<span
                                          className="innerEdit"
                                          onClick={(e) => editDescriptionClick(item)}
                                        >
                                          <i className="fas fa-edit"></i>{" "}
                                        </span></p>
                                        <p className={editSelectedProduct && editSelectedProduct === item.productId && descriptionEditClick === true ? 'saved' : 'unsaved shortdes'}> <textarea
                                            type="textarea"
                                            placeholder
                                            className=""
                                            defaultValue={item.shortDescription}
                                            value={productValues.description}
                                            onChange={(e) =>
                                              setProductValues({
                                                ...productValues,
                                                description: e.target.value,
                                              })
                                            }

                                          /><span
                                            className="innerEdit save"
                                            onClick={(e) => saveDescriptionClick(item)}
                                          >
                                            {descriptionLoading && descriptionLoading === true ? (<span><i className="fas fa-spinner fa-spin"></i>{" save"}</span>):(<span><i className="fas fa-save"></i>{" save"}</span>)}
                                          </span></p>
                                      </div>

                                      <div className="bottom">
                                        <p className={editSelectedProduct && editSelectedProduct === item.productId && priceEditClick === true ? 'unsaved' : 'saved'}>{editSelectedProduct && editSelectedProduct === item.productId ? <span><span className="boldtxt">Price:</span> $</span>: '$'}{item.price}<span
                                          className="innerEdit"
                                          onClick={(e) => editPriceClick(item)}
                                        >
                                          <i className="fas fa-edit"></i>{" "}
                                        </span></p>
                                        <span className={editSelectedProduct && editSelectedProduct === item.productId && priceEditClick === true ? 'saved price' : 'unsaved price'}>Price: $<input
                                            type="text"
                                            placeholder
                                            className=""
                                            defaultValue={item.price}
                                            value={productValues.price}
                                            onChange={(e) =>
                                              setProductValues({
                                                ...productValues,
                                                price: e.target.value,
                                              })
                                            }

                                          /><span
                                            className="innerEdit save"
                                            onClick={(e) => savePriceClick(item)}
                                          >
                                            {priceLoading && priceLoading === true ? (<span><i className="fas fa-spinner fa-spin"></i>{" save"}</span>):(<span><i className="fas fa-save"></i>{" save"}</span>)}
                                          </span></span>
                                          {editSelectedProduct && editSelectedProduct === item.productId && categoryEditClick === true ? (
                                            <div className="status catname inner saved"><span className="saved">
                                            <FormControl
                                                required
                                                type="text"
                                                onChange={(e) =>
                                                  setProductValues({
                                                    ...productValues,
                                                    cat_Id: e.target.value,
                                                  })
                                                }
                                                as="select"
                                              >
                                              <option value="">Select Category</option>
                                              {allCategoriesResult &&
                                                allCategoriesResult.length > 0 &&
                                                allCategoriesResult.map((item, index) => {
                                                  return (
                                                    <option value={item.categoryId}>{item.categoryName}</option>
                                                  );
                                                })}
                                              </FormControl><span
                                                className="innerEdit save"
                                                onClick={(e) => saveCategoryClick(item)}
                                              >
                                                {categoryLoading && categoryLoading === true ? (<span><i className="fas fa-spinner fa-spin"></i>{" save"}</span>):(<span><i className="fas fa-save"></i>{" save"}</span>)}
                                              </span></span></div>

                                      ) : (<div className="status catname inner saved"><span className="lables">Category: </span><span>{props && props.location && props.location.state && props.location.state.cat_Name ? props.location.state.cat_Name : null}
                                  </span></div>
                                    ) }
                                      </div>


                                    </div>

                                  </div>
                                  <div className={addonsEdit}>
                                {/* Addons Area Starts */}

                                  <div
                                    className="col-12 addons-area"
                                    key={index}
                                  >
                                    <div className="ppara">
                                      <span
                                        className={showEdit}
                                        onClick={(e) => editAddonClick(item)}
                                      >
                                        <i className="fas fa-edit"></i>{" "}

                                      </span>
                                      <span
                                        className={showEdit}
                                        onClick={(e) => saveAddonClick(item)}
                                      >
                                        <span className="savechanges addons">
                                        <i className="far fa-window-close"></i>
                                        </span>
                                      </span>
                                      <div className="pizza-img">

                                        <div className="bottom">
                                          <span className="current-addons"><span className="lables">Current Addons: </span>
                                        {singleProductResult && singleProductResult.length > 0 && singleProductResult.map((item, index) => {
                                               return (
                                                 <button type="button" className="btn-fill-md radius-30 text-light bg-dodger-blue">{item.name}</button>
                                               );
                                             })}

                                          </span>
                                          <span className="manage-addons"><span className="lables">Manage Addons: <br/></span>


                                               {allBookResult && allBookResult.length > 0 && allBookResult.map((item, index) => {
                                               return (
                                                 <label for={item.addOnGroupId} key={item.addOnGroupId} className="lableforaddon">

                                                <Checkbox
                                                  id={item.addOnGroupId}
                                                  name={item.addOnGroupId}
                                                  checked={checkedItems[item.addOnGroupId]}
                                                  onChange={handleChange2}
                                                />
                                                <span className="btn-fill-1md radius-30 text-dark bg-dodger-blue unselect">{item.name} <span className="delete-addon"><i className="fa fa-times" aria-hidden="true"></i></span></span>

                                              </label>
                                              );
                                            })
                                          }
                                               </span>
                                        </div>
                                      </div>

                                    </div>
                                    <div className="row middle unsaved">
                                    <div className="addons-area">
                                    </div>
                                    </div>

                                  </div>

                                {/* Addons Area Ends */}
                                  </div>
                                  <div className="row btmrow">
                                  <div className="col-lg-4">
                                  <div className="status catname"><span className="lables">Category: </span><span className="saved">{props && props.location && props.location.state && props.location.state.cat_Name ? props.location.state.cat_Name : null}</span><span className="unsaved">  <FormControl
                                      required
                                      type="text"
                                      onChange={(e) =>
                                        setProductValues({
                                          ...productValues,
                                          cat_Id: e.target.value,
                                        })
                                      }
                                      as="select"
                                    >
                                    <option value="">Select Category</option>
                                    {allCategoriesResult &&
                                      allCategoriesResult.length > 0 &&
                                      allCategoriesResult.map((item, index) => {
                                        return (
                                          <option value={item.categoryId}>{item.categoryName}</option>
                                        );
                                      })}
                                    </FormControl></span></div>
                                  </div>
                                  
                                  <div className="col-lg-4">
                                     <div className="status product"><span className="lables">Status</span><span><Switch onChange={e=>setProductStatus(item.productId, item.productStatus)} checked={item.productStatus} uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/></span>
                                     </div>
                                  </div>
                                  <div className="col-lg-4">
                                     <div className="status product"><span className="lables">Stock</span><span><Switch onChange={e=>setProductStatus(item.productId, item.productStatus)} checked={item.productStatus} uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/></span>
                                     </div>
                                  </div>

                                  </div>
                                </div>
                                </div>

                                // menu data of particular restaurant end
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Expanse Table Area End Here */}
          
        </>
    
  );
}

export default AllProducts;
