import {
  FETCH_ALLCOUPONS_REQUEST,
  FETCH_ALLCOUPONS_SUCCESS,
  FETCH_ALLCOUPONS_FALIURE,
  ADD_COUPON_REQUEST,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FALIURE
} from './AllCouponsConstants'

const intialstate = {
  all_coupons_loading : false,
  all_coupons : [],
  all_coupons_error : '',
  add_coupon_loading: false,
  add_coupon : null,
  add_coupon_error : null
}

const AllCouponsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_ALLCOUPONS_REQUEST : return{
      ...state,
      all_coupons_loading:true
    }
    case FETCH_ALLCOUPONS_SUCCESS : return{
      ...state,
      all_coupons_loading:false,
      all_coupons:action.payload
    }
    case FETCH_ALLCOUPONS_FALIURE : return{
      ...state,
      all_coupons_error:action.payload
    }

    case ADD_COUPON_REQUEST : return{
      ...state,
      add_coupon_loading:true,
      add_coupon: null
    }
    case ADD_COUPON_SUCCESS : return{
      ...state,
      add_coupon_loading:false,
      add_coupon:action.payload
    }
    case ADD_COUPON_FALIURE : return{
      ...state,
      add_coupon_loading:false,
      add_coupon: null,
      add_coupon_error:action.payload
    }
    default: return state
  }

}

export default AllCouponsReducers
