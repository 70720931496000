export const FETCH_ALLBOOK_REQUEST = 'FETCH_ALLBOOK_REQUEST'
export const FETCH_ORDERDETAIL_REQUEST = 'FETCH_ORDERDETAIL_REQUEST'
export const FETCH_ORDERDETAIL_SUCCESS = 'FETCH_ORDERDETAIL_SUCCESS'
export const FETCH_ORDERDETAIL_FALIURE = 'FETCH_ORDERDETAIL_FALIURE'
export const FETCH_ALLBOOK_SUCCESS = 'FETCH_ALLBOOK_SUCCESS'
export const FETCH_ALLDETAIL_SUCCESS = 'FETCH_ALLDETAIL_SUCCESS'
export const FETCH_ALLBOOK_FALIURE = 'FETCH_ALLBOOK_FALIURE'
export const FETCH_ALLBOOK_ADD_SUCCESS = 'FETCH_ALLBOOK_ADD_SUCCESS'
export const FETCH_SINGLEORDERSTATUS_REQUEST = 'FETCH_SINGLEORDERSTATUS_REQUEST'
export const FETCH_SINGLEORDERSTATUS_SUCCESS = 'FETCH_SINGLEORDERSTATUS_SUCCESS'
export const FETCH_SINGLEORDERSTATUS_FALIURE = 'FETCH_SINGLEORDERSTATUS_FALIURE'
export const FETCH_SINGLEORDERSTATUS_CANCEL = 'FETCH_SINGLEORDERSTATUS_CANCEL'
export const FETCH_ALLADDON_REQUEST = 'FETCH_ALLADDON_REQUEST'
export const FETCH_ALLADDON_SUCCESS = 'FETCH_ALLADDON_SUCCESS'
export const FETCH_ALLADDON_FALIURE = 'FETCH_ALLADDON_FALIURE'
export const DELETE_ADDON_REQUEST = 'DELETE_ADDON_REQUEST'
export const DELETE_ADDON_SUCCESS = 'DELETE_ADDON_SUCCESS'
export const DELETE_ADDON_FALIURE = 'DELETE_ADDON_FALIURE'
