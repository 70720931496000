import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Switch from "react-switch";
import { useSelector, useDispatch } from 'react-redux'

import { fetchsingleproduct, updateProductMemo, disableStock, enableStock, deleteProductAddons, updateProductPrice, fetchproducts, deleteProductCategory } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation } from "react-router-dom";


function EditProduct() {
  // store data access start
  const productData = useSelector(state => state.AllCategories)
 
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  const { state } = useLocation()
  const history = useHistory();
  // component all states define start
  const [productCategoryResult, setProductCategoryResult] = useState([]);
  const [productAddonsResult, setProductAddonsResult] = useState([]);
  const [productAddonsloaded, setProductAddonsloaded] = useState('')
  const [stockProductStatus, setStockProductStatus] = useState([])
  const [saveState, setSaveState] = useState('')
  const [indexState, setIndexState] = useState(0)
  const [editProductResp, setEditProductResp] = useState({
    type: '',
    message: ''
  })


  const [productValues, setProductValues] = useState({
    productId: state.productId,
    name: state.name,
    description: state.description,
    shortDescription: state.shortDescription,
    price: state.price,
    stockStatus: state.stockStatus
  })

  useEffect(() => {
    // Check if the state.productId is defined
    if (state.productId) {
    // Dispatch the fetchsingleproduct action with the state.productId as the argument
    dispatch(fetchsingleproduct(state.productId));
  
    // Reset the edit product state
    resetEditProduct();
    }
    }, [dispatch, state.productId]);
  
   

  const goToBack = () => {

    history.goBack();
  
  }  



  const removeGroup = (addonGroupId) => {
     setSaveState('delete')
     dispatch(deleteProductAddons(state.productId, addonGroupId))
  }

  const removeCategoryGroup = (catId) => {
    setSaveState('delete')
    dispatch(deleteProductCategory(state.productId,catId))
 }

  useMemo(() => {
    if (productData && productData.stock_product && saveState == 'stock_update') {
      productValues.stockStatus = !stockProductStatus;
     
      setProductValues(productValues);
      editProductResp.type = 'stock_success'
      editProductResp.message = 'Stock Status Change Successfully!'
      setEditProductResp(editProductResp);
    }
  }, [productData])

  const showAddons = (index) =>{
    setIndexState(index)
  }

  const hideAddons = () =>{
    setIndexState(-1)
  }
  const showCategories = (index) =>{
    setIndexState(index)
  }

  const hideCategories = () =>{
    setIndexState(-1)
  }

  const editProductHandler = (event) => {
    event.preventDefault()
    setSaveState('update');
    const product_info = productValues;
    dispatch(updateProductMemo(product_info))
  }

  const editProductPrice = () => {
    setSaveState('price_update');
    const productdata = {
      productId: productValues.productId,
      price: productValues.price
    }
    dispatch(updateProductPrice(productdata));
  }

  const updateProductStatus = (id, status) => {
    setSaveState('stock_update');
    setStockProductStatus(status)
    if (status === true) {
      dispatch(enableStock(id));
    } else {
      dispatch(disableStock(id));
    }
  };

  

  const addAddonGroups = () => {       
    const addonGroupData = {
      catName : state.catName,
      categoryId: state.categoryId,
      productId: state.productId,
      productName: state.name
    } 
    return addonGroupData;
  }

  const addCategoryGroups = () => {       
    const addCategoryData = {
      catName : state.catName,
      categoryId: state.categoryId,
      productId: state.productId,
      productName: state.name
    } 
    return addCategoryData;
  }

  useMemo(() => {
    if (productData && productData.edit_product && saveState == 'update') {
      editProductResp.type = 'success'
      editProductResp.message = 'Updated Successfully!'
      setEditProductResp(editProductResp);
    }
  }, [productData])

  useMemo(() => {
    if (productData && productData.edit_product_price && saveState == 'price_update') {
      editProductResp.type = 'price_success'
      editProductResp.message = 'Price Updated Successfully!'
      setEditProductResp(editProductResp);
    }
  }, [productData])

  const resetEditProduct = () => {
    productData.edit_product = null;
    const editProductRes = { type: '',
                       message: ''};
    setEditProductResp(editProductRes);
  }

  useMemo(() => {
    if (productData && productData.edit_product_error && productData.edit_product_error.object) {
      editProductResp.type = 'error'
      editProductResp.message = productData.edit_product_error.object.error
      setEditProductResp(editProductResp);
    }
  }, [productData])

  useMemo(() => {
    if (productData && productData.stock_product_error && productData.stock_product_error.object) {
      editProductResp.type = 'error'
      editProductResp.message = productData.stock_product_error.object.error
      setEditProductResp(editProductResp);
    }
  }, [productData])

  useMemo(() => {
    if (productData && productData.edit_product_price_error && productData.edit_product_price_error.object) {
      editProductResp.type = 'error'
      editProductResp.message = productData.edit_product_price_error.object.error
      setEditProductResp(editProductResp);
    }
  }, [productData])

  useMemo(() => {
    if (productData && productData.delete_product_group && productData.delete_product_group_loaded && saveState == 'delete') {
      setSaveState('');
      dispatch(fetchsingleproduct(state.productId));
    }
  }, [productData])

  useMemo(() => {
    if (productData && productData.single_product) {
      setIndexState(0)
      setProductAddonsloaded(false)
      setProductAddonsResult(productData.single_product.data);
      if (productData.single_product_loaded) {
        setProductAddonsloaded(true);
      }
    }
  }, [productData])
  // when allExpenseResult data change than data add into constant,hook End
  const [allproductsloaded, setAllproductsloaded] = useState('')

  
 
  

 




  return (
    <>
      
      
      

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
         
                <div className="heading-layout1">
                  <div className="item-title">
                    <h3>Edit Product</h3>

                  </div>

                </div>
                {editProductResp && editProductResp.type && editProductResp.type == 'success' ? <div style={{textAlign:'center',color:'green'}}>{editProductResp.message}</div>
                  : (editProductResp.type == 'stock_success' ? <div style={{textAlign:'center',color:'green'}}>{editProductResp.message}</div>
                    : (editProductResp.type == 'price_success' ? <div style={{textAlign:'center',color:'green'}}>{editProductResp.message}</div>
                    : <div style={{textAlign:'center',color:'red'}}>{editProductResp.message}</div>))
                  }
                <div className="card-body">  
                <form className="new-added-form" onSubmit={(e) =>editProductHandler(e)}>               
                  <div className="row">
                    <div className="col-lg-6 col-12 form-group">
                    <label>Product Name</label>
                      <input type="text" placeholder="Product Name" className="form-control" value={productValues.name} onChange={(e) =>setProductValues({...productValues, name:e.target.value})} required/>
                    </div>
                    
                    <div className="col-lg-6 col-12 form-group">
                    <label>Product Description</label>
                      <textarea placeholder="Product Description" className="form-control" onChange={(e) =>setProductValues({...productValues, description:e.target.value})} >
                        {productValues.description}
                      </textarea>
                    </div>
                    <div className="col-lg-6 col-12 form-group">
                    <label>Product Short Description</label>
                        <textarea placeholder="Product Short Description" className="form-control" onChange={(e) =>setProductValues({...productValues, shortDescription:e.target.value})} >
                        {productValues.shortDescription}
                        </textarea>
                     </div>   
                  
                    <div className="col-12 form-group mg-t-8">
                      <button type="submit" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" style={{float:'right', paddingTop:'5px', paddingBottom:'5px', paddingLeft:'15px', paddingRight:'15px'}}>Save</button>
                    </div>
                  </div>
                </form>
                </div>

                <div className="card-body mg-t-20 pad-5">
                   <div className="row btmrow" style={{background:'none', border: 'none'}}>
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                          <div className="status product"><span className="lables">Product Stock Enable / Disable</span> <span><Switch onChange={(e) => updateProductStatus(productValues.productId, productValues.stockStatus)} checked={productValues.stockStatus}
                             checkedIcon={false}
                             boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                             activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/></span>
                          </div>
                      </div>       
                    </div>
                </div>

                <div className="card-body mg-t-20 pad-l-5">
                    <div className="col-lg-6 col-12 form-group">
                      <label>Price: $ </label>
                      <input type="number" placeholder="Price" className="form-control" style={{display:'inline', width:'auto'}} value={productValues.price} onChange={(e) =>setProductValues({...productValues, price:e.target.value})} required/>
                      <button type="button" onClick={(e)=>editProductPrice()} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" style={{marginLeft:'10px', paddingTop:'5px', paddingBottom:'5px', paddingLeft:'15px', paddingRight:'15px'}}>Save</button>
                    </div>
                </div>

             


          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
            <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}>            
               {!productAddonsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div> : 
                <><div style={{padding:'10px', color:'#000000'}}>Categories:
                     <div className="float-right">                                
                        <Link className="btn btn-primary" to={{ 
                            pathname: "/editproductcategory", 
                            state: addCategoryGroups() 
                          }}>Manage Categories Groups
                        </Link>
                     </div>
                   </div>                                                                  
                        <div className='pad-10'>                          
                            {
                              productAddonsloaded && productCategoryResult && productCategoryResult.length > 0 && productCategoryResult.map((categoryGroup, index) => {
                                return (<><div className="card-body2 mg-b-10"><div className='mg-b-10'>{index === indexState ? <span onClick={(e)=>hideCategories()}><i className='fas fa-caret-down'></i> {categoryGroup.name}</span> : <span onClick={(e)=>showCategories(index)}><i className='fas fa-caret-right'></i> {categoryGroup.name}</span>} <span style={{float: 'right'}} onClick={(e)=>removeCategoryGroup(categoryGroup.catId)}><i className="fas fa-trash"></i></span>{/*<span style={{float: 'right'}}><Link to={{ pathname: "/edit_addons", state: editAddonGroup(addonGroup) }}><i className="fas fa-edit"></i></Link></span>*/} </div>                               
                                 <table className={index === indexState ? 'table data-table text-nowrap' : 'd-none table data-table text-nowrap'}>
                                  <tbody><thead></thead>
                                   {categoryGroup.categories && categoryGroup.categories.length > 0 &&
                                      categoryGroup.categories.map((category, index) => {
                                         return (                              
                                              <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                                <td className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                                <td className='no-border'>{category.name}</td>
                                                <td className='no-border'>$ {category.unitPrice}</td>                              
                                                <td className='no-border'><i className="fas fa-ellipsis-v"></i></td>
                                              </tr>                              
                                         )
                                      })
                                   }
                                  </tbody>
                                </table></div>
                               </>)
                              })                          
                            }
                        </div>
               </> }
            </div>
          
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
            <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}>            
               {!productAddonsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div> : 
                <><div style={{padding:'10px', color:'#000000'}}>Addons:
                     <div className="float-right">                                
                        <Link className="btn btn-primary" to={{ 
                            pathname: "/edit_addons", 
                            state: addAddonGroups() 
                          }}>Manage Addons Groups
                        </Link>
                     </div>
                   </div>                                                                  
                        <div className='pad-10'>                          
                            {
                              productAddonsloaded && productAddonsResult && productAddonsResult.length > 0 && productAddonsResult.map((addonGroup, index) => {
                                return (<><div className="card-body2 mg-b-10"><div className='mg-b-10'>{index === indexState ? <span onClick={(e)=>hideAddons()}><i className='fas fa-caret-down'></i> {addonGroup.name}</span> : <span onClick={(e)=>showAddons(index)}><i className='fas fa-caret-right'></i> {addonGroup.name}</span>} <span style={{float: 'right'}} onClick={(e)=>removeGroup(addonGroup.addOnGroupId)}><i className="fas fa-trash"></i></span>{/*<span style={{float: 'right'}}><Link to={{ pathname: "/edit_addons", state: editAddonGroup(addonGroup) }}><i className="fas fa-edit"></i></Link></span>*/} </div>                               
                                 <table className={index === indexState ? 'table data-table text-nowrap' : 'd-none table data-table text-nowrap'}>
                                  <tbody><thead></thead>
                                   {addonGroup.addons && addonGroup.addons.length > 0 &&
                                      addonGroup.addons.map((addon, index) => {
                                         return (                              
                                              <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                                <td className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                                <td className='no-border'>{addon.name}</td>
                                                <td className='no-border'>$ {addon.unitPrice}</td>                              
                                                <td className='no-border'><i className="fas fa-ellipsis-v"></i></td>
                                              </tr>                              
                                         )
                                      })
                                   }
                                  </tbody>
                                </table></div>
                               </>)
                              })                          
                            }
                        </div>
               </> }
            </div>
          
          </div>
        </div>
      </div>
    

    </>

  );
}

export default EditProduct;
