import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from 'react-redux'

import { updateCategory, fetchproducts } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";

function EditCategory() {
  // store data access start
  const categoryData = useSelector(state => state.AllCategories)

  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  const { state } = useLocation();
  const history = useHistory();
  // component all states define start
  const [allProductResult, setAllProductResult] = useState([]);
  const [allproductsloaded, setAllproductsloaded] = useState('')
  const [editCategoryResp, setEditCategoryResp] = useState({
    type: '',
    message: ''
  })
  const [inputValues, setInputValues] = useState({
    catID: state.catID,
    catName: state.catName,
    catDes: state.catDes,
    orderIndex: state.orderIndex,
    catStatus: state.catStatus
  })
  const [saveState, setSaveState] = useState(false)

  // setAllProductResult([])
  useEffect(() => {    
    dispatch(fetchproducts(state.catID));
    resetEditCategory();
  }, [dispatch])

  useMemo(() => {
    if (categoryData && categoryData.edit_category && saveState) {
      editCategoryResp.type = 'success'
      editCategoryResp.message = 'Updated Successfully!'
      setEditCategoryResp(editCategoryResp);
    }
  }, [categoryData])

  const resetEditCategory = () => {
    editCategoryResp.type = ''
    editCategoryResp.message = ''
    setEditCategoryResp(editCategoryResp);
  }

  const editProductClick = (item) => {  
    history.push({
       pathname: "/edit_product",
       state: editProduct(item)
    })
  }

  const goToBack = () => {
      history.goBack();
  }

  const editProduct = (item) => {
    let product = {
      catName: state.catName,
      productId: item.productId,
      name: item.name,
      description: item.descriptions,
      shortDescription: item.shortDescription,
      price: item.price,
      stockStatus: item.inStock
    };
    return product;
  }

  useMemo(() => {
    if (categoryData && categoryData.edit_category_error && categoryData.edit_category_error.object) {
      editCategoryResp.type = 'error'
      editCategoryResp.message = categoryData.edit_category_error.object.error
      setEditCategoryResp(editCategoryResp);
    }
  }, [categoryData])



  useMemo(() => {
    if (categoryData && categoryData.all_products) {
      setAllproductsloaded(false);
      setAllProductResult(categoryData.all_products.data);
      if (categoryData.all_products_loaded) {
        setAllproductsloaded(true);
      }
    }
  }, [categoryData]);

  // examHandler function start
  const editCatHandler = (event) => {
    event.preventDefault()
    resetEditCategory();
    const category_info = inputValues;
    setSaveState(true);
    dispatch(updateCategory(category_info))
  }


  // examHandler function start
  const editCatStatusClick = (e) => {
   
    setInputValues({ ...inputValues, catStatus: e.target.value });
   
  }
  // examHandler function End


  return (
    <>
      {/* Breadcubs Area Start Here */}
      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e)=>goToBack()}>Back</button>
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li onClick={(e)=>goToBack()}>
            All Categories
          </li>
          <li>{state.catName}</li>
        </ul>
      </div>


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">

            <div className="card-body">

              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Edit Category</h3>

                </div>

              </div>
              <form className="new-added-form" onSubmit={(e) => editCatHandler(e)}>
                {editCategoryResp && editCategoryResp.type && editCategoryResp.type == 'success' ? <div style={{ textAlign: 'center', color: 'green' }}>{editCategoryResp.message}</div>
                  : <div style={{ textAlign: 'center', color: 'red' }}>{editCategoryResp.message}</div>}

                <div className="row">
                  <div className="col-lg-6 col-12 form-group">
                    <label>Category Name</label>
                    <input type="text" placeholder className="form-control" value={inputValues.catName} onChange={(e) => setInputValues({ ...inputValues, catName: e.target.value })} required />
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <label>Category Description</label>
                    <input type="text" placeholder className="form-control" value={inputValues.catDes} onChange={(e) => setInputValues({ ...inputValues, catDes: e.target.value })} required />
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <label>Order Index</label>
                    <input type="number" placeholder className="form-control" value={inputValues.orderIndex} onChange={(e) => setInputValues({ ...inputValues, orderIndex: e.target.value })} required />
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <FormGroup>
                      <FormLabel>Select Status</FormLabel>
                      <FormControl
                        required
                        type="text"
                        value={inputValues.catStatus}
                        onChange={(e) => editCatStatusClick(e)}
                        as="select">
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>

                      </FormControl>
                    </FormGroup>
                  </div>
                  <div className="col-12 form-group mg-t-20">
                    <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow float-right">Save</button>            
                  </div>
                </div>
              </form>
            </div>


          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">

            <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}>
            {!allproductsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div> :
               <><div style={{padding:'10px', color:'#000000'}}>Items:</div>
               <table className="table data-table text-nowrap">
                 <tbody><thead></thead>
                   {allproductsloaded && allProductResult &&
                        allProductResult.length > 0 &&
                        allProductResult.map((item, index) => {
                            return (                                                                                                         
                               <tr onClick={(e)=>editProductClick(item)} style={{ borderBottom: '1px solid #dee2e6' }}>
                                 <td className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                 <td className='no-border'>{item.name}</td>
                                 <td className='no-border'>$ {item.price}</td>
                                 {item.inStock ? <td className='no-border'>In Stock</td> : <td className='no-border'>Out of Stock</td>}                                 
                                 <td className='no-border'><i className="fas fa-ellipsis-v"></i></td>
                               </tr>                    
                            )
                        })
                    }
                 </tbody>
               </table></> }
            </div>
          
          </div>
        </div>
      </div>

    </>

  );
}

export default EditCategory;
