import React,{useState}from 'react';
import { Link } from 'react-router-dom';
import AddEvent from './AddEvent';
import { Card } from 'react-bootstrap';
import Events from './Events';
import Coupons from './Coupons';

import AddCoupons from './AddCoupons';

function AllEvents() {
        return (        
          <div>
          <div className="breadcrumbs-area">
            <h3>Specials</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Specials</li>
            </ul>
          </div>


         

      
            {/* Breadcubs Area Start Here */}

            <div className="row">
            <Events/>
            <AddEvent />
            </div>
            
         
          </div>
        );
}

export default AllEvents;
