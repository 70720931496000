import {
  ADD_HOLIDAY_REQUEST,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_FALIURE,
  DELETE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FALIURE
} from './AddHolidayConstants'

const intialstate = {
  add_holiday_loading : false,
  add_holiday : '',
  add_holiday_error : '',
  add_holiday_success : false,
  delete_holiday_loading : false,
  delete_holiday : '',
  delete_holiday_error : '',
  delete_holiday_success : false
}

const AddHolidayReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ADD_HOLIDAY_REQUEST : return{
      ...state,
      add_holiday_loading:true
    }
    case ADD_HOLIDAY_SUCCESS : return{
      ...state,
      add_holiday_loading:false,
      add_holiday_success:true,
      add_holiday:action.payload
    }
    case ADD_HOLIDAY_FALIURE : return{
      ...state,
      add_holiday_error:action.payload
    }    
    case DELETE_HOLIDAY_REQUEST : return{
      ...state,
      delete_holiday_loading:true
    }
    case DELETE_HOLIDAY_SUCCESS : return{
      ...state,
      delete_holiday_loading:false,
      delete_holiday_success:true,
      delete_holiday:action.payload
    }
    case DELETE_HOLIDAY_FALIURE : return{
      ...state,
      delete_holiday_error:action.payload
    }
    default: return state
  }

}

export default AddHolidayReducers
