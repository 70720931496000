import React, { useEffect, useMemo, useState } from 'react'
import { deleteCoupon, fetchAllCouponsList } from '../Redux/AllCoupon/AllCouponsActions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from '@mui/material';
import { Button, Pagination } from 'react-bootstrap';

export default function Coupons() {
const dispatch = useDispatch();
       
       const [allCoupons,setAllCoupons] = useState([])
       const allCouponsData = useSelector(state =>state.AllCoupon);
       const [currentPage, setCurrentPage] = useState(0);
       const [totalPages, setTotalPages] = useState(0);
       const [pageSize, setPageSize] = useState(10);
       console.log(allCouponsData);

    useEffect(() =>{
        dispatch(fetchAllCouponsList(currentPage))
       },[dispatch])

       

       useMemo(() =>{
        if(allCouponsData && allCouponsData.all_coupons && allCouponsData.all_coupons.data && allCouponsData.all_coupons.data.length > 0){
          setTotalPages(Math.ceil(allCouponsData.all_coupons.total / pageSize));  
          setAllCoupons(allCouponsData.all_coupons.data)
        }
      },[allCouponsData])

      const handleDeleteCoupon = (groupId) => {
     if(window.confirm("Are you sure you want to delete?")){
      dispatch(deleteCoupon(groupId))
      setCurrentPage(0)
     }
       
      
      }


      const onPageChange = (currentPage) => {
        setCurrentPage(currentPage);
        dispatch(fetchAllCouponsList(currentPage))
      };


      const renderPagination = () => {
        let items = [];
        for (let i = 0; i < totalPages; i++) {
          if (i == currentPage || i == 0 || i == (parseInt(currentPage)-1) || i == (parseInt(currentPage)+1) || i == (totalPages-1)) items.push(
        <Pagination.Item
        key={i}
        active={i == currentPage}
        onClick={() => onPageChange(i)}
        >
        {(i == totalPages-1)?`...${(i+1)}`: i == 0 ?`${(i+1)}...`:(i+1)}
        </Pagination.Item>
        );
        }
        return (
        <Pagination style={{ overflowX: "auto" }}>
        <Pagination.Prev
        disabled={currentPage === 0}
        onClick={() => onPageChange(currentPage - 1)}
        />
        {items}
        <Pagination.Next
        disabled={currentPage === totalPages-1}
        onClick={() => onPageChange(currentPage + 1)}
        />
        </Pagination>
        );
        };

  return (
    <div className="col-8-xxxl col-12" style={{marginLeft:"-1%"}}>
 
      <div className="card-body">
      <div className="">
            <div className="item-title">
              <h3>Coupons</h3>
            </div>

          </div>
      <table className="table data-table text-nowrap">
                              <thead>
                                <tr>
                                  <th>
                                    Name
                                  </th>
                                
                                  <th>
                                    Description
                                  </th>
                                  {/* <th>Expired</th> */}
                                  <th>Action</th>

                                </tr>
                              </thead>
                              <tbody>
                              {allCoupons.map((item,index) => {
return (  
<tr>
<td>{item.name}</td>
<td>{item.description}</td>
{/* <td>{item.expired}</td> */}

<td className="entry-delete">
{/*<button type="button" className="btn btn-info restdelete"><i className="fa fa-edit" aria-hidden="true"></i></button>
 &nbsp;&nbsp;*/}
  <button type="button" onClick={e=>handleDeleteCoupon(item.name)} className="btn btn-danger restdelete"><i className="fa fa-trash" aria-hidden="true"></i></button>
  </td>

</tr>

)
})}

                              </tbody>
                            </table>
    
</div>
     

<div className='row'>
        <div className="col-12-xxxl col-12">     
         <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
        
            </div>
       
          </div>

  
  </div>

  )
}
