import React,{Component} from 'react';
import './App.css';
import Admindashboard from './Components/Admindashboard';
import { connect } from "react-redux";

class App extends Component {
  render() {
    return (
          <>
            <Admindashboard />
          </>
    );	
  }
}

const mapStateToProps = state => ({
  isLogin: state.LoginCheck
});

export default connect(
  mapStateToProps
)(App);