import {
  WAKE_UP_REQUEST,
  WAKE_UP_SUCCESS,
  WAKE_UP_FALIURE
} from './WakeUpSettingsConstants'
import {config} from '../config'

export const wakeUpSettingsRequest = (wake_up) =>{
  return{
    type : WAKE_UP_REQUEST,
    payload : wake_up
  }
}

export const wakeUpSettingsSuccess = (wake_up_success) =>{
  return{
    type : WAKE_UP_SUCCESS,
    payload : wake_up_success
  }
}

export const wakeUpSettingsFaliure = (error) =>{
  return{
    type : WAKE_UP_FALIURE,
    payload : error
  }
}


export const wakeUpHours = (hours) =>{
    return(dispatch) => {
      dispatch(wakeUpSettingsRequest())
      const url =`${config.api_root}/api/v1/users/shut_shop_until?user_id=${config.user_id}&hours=${hours}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
      }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(wake_up_res =>{
      const wake_up_res_success = wake_up_res
      var dateNow = new Date();
      var tm = dateNow.getTime();
      dateNow.setTime(tm + (hours*60*60*1000));
      const times = dateNow.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
      if(wake_up_res_success.success=="true"){
         let res = {
           success:'true',
           times: times
         }
         dispatch(wakeUpSettingsSuccess(res))
      }
    })
    .catch(error => {
      const errorMsg = error
      dispatch(wakeUpSettingsFaliure(errorMsg))
    })
    }
}

