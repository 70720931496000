import {
  FETCH_AllRESTTIMINGS_REQUEST,
  FETCH_AllRESTTIMINGS_SUCCESS,
  FETCH_AllRESTTIMINGS_FALIURE
} from './AllRestTimingsConstants'

const intialstate = {
  all_rest_timings_loading : false,
  all_rest_timings : [],
  all_rest_timings_error : ''
}

const AllRestTimingReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_AllRESTTIMINGS_REQUEST : return{
      ...state,
      all_rest_timings_loading:true
    }
    case FETCH_AllRESTTIMINGS_SUCCESS : return{
      ...state,
      all_rest_timings_loading:false,
      all_rest_timings:action.payload
    }
    case FETCH_AllRESTTIMINGS_FALIURE : return{
      ...state,
      all_rest_timings_error:action.payload
    }
    default: return state
  }

}

export default AllRestTimingReducers
