import {
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FALIURE
} from './DownloadReportConstants'
import {config} from '../config'

export const downloadReportRequest = (download_report_info) =>{
  return{
    type : DOWNLOAD_REPORT_REQUEST,
    payload : download_report_info
  }
}

export const downloadReportSuccess = (download_report_success) =>{
  return{
    type : DOWNLOAD_REPORT_SUCCESS,
    payload : download_report_success
  }
}

export const downloadReportFaliure = (error) =>{
  return{
    type : DOWNLOAD_REPORT_FALIURE,
    payload : error
  }
}

export const downloadRport = (report_info) =>{
    return(dispatch) => {
      dispatch(downloadReportRequest(report_info))
      const url =`${config.api_root}/api/v1/merchants/download_report?user_id=${config.user_id}&from=${report_info.from_date}&to=${report_info.to_date}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      const add_timing_success = add_timing_res
   dispatch(downloadReportSuccess(add_timing_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(downloadReportFaliure(errorMsg))
    })
}
}

export const downloadTodayRport = (currentDate) =>{
  return(dispatch) => {
      dispatch(downloadReportRequest(currentDate))
      const url =`${config.api_root}/api/v1/merchants/download_report?user_id=${config.user_id}&from=${currentDate}&to=${currentDate}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      const add_timing_success = add_timing_res
    dispatch(downloadReportSuccess(add_timing_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(downloadReportFaliure(errorMsg))
    })
  }
}


export const downloadReportUpdate = (download_report_info) =>{
    return(dispatch) => {
      dispatch(downloadReportRequest(download_report_info))
      const url =`${config.api_root}/api/v1/users/shut?user_id=${config.user_id}&shut=${download_report_info}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(download_report_res =>{
      const download_report_success = download_report_res
      dispatch(downloadReportSuccess(download_report_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(downloadReportFaliure(errorMsg))
    })
    }
}
