import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FALIURE,
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FALIURE
} from './AddressCommonsConstants'
import {config} from '../config'

export const countriesRequest = (countries) =>{
  return{
    type : COUNTRIES_REQUEST,
    payload : countries
  }
}

export const countriesSuccess = (countries_success) =>{
  return{
    type : COUNTRIES_SUCCESS,
    payload : countries_success
  }
}

export const countriesFaliure = (error) =>{
  return{
    type : COUNTRIES_FALIURE,
    payload : error
  }
}

export const statesRequest = (states) =>{
  return{
    type : STATES_REQUEST,
    payload : states
  }
}

export const statesSuccess = (states_success) =>{
  return{
    type : STATES_SUCCESS,
    payload : states_success
  }
}

export const statesFaliure = (error) =>{
  return{
    type : STATES_FALIURE,
    payload : error
  }
}

export const fetchCountries = () =>{
    return(dispatch) => {
      dispatch(countriesRequest())
      const url =`${config.api_root}/api/v1/enterprised/countries?`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(countries_res =>{
      const countries_res_success = countries_res
      dispatch(countriesSuccess(countries_res_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(countriesFaliure(errorMsg))
    })
    }
}

export const fetchStates = (countryId) =>{
  return(dispatch) => {
    dispatch(statesRequest())
    const url =`${config.api_root}/api/v1/enterprised/countries/states?country_id=`+countryId+`&pageSize=500&status=ACTIVE`;
    const request_option = {
    method: "GET",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(states_res =>{
    const states_res_success = states_res
    dispatch(statesSuccess(states_res_success))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(statesFaliure(errorMsg))
  })
  }
}
