import {
  DELIVERY_SHUT_REQUEST_UPDATE,
  DELIVERY_SHUT_REQUEST,
  DELIVERY_SHUT_SUCCESS,
  DELIVERY_SHUT_FALIURE
} from './DeliveryShutConstants'
import {config} from '../config'

export const deliveryShutRequest = (delivery_shut_info) =>{
  return{
    type : DELIVERY_SHUT_REQUEST,
    payload : delivery_shut_info
  }
}

export const deliveryShutRequestUpdate = (delivery_shut_info) =>{
  return{
    type : DELIVERY_SHUT_REQUEST_UPDATE,
    payload : delivery_shut_info
  }
}

export const deliveryShutSuccess = (delivery_shut_success) =>{
  return{
    type : DELIVERY_SHUT_SUCCESS,
    payload : delivery_shut_success
  }
}

export const deliveryShutFaliure = (error) =>{
  return{
    type : DELIVERY_SHUT_FALIURE,
    payload : error
  }
}

export const deliveryShut = (delivery_shut_info) =>{
    return(dispatch) => {
      dispatch(deliveryShutRequest(delivery_shut_info))
      const url =`${config.api_root}/api/v1/users/shut_shop?user_id=${config.user_id}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(delivery_shut_res =>{
      const delivery_shut_success = delivery_shut_res
      dispatch(deliveryShutSuccess(delivery_shut_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(deliveryShutFaliure(errorMsg))
    })
    }
}

export const deliveryShutUpdate = (delivery_shut_info) =>{
    return(dispatch) => {
      let req = {
        success: false,
        status: delivery_shut_info
      }
      dispatch(deliveryShutRequestUpdate(req))
      const url =`${config.api_root}/api/v1/users/shut_shop?user_id=${config.user_id}&shut=${delivery_shut_info}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(delivery_shut_res =>{
      const delivery_shut_success = delivery_shut_res
      let res = {
        success: delivery_shut_success.success,
        status: delivery_shut_info
      }
      dispatch(deliveryShutSuccess(res))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(deliveryShutFaliure(errorMsg))
    })
    }
}
