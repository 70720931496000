import React, { useRef, useState, useMemo} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addConnection } from '../Redux/AddConnection/AddConnectionActions';

function PrintCommon(props) {

    const [isMountRender, setMountRender] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState("");
    const [loading, setLoading] = useState("");
    const AddConnectionData = useSelector(state =>state.AddConnection);

    const dispatch = useDispatch();
    const printerIPAddress = localStorage.getItem("ipAddress");
    const printerPort = localStorage.getItem("port");
  
    const ePosDevice = useRef();
    const printer = useRef();
   

    useMemo(() =>{
      if(AddConnectionData && AddConnectionData.add_connection){
        printer.current = AddConnectionData.add_connection;
            
      }
    },[AddConnectionData]) 
  
    const connect = (isReconnect) => {
        setMountRender(false)
        if (!printerIPAddress) {
          alert("Type the printer IP address");
        }
        if (!printerPort) {
          alert("Type the printer port");
          return;
        }
        if(!props.isNotify) {
           setConnectionStatus("");
           setLoading('true')
        }
        let ePosDev = new window.epson.ePOSDevice();
        ePosDevice.current = ePosDev;
  
        ePosDev.connect(printerIPAddress, printerPort, (data) => {
          if (data == "OK" || data == "SSL_CONNECT_OK") {
            ePosDev.createDevice(
             "local_printer",
             ePosDev.DEVICE_TYPE_PRINTER,
             { crypto: false, buffer: false },
             (devobj, retcode) => {
              if (retcode === "OK") {
                printer.current = devobj;
                dispatch(addConnection(printer.current));
              
                if(isReconnect === false){
                     print(props)
                }
                if(!props.isNotify) {
                   setConnectionStatus('Connected');
                   setLoading('false')
                }
              } else {
                if(!props.isNotify) {
                   setConnectionStatus(retcode);
                   setLoading('false')
                }
                throw retcode;
              }
             }
            );
          } else {
            setConnectionStatus(data);
            setLoading('false')
            throw data;
          }
        }); 
    };
  
    const print = (props) => {
      let noOfCopies = 1;
      if(localStorage.getItem("number_of_copies")){
        noOfCopies = parseInt(localStorage.getItem("number_of_copies"));
      }
      for(let i = 0; i < noOfCopies; i++) {
        setMountRender(false)
        let prn = printer.current;
      
        let items = props.items;
       
        let datamap = props.datamap;
   
        if (!prn) {
           alert("Not connected to printer");
           return;
        }
        prn.addTextSmooth(true);
        prn.addTextFont(prn.FONT_A);
        prn.addTextSize(2, 2);
        prn.addTextStyle(false, false, true, undefined);
        prn.addTextAlign(prn.ALIGN_CENTER);
        prn.addText(datamap.get("restaurantName")+ "\n");

        prn.addFeedLine(1);
        prn.addTextSize(1, 1);     
        prn.addTextStyle(false, false, true, undefined);
        prn.addTextAlign(prn.ALIGN_LEFT);
        
        let textData = "\tOrder: " + datamap.get("orderId")     
        + "\n\tDate: " + datamap.get("date");
        prn.addText(textData);
        
        prn.addTextStyle(false, false, true, undefined);
        textData = "\n\t===================================\n";
        prn.addText(textData);

        items.forEach(item => {
           //start
           prn.addTextSize(2,1);
           prn.addTextStyle(false, false, true, undefined);
           prn.addTextAlign(prn.ALIGN_LEFT);        
           textData = item.get("item") + "\n";
           prn.addText(textData);
        
           if(item.get("addons")){
              prn.addTextStyle(false, false, true, undefined);
              prn.addTextSize(1, 1);
              textData = item.get("addons") +"\n";
              prn.addText(textData);
           }
           //end
        });

        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextStyle(false, false, true, undefined);
        prn.addTextSize(1, 1);
        textData = "\t===================================\n";
        prn.addText(textData);

        if(datamap.get("userName")) {
            textData = "\tOrder By: " + datamap.get("userName");
            prn.addText(textData);
        }

        if(datamap.get("mobile")) {
            textData = "\n\tMobile No.: " + datamap.get("mobile");
            prn.addText(textData);
        }

        if(datamap.get("address1")) {
            textData = "\n\t" + datamap.get("address1");
            prn.addText(textData);          
        }

        if(datamap.get("city")) {
            textData = "\n\t" + datamap.get("city");
            prn.addText(textData);
        }

        if(datamap.get("orderType")) {
            textData = "\n\tOrder Type: " + datamap.get("orderType")+"\n\n";
            prn.addText(textData);
        }

       

        prn.addTextSmooth(true);
        prn.addTextFont(prn.FONT_A);
        prn.addTextSize(1, 1);
        prn.addTextStyle(false, false, true, undefined);
        prn.addTextAlign(prn.ALIGN_CENTER);
        prn.addText("Powered By DiningTek\n");


        if(datamap.get("note")) {         
            textData = "\n\t===================================\n";
            prn.addText(textData);
            textData = datamap.get("note");
            prn.addText("\t"+textData);
        }
        prn.addFeedLine(2);
        prn.addCut(prn.CUT_FEED);
        prn.send();
      }
    }; 

    return (
        <>
          {
            (localStorage.getItem("auto_print") == 'true' && props.isNotify && isMountRender) ? (printer.current =='undefined' || null == printer.current) ? connect(false) : print(props)
            : ''
          }
          
          <div className="right-float">
          { loading == 'false' ? connectionStatus == 'Connected' ? <span className='text-green pad-r-5'>{connectionStatus}</span> : <span className='text-red pad-r-5'>{connectionStatus}</span> : <span></span>} 
          {!connectionStatus && loading == 'true' ? <span className="pad-r-5 text-green"><i className="fa fa-spinner fa-spin"></i></span> : <span></span>}
                <button type="button" className="btn btn-info btn-lg mg-r-10"
                       onClick={() => {(printer.current == 'undefined' || null == printer.current) ? connect(false) : print(props)}}><i className='fas fa-print'> Print</i></button>

                <button type="button" className="btn btn-light btn-lg"
                       onClick={() => connect(true)}><i className='fas fa-paper-plane'> Reconnect</i></button>                        
          </div>
        </>
    );
};

export default PrintCommon;