import {
  EGIFTCARD_REDEEM_REQUEST,
  EGIFTCARD_REDEEM_SUCCESS,
  EGIFTCARD_REDEEM_FALIURE,
  EGIFTCARD_BALANCE_REQUEST,
  EGIFTCARD_BALANCE_SUCCESS,
  EGIFTCARD_BALANCE_FALIURE
} from './EGiftCardRedeemConstants'
import {config} from '../config'

export const eGiftCardRedeemRequest = (egift_card_redeem) =>{
  return{
    type : EGIFTCARD_REDEEM_REQUEST,
    payload : egift_card_redeem
  }
}

export const eGiftCardRedeemSuccess = (egift_card_redeem_success) =>{
  return{
    type : EGIFTCARD_REDEEM_SUCCESS,
    payload : egift_card_redeem_success
  }
}

export const eGiftCardRedeemFaliure = (error) =>{
  return{
    type : EGIFTCARD_REDEEM_FALIURE,
    payload : error
  }
}

export const eGiftCardBalanceRequest = (egift_card_balance) =>{
  return{
    type : EGIFTCARD_BALANCE_REQUEST,
    payload : egift_card_balance
  }
}

export const eGiftCardBalanceSuccess = (egift_card_balance_success) =>{
  return{
    type : EGIFTCARD_BALANCE_SUCCESS,
    payload : egift_card_balance_success
  }
}

export const eGiftCardBalanceFaliure = (error) =>{
  return{
    type : EGIFTCARD_BALANCE_FALIURE,
    payload : error
  }
}

export const payByEGiftCard = (ecard) =>{
    return(dispatch) => {
      dispatch(eGiftCardRedeemRequest())
      const url =`${config.api_root}/api/v1/users/business/order/egift/offline_redeem`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
          user_id : config.user_id,
          fields:{
            claimAmount: ecard.amount,
            giftCard:[{
               card_number: ecard.card_number,
               pin: ecard.pin
            }]
          },
      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(egiftcard_res =>{
      const egiftcard_res_success = egiftcard_res
      dispatch(eGiftCardRedeemSuccess(egiftcard_res_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(eGiftCardRedeemFaliure(errorMsg))
    })
    }
}       

export const checkEGiftCardBalance = (ecard) =>{
  return(dispatch) => {
    dispatch(eGiftCardBalanceRequest())
    const url =`${config.api_root}/api/v1/instrument/giftcards/balance`;
    const request_option = {
    method: "POST",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
    body: JSON.stringify({
      card_number: ecard.card_number,
      pin: ecard.pin
    })    
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(countries_res =>{
    const countries_res_success = countries_res
    dispatch(eGiftCardBalanceSuccess(countries_res_success))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(eGiftCardBalanceFaliure(errorMsg))
  })
  }
}
