import React, {useState,useEffect,useRef,useMemo} from 'react';

//var CanvasJSReact = require('@canvasjs/react-charts');
import ReactApexCharts from "react-apexcharts";
import Socialmedia from './Socialmedia';
//import Notification from './Notification';
import Holidays from './Holidays';
import Breadcubs from './Breadcubs';
import { useSelector,useDispatch } from 'react-redux';
import { fetchAllBook, acceptOrder, declineOrder, fetchOrderDetail, fetchAllDetails } from '../Redux/AllBook/AllBookActions';
import { restShut, restShutUpdate } from '../Redux/RestShut/RestShutActions';
import { wakeUpHours } from '../Redux/WakeUpSettings/WakeUpSettingsActions';

import { registerDevice } from '../Redux/Login/LoginActions';

import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import fees from '../Assets/Images/fees.png';
import system from '../Assets/Images/system.png';
import discount from '../Assets/Images/discount.png';
import delivery from '../Assets/Images/delivery.png';
import gift from '../Assets/Images/gift.png';
import tax from '../Assets/Images/tax.png';
import tips from '../Assets/Images/tips.png';
import payment from '../Assets/Images/payment.png';
import { Doughnut } from 'react-chartjs-2';
import { VictoryPie } from 'victory-pie';
import { VictoryTooltip } from 'victory-tooltip';
import { config } from '../Redux/config';
import toast from 'react-hot-toast';
import { Link } from '@mui/material';



const options = {
  series: [
  
    ],
  chart: {
  width: 380,
  type: "donut",
  },
  dataLabels: {
  enabled: false,
  },
  fill: {
    type: 'gradient',
  },
  responsive: [
  {
  breakpoint: 480,
  options: {
  chart: {
  width: 200,
  },
  legend: {
  show: false,
  },
  },
  },
  {
    breakpoint: 1366, // change the chart options when the screen width is less than or equal to 1366px
    options: {
    chart: {
    width: 200, // reduce the chart width
    offsetX: -50, // move the chart 50px to the left
    // offsetY: 150 // move the chart 50px to the bottom
    },
    legend: {
    position: 'bottom' // move the legend to the bottom
    }
    }
    },
  //   {
  //   breakpoint: 1024, // change the chart options when the screen width is less than or equal to 1024px
  //   options: {
  //   chart: {
  //   width: 200, // reduce the chart width further
  //   offsetX: -100, // move the chart 100px to the left
  //   // offsetY: 100 // move the chart 100px to the bottom
  //   },
  //   legend: {
  //   position: 'left' // move the legend to the left
  //   }
  //   }
  // },
  ],
  // legend: {
  // position: "right",
  // offsetY: 0,
  // height: 230,
  // },
  labels: ["Weekly", "Monthly","Today"],
  legend: {
    show: true,
    position: "right",
    formatter: function (seriesName, opts) {
    let value = opts.w.config.series[opts.seriesIndex];
    return seriesName + ": " + (value === undefined ? 0 : value); // return 0 if value is undefined
    },
    },
   
  };
  

 

  
 


export const datass = {
  // labels: ['Today', 'Weekly', 'Monthly'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 10, 3],
      backgroundColor: [
       

        '#C70039', '#FFC300', '#40826D'
      ],
      borderColor: [
        '#C70039',
        '#FFC300',
        '#40826D',
       
      ],
      borderWidth: 1,
    },
  ],
};
 

function ViewSalesAnalytics(){
 

  const [label, setLabel] = useState(false);
 

    // trial above
const [restOnOff,setRestOnOff] = useState(false)
//const [deliveryOnOff,setDeliveryOnOff] = useState(false)
const allBookData = useSelector(state =>state.AllBook)
console.log(allBookData);
const allWeek = useSelector(state =>state.AllBook)

const restStateData = useSelector(state =>state.RestShutStatus)

const loginData = useSelector(state =>state.Login)
const wakeUpData = useSelector(state =>state.WakeUpSettings)

// store data access End
const dispatch = useDispatch()  // for accessing the redux function
const history = useHistory();
const [allBookResult,setBookResult] = useState([])
const [allDetailResult,setDetailResult] = useState({})
console.log(allDetailResult);
const [allWeekResult,setWeekResult] = useState({})

const [allMonthResult,setMonthResult] = useState({})

const [startDate, setStartDate] = useState();
const [endDate, setEndDate] = useState();
const [error,setError] = useState(null)
const [hour,setHour] = useState(0)
const [st,setSt] = useState(0)
const [pageNumber, setPageNumber] = useState(0)
const [data,setData] = useState([])
const [currentorderTab,setCurrentorderTab] = useState(2)
const [responsMessage, setResponsMessage] = useState('')
const [errorMessage, setErrorMessage] = useState('')
const [curTime, setCurTime] = useState('')
const listInnerRef = useRef();
//const [updateTime, setUpdateTime] = useState('')

// fetch allBook  api hook start

const { state } = useLocation();

const [week,setWeek] = useState(0);

const [indexState, setIndexState] = useState(0)


  useEffect(() =>{
  
  setSt(0);
  // dispatch(fetchAllBook(0, false))
 
  getTodayData();
  
  getWeeklyData();
  getMonthlyData();
  
  //  dispatch(fetchAllDetails(0, false, {
  //   dateFilter: {
  //     startDate: moment(startDate).format('DD-MM-YYYY'),
  //     endDate: moment(endDate).format('DD-MM-YYYY'),
  //   },
  //   filter: currentorderTab
  // }))
  // getMonthlyData();
  if (state?.order && !isNaN(state.order)) {
    // only dispatch the action if the condition is true
    dispatch(fetchOrderDetail(state.order))
    }
  dispatch(restShut())
 // dispatch(deliveryShut())
  if(localStorage.getItem('device_token') && localStorage.getItem('new_token') && localStorage.getItem('new_token') == 'true') {
     dispatch(registerDevice(localStorage.getItem('device_token')))
  }
  componentDidMount();
},[dispatch,state?.order])


// fetch allBook api hook End

const componentDidMount = () => {
  setInterval(() => {
    const time = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    setCurTime(time)  
    setSt(1);
  }, 1000)
}





// add data of allFees api into constant,hook start
useMemo(() =>{
  if(allBookData && allBookData.all_book && allBookData.all_book && !allBookData.all_book_loading){
    setError('');
    if(allBookData.is_added){
      allBookData.all_book.forEach((item) => {
        if(allBookResult.indexOf(item) == -1){
          setBookResult(allBookResult.concat(allBookData.all_book));
          console.log(setBookResult(allBookResult.concat(allBookData.all_book)));
        }
      });
    } else if(!allBookData.is_added) {
      setBookResult(allBookData.all_book)
      //setData(allBookData.all_book)
    }
  }
},[allBookData])

useMemo(() => {
  if (allWeek && allWeek.all_detail && !allWeek.all_book_loading) {
  setError("");
  if (allWeek.is_added) {
  // Use Array.from to create an array from allBookData.all_detail
  Array.from(allBookData.all_detail).forEach((item) => {
  if (allDetailResult.indexOf(item) == -1 && allWeekResult.indexOf(item) == -1 && allMonthResult.indexOf(item) == -1)  {
  setDetailResult(allBookResult.concat(allWeek.all_detail));
  console.log(setDetailResult(allBookResult.concat(allWeek.all_detail)));
  // setWeekResult(allBookResult.concat(allBookData.all_detail));
  // setMonthResult(allBookResult.concat(allBookData.all_detail));
  }
  });
  } else if (!allBookData.is_added) {
  setDetailResult(allBookData.all_detail);
  console.log(setDetailResult(allBookData.all_detail));
  // setWeekResult(allBookData.all_detail);
  // setMonthResult(allBookData.all_detail);
  //setData(allBookData.all_book)
  }
  }
  }, [allWeek]);

useMemo(() => {
  if (allBookData && allBookData.all_detail && !allBookData.all_book_loading) {
  setError("");
  if (allBookData.is_added) {
  // Use Array.from to create an array from allBookData.all_detail
  Array.from(allBookData.all_detail).forEach((item) => {
  if (allDetailResult.indexOf(item) == -1 && allWeekResult.indexOf(item) == -1 && allMonthResult.indexOf(item) == -1)  {
  // setDetailResult(allBookResult.concat(allBookData.all_detail));
  // console.log(setDetailResult(allBookResult.concat(allBookData.all_detail)));
  setWeekResult(allBookResult.concat(allBookData.all_detail));
  setMonthResult(allBookResult.concat(allBookData.all_detail));
  }
  });
  } else if (!allBookData.is_added) {
  // setDetailResult(allBookData.all_detail);
  // console.log(setDetailResult(allBookData.all_detail));
  setWeekResult(allBookData.all_detail);
  setMonthResult(allBookData.all_detail);
  //setData(allBookData.all_book)
  }
  }
  }, [allBookData]);


const onScroll = () => {
  if (listInnerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
 
    if (Math.ceil(scrollTop + clientHeight) == scrollHeight) {

      if(startDate && endDate){
        dispatch(fetchAllBook(pageNumber+1, true, {
           dateFilter: {
             startDate: moment(startDate).format('DD-MM-YYYY'),
             endDate: moment(endDate).format('DD-MM-YYYY'),
           },
           filter: currentorderTab
        }))
      } else {
        dispatch(fetchAllBook(pageNumber+1, true, {
          filter: currentorderTab
        }))
      }
      setPageNumber(pageNumber+1);
      // This will be triggered after hitting the last element.
      // API call should be made here while implementing pagination.
    }
  }
};

// add data of allFees api into constant,hook End

useMemo(() => {
  if(loginData && loginData.device) {
    if(loginData.device.object && loginData.device.object.pushEndpoint) {
      localStorage.setItem('new_token', 'false');
    }
  }
}, [loginData])

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(restStateData && restStateData.rest_shut && restStateData.rest_shut.success){
    setRestOnOff(restStateData.rest_shut.status)
  }
},[restStateData])
// useMemo(() =>{
//   if(restStateData && restStateData.rest_post_shut && restStateData.rest_post_shut.success){
//     setRestOnOff(restStateData.rest_post_shut.status)
//   }
// },[restStateData])

useMemo(() =>{
  if(wakeUpData && wakeUpData.wake_up) {
    if(wakeUpData.wake_up.success=="true") {
      setRestOnOff(false)
      dispatch(restShut())
      setErrorMessage('')
      setResponsMessage('Restaurant is closed now for '+hour+' hour(s)! Open At: '+wakeUpData.wake_up.times)
    } else if(wakeUpData.wake_up.object && wakeUpData.wake_up.object.error) {
      setResponsMessage('')
      setErrorMessage(wakeUpData.wake_up.object.error)
    }
  }
},[wakeUpData])
// add data of allFees api into constant,hook End


const handlefilterSelect = (e) => {
    setPageNumber(0);
    if(e === 'all') {
      setCurrentorderTab(0);
    }
    else {
      setCurrentorderTab(e);
    }
    if(startDate && endDate) {
      if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
      else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
      else dispatch(fetchAllBook(0, false, {
        dateFilter: {
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
        },
        filter: e
      }))
    } else {
       dispatch(fetchAllBook(0, false, {filter:e}))
    }
}


const handleDateChange = (value) =>{
  setStartDate(value)
}

const handleEndDateChange = (value) =>{
  setEndDate(value)
}

const handleOrderSelect = (orderNo) => {
  history.push({
    pathname: "/order_detail",
    state: {order: orderNo, isNotify: false}
  })
}

const filterOrders = () =>{
  if(startDate && endDate){
   setPageNumber(0); 
   if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
   else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
   else 
   dispatch(fetchAllBook(0, false, {
    dateFilter: {
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY'),
    },
    filter: currentorderTab
  }))

  }

}


  
const filterDetail = () => {
  if (startDate && endDate) {
  setPageNumber(0);
  if (
  startDate > endDate ||
  startDate > new Date() ||
  endDate > new Date()
  )
  setError('invalid Date');
  else if ((endDate - startDate) / (1000 * 3600 * 24) > 31)
  setError('Orders can not be more than 31 days!');
  else
  getTodayData();
  getWeeklyData();
  dispatch(
  fetchAllDetails(0, false, {
  dateFilter: {
  startDate: moment(startDate).format('DD-MM-YYYY'),
  endDate: moment(endDate).format('DD-MM-YYYY'),
  },
  filter: currentorderTab,
  })
  );
  }
  };


  // function today(){
  //   dispatch(
  //     fetchAllDetails(0, false, {
  //     dateFilter: {
  //     startDate: moment(startDate).format('DD-MM-YYYY'),
  //     endDate: moment(endDate).format('DD-MM-YYYY'),
  //     },
  //     filter: currentorderTab,
  //     })
  //     );
  // }

  // try part
  // function getTodayData(startDate,endDate) {
  //   startDate = moment().startOf('today').format('DD-MM-YYYY');
  //   // Set the end date to the current date
  //   endDate = moment().endOf('today').format('DD-MM-YYYY');
  //     dispatch(
  //     fetchAllDetails(0, false, {
  //     dateFilter: {
  //     startDate: startDate,
  //     endDate: endDate,
  //     },
  //     filter: currentorderTab,
  //     })
  //     );
  //   }

  const [today,setToday] = useState();
    function getTodayData(startDate, endDate,book_info) {
   
      // Set the start date to 7 days back from the current date
      startDate = moment().startOf('today').format('DD-MM-YYYY');
      // Set the end date to the current date
      endDate = moment().endOf('today').format('DD-MM-YYYY');
      console.log(startDate, "  ++++++++++ ", endDate);
      // Call the API with the date filter and the current order tab
      // const dateFilter = book_info && book_info.dateFilter ? `&from=${startDate}&to=${endDate}` : '';
      const url =`${config.api_root}/api/v1/merchants/sales_summary?user_id=${config.user_id}&from=${startDate}&to=${endDate}`;
      const request_option = {
        method: "GET",
        headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer '+ config.token,
                'Content-Type': 'application/json'
            },
        }
      fetch(url,request_option, {
        // Pass the date filter and the current order tab as query parameters
        params: {
        dateFilter: {
        startDate: startDate,
        endDate: endDate,
        },
        filter: currentorderTab,
        },
        })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.object);
         
          setToday(data.object);
         
          // totalOrders = data.object.total_orders;
          // console.log(totalOrders);
        })
        .catch((error) => console.error(error));
      
      }
    
    // Define a function to get the data for the current week
    const [orders,setOrders] = useState();

//     const getWeeklyData = (startDate, endDate,book_info) => {

//       startDate = moment().subtract(7, 'days').format('DD-MM-YYYY');
// // Set the end date to the current date
// endDate = moment().format('DD-MM-YYYY');
//       fetch(`${config.api_root}/api/v1/merchants/sales_summary?user_id=${config.user_id}&from=${startDate}&to=${endDate}`, {
//       method: 'GET',
//         headers: {
//           'Accept': 'application/json',
//           'Authorization': 'Bearer '+ config.token,
//           'Content-Type': 'application/json'
//         }
//       }).then(response => response.json())
//       .then(data => {
//         console.log(data.object);
     
//         setOrders(data.object);
 
//       }).catch(error => console.error(error))
      
//     };

   
    
   function getWeeklyData(startDate, endDate,book_info) {
   
// Set the start date to 7 days back from the current date
startDate = moment().subtract(7, 'days').format('DD-MM-YYYY');
// Set the end date to the current date
endDate = moment().format('DD-MM-YYYY');
console.log(startDate, "  ++++++++++ ", endDate);
// Call the API with the date filter and the current order tab
// const dateFilter = book_info && book_info.dateFilter ? `&from=${startDate}&to=${endDate}` : '';
const url =`${config.api_root}/api/v1/merchants/sales_summary?user_id=${config.user_id}&from=${startDate}&to=${endDate}`;
const request_option = {
  method: "GET",
  headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer '+ config.token,
          'Content-Type': 'application/json'
      },
  }
fetch(url,request_option, {
  // Pass the date filter and the current order tab as query parameters
  params: {
  dateFilter: {
  startDate: startDate,
  endDate: endDate,
  },
  filter: currentorderTab,
  },
  })
  .then((response) => response.json())
  .then((data) => {
    console.log(data.object);
   
    setOrders(data.object);
   
    // totalOrders = data.object.total_orders;
    // console.log(totalOrders);
  })
  .catch((error) => console.error(error));

}
    

const [month,setMonth] = useState();
function getMonthlyData(startDate, endDate,book_info) {
   
  // Set the start date to 7 days back from the current date
  startDate = moment().subtract(30, 'days').format('DD-MM-YYYY');
  // Set the end date to the current date
  endDate = moment().format('DD-MM-YYYY');
  console.log(startDate, "  ++++++++++ ", endDate);
  // Call the API with the date filter and the current order tab
  // const dateFilter = book_info && book_info.dateFilter ? `&from=${startDate}&to=${endDate}` : '';
  const url =`${config.api_root}/api/v1/merchants/sales_summary?user_id=${config.user_id}&from=${startDate}&to=${endDate}`;
  const request_option = {
    method: "GET",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
    }
  fetch(url,request_option, {
    // Pass the date filter and the current order tab as query parameters
    params: {
    dateFilter: {
    startDate: startDate,
    endDate: endDate,
    },
    filter: currentorderTab,
    },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data.object);
     
      setMonth(data.object);
     
      // totalOrders = data.object.total_orders;
      // console.log(totalOrders);
    })
    .catch((error) => console.error(error));
  
  }
    // Define a function to get the data for the current month
    // function getMonthlyData(startDate,endDate) {
    // // Set the start date to the first day of the month and the end date to the last day of the month
    // startDate = moment().startOf('month').format('DD-MM-YYYY');
    // endDate = moment().endOf('month').format('DD-MM-YYYY');
    // console.log(startDate, "  ++++++++++ ", endDate);
    // // Call the API with the date filter and the current order tab
    // dispatch(
    //   fetchAllDetails(0, false, {
    //   dateFilter: {
    //   startDate: startDate,
    //   endDate: endDate,
    //   },
    //   filter: currentorderTab,
    //   })
    //   );
    //   console.log(dispatch(
    //     fetchAllDetails(0, false, {
    //     dateFilter: {
    //     startDate: startDate,
    //     endDate: endDate,
    //     },
    //     filter: currentorderTab,
    //     })
    //     ));
    // }
    


  // end of try part

function viewSales(){
  history.push("/viewsalesanalytics");
}

const clearOrders = () =>{
  setStartDate();
  setEndDate();
  dispatch(fetchAllBook(0, false, {filter:currentorderTab}))
}






return (
    <>
     <Breadcubs />

    


               <div className="row">
        <div className="col-12 col-xl-12 col-8-xxxl">
          <div className="card dashboard-card-one pd-b-20">
          <div className="card-body">
             
          <div className="row">
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                         <label>Start Date</label>
                        <DatePicker selected={startDate} className="form-control" onChange={(date) => handleDateChange(date)} />
                          {error != null && error.from_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.from_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                          <label>End Date</label>
                        <DatePicker selected={endDate} className="form-control" onChange={(date) => handleEndDateChange(date)} />
                          {error != null && error.to_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.to_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-6-xxxl col-lg-4 col-12 form-group mg-t-35">
                       {/* <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>filterOrders()}>Apply Filter</button>              */}
                       <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={() => { filterOrders(); filterDetail();}}>Apply Filter</button> 
                       <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow mg-l-10" onClick={(e) =>clearOrders()}>Clear</button>
                    </div>
               </div>
            
            </div>
          </div>
        </div>

      



      </div>

      {/* Dashboard summery start Here  */}

      
<div className="card-body">
<div className='row'>
<div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Total Orders</div>
              </div>
              </div>
              <div className="col-12">
              {/* <div className="item-icon bg-light-yellow"> */}
              {/* {allDetailResult.object ? (<>
              <Doughnut data={datass}/>
             </> ) : (<div className="item-number">0</div>)} */}


              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}
series = {[
  orders.total  ? parseInt(orders.total_orders) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.total ? parseInt(month.total_orders) : 0 
  : allDetailResult.object.total ? parseInt(allDetailResult.object.total_orders) : 0, 
  today.total  ? parseInt(today.total_orders) : 0,
  ]}

// series = {[
//   parseInt(orders.total_orders),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseInt(month.total_orders)
//   : parseInt(allDetailResult.object.total_orders), 
// ]}
// series={[parseInt(orders.total_orders), parseInt(allDetailResult.object.total_orders)]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>


        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Total Payment</div>
              </div>
              </div>
              <div className="col-12">
             

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}
series = {[
  orders.total  ? parseFloat(orders.total.replace(/,/g, "").replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.total ? parseFloat(month.total.replace(/,/g, "").replace("$", "")) : 0 
  : allDetailResult.object.total ? parseFloat(allDetailResult.object.total.replace(/,/g, "").replace("$", "")) : 0,
  today.total  ? parseFloat(today.total.replace(/,/g, "").replace("$", "")) : 0, 
  ]}
// series = {[
//   parseFloat(orders.total.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.total.replace("$", ""))
//   :  parseFloat(allDetailResult.object.total.replace("$", ""))  
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>


        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Tax</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}
series = {[
  orders.tax  ? parseFloat(orders.tax.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.tax ? parseFloat(month.tax.replace("$", "")) : 0 
  : allDetailResult.object.tax ? parseFloat(allDetailResult.object.tax.replace("$", "")) : 0,
  today.tax  ? parseFloat(today.tax.replace("$", "")) : 0, 
  ]}

// series = {[
//   parseFloat(orders.tax.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.tax.replace("$", ""))
//   :  parseFloat(allDetailResult.object.tax.replace("$", ""))  
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Tip</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}
series = {[
  orders.tip  ? parseFloat(orders.tip.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.tip ? parseFloat(month.tip.replace("$", "")) : 0 
  : allDetailResult.object.tip ? parseFloat(allDetailResult.object.tip.replace("$", "")) : 0,
  today.tip  ? parseFloat(today.tip.replace("$", "")) : 0, 
  ]}
// series = {[
//   parseFloat(orders.tip.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.tip.replace("$", ""))
//   :  parseFloat(allDetailResult.object.tip.replace("$", ""))  
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">PG Fees</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}
series = {[
  orders.pg_fees  ? parseFloat(orders.pg_fees.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.pg_fees ? parseFloat(month.pg_fees.replace("$", "")) : 0 
  : allDetailResult.object.pg_fees ? parseFloat(allDetailResult.object.pg_fees.replace("$", "")) : 0,
  today.pg_fees  ? parseFloat(today.pg_fees.replace("$", "")) : 0, 
  ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">System Fees</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}

series = {[
  orders.system_fees  ? parseFloat(orders.system_fees.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.system_fees ? parseFloat(month.system_fees.replace("$", "")) : 0 
  : allDetailResult.object.system_fees ? parseFloat(allDetailResult.object.system_fees.replace("$", "")) : 0,
  today.system_fees  ? parseFloat(today.system_fees.replace("$", "")) : 0, 
  ]}
// series = {[
//   parseFloat(orders.system_fees.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.system_fees.replace("$", ""))
//   : parseFloat(allDetailResult.object.system_fees.replace("$", "")) 
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>


        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Discounts</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}

series = {[
  orders.discounts  ? parseFloat(orders.discounts.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.discounts ? parseFloat(month.discounts.replace("$", "")) : 0 
  : allDetailResult.object.discounts ? parseFloat(allDetailResult.object.discounts.replace("$", "")) : 0,
  today.discounts  ? parseFloat(today.discounts.replace("$", "")) : 0, 
  ]}


// series = {[
//   parseFloat(orders.discounts.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.discounts.replace("$", ""))
//   : parseFloat(allDetailResult.object.discounts.replace("$", "")) 
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Delivery Fees</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}
series = {[
  orders.delivery_fees  ? parseFloat(orders.delivery_fees.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.delivery_fees ? parseFloat(month.delivery_fees.replace("$", "")) : 0 
  : allDetailResult.object.delivery_fees ? parseFloat(allDetailResult.object.delivery_fees.replace("$", "")) : 0,
  today.delivery_fees  ? parseFloat(today.delivery_fees.replace("$", "")) : 0, 
  ]}

// series = {[
//   parseFloat(orders.delivery_fees.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.delivery_fees.replace("$", ""))
//   : parseFloat(allDetailResult.object.delivery_fees.replace("$", "")) 
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>
  
         

        <div className="col-md-4 col-sm-6">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
            <div className="col-12">
            <div className="item-content" style={{textAlign:"left"}}>
                <div className="item-number">Gift Payments</div>
              </div>
              </div>
              <div className="col-12">
           

              

             

{orders && month && today && allDetailResult.object ? (
                    <>
                    <div>
                
<ReactApexCharts
options={options}

series = {[
  orders.gift_payments  ? parseFloat(orders.gift_payments.replace("$", "")) : 0,
  isNaN(allDetailResult.object.total_orders)
  ? month.gift_payments ? parseFloat(month.gift_payments.replace("$", "")) : 0 
  : allDetailResult.object.gift_payments ? parseFloat(allDetailResult.object.gift_payments.replace("$", "")) : 0,
  today.gift_payments  ? parseFloat(today.gift_payments.replace("$", "")) : 0, 
  ]}
// series = {[
//   parseFloat(orders.gift_payments.replace("$", "")),
//   isNaN(allDetailResult.object.total_orders)
//   ? parseFloat(month.gift_payments.replace("$", ""))
//   : parseFloat(allDetailResult.object.gift_payments.replace("$", "")) 
// ]}
type="donut"
width={300}
/>
</div></>):(<div className="item-number">0</div>)}


            
                
            
              </div>
             
            </div>
          </div>
        </div>

      

     

       

     

       
    
</div>
</div>

<br/>
    

{/* <div class="svg-item">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
    <circle class="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" stroke-dasharray="69 31" stroke-dashoffset="25"></circle>
    <g class="donut-text donut-text-1">

      <text y="50%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-percent">69%</tspan>   
      </text>
      <text y="60%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-data">3450 widgets</tspan>   
      </text>
    </g>
  </svg>
</div>
<div class="svg-item">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
    <circle class="donut-segment donut-segment-3" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" stroke-dasharray="30 70" stroke-dashoffset="25"></circle>
    <g class="donut-text donut-text-2">

      <text y="50%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-percent">30%</tspan>   
      </text>
      <text y="60%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-data">1500 widgets</tspan>   
      </text>
    </g>
  </svg>
</div>

<div class="svg-item">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
    <circle class="donut-segment donut-segment-4" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" stroke-dasharray="1 99" stroke-dashoffset="25"></circle>
    <g class="donut-text donut-text-3">

      <text y="50%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-percent">1%</tspan>   
      </text>
      <text y="60%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-data">50 widgets</tspan>   
      </text>
    </g>
  </svg>
</div> */}




        {/* Dashboard Content Start Here */}
   
      {/* Dashboard Content End Here */}
      <Socialmedia />
      </>

        );
}


export default ViewSalesAnalytics;
