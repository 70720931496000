import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector, useDispatch } from 'react-redux'
import { fetchAllAddons, fetchAllAddonsInOne } from '../Redux/AllBook/AllBookActions';
import { updateProductAddons, fetchsingleproduct, deleteProductAddons } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation } from "react-router-dom";

function EditAddonGroup() {
  // store data access start
  const productGroupData = useSelector(state => state.AllCategories)
  const addonGroupData = useSelector(state => state.AllBook);
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  const { state } = useLocation()
  const history = useHistory();
  // component all states define start
  const [checkedItems, setCheckedItems] = useState({});
  const [productAddonsResult, setProductAddonsResult] = useState([]);
  const [productAddonsloaded, setProductAddonsloaded] = useState('')
  const [saveState, setSaveState] = useState('')
  const [indexState, setIndexState] = useState(0)
  const [allGroupResult, setGroupResult] = useState([])
  const [selectedGroups, setSelectedGroups] = useState({});
  const [editProductResp, setEditProductResp] = useState({
    type: '',
    message: ''
  })

  const [productValues, setProductValues] = useState({
    productId: state.productId,
    name: state.productName
  })

  const goToBack = () => {
    history.goBack();
  }


  useEffect(() => {
    dispatch(fetchAllAddonsInOne());
    dispatch(fetchsingleproduct(state.productId));
    if (!productGroupData.single_product.data) {
      setIndexState(0);
      dispatch(fetchsingleproduct(state.productId));
    }
    {console.log("indexState==>"+indexState)}
  }, [dispatch]);

  const saveAddonClick = (item) => {
   
    checkedItems && checkedItems.length > 0 && checkedItems.map((item2, index) => {
      dispatch(updateProductAddons(item.productId, index));
    })
  };

  const showAddons = (index) =>{
    setIndexState(index)
  }

  const hideAddons = () =>{
    setIndexState(-1)
  }

  useMemo(() => {
    const groups = [];
    if (addonGroupData && addonGroupData.all_book && addonGroupData.all_book.data && addonGroupData.all_book.data.length > 0) {
      setGroupResult(addonGroupData.all_book.data)
      addonGroupData.all_book.data.map((item, index) => {
        groups.push({ value: item.addOnGroupId, label: item.name })
      })
      //setIndexState(0);
      //dispatch(fetchsingleproduct(state.productId));
    }
    else {
      setGroupResult([])
    }
    setSelectedGroups(groups)
  }, [addonGroupData])

  useMemo(() => {
    if (productGroupData && productGroupData.single_product && productGroupData.single_product.data) {
      const selectedAddons = {};
      setProductAddonsloaded(false)
      let addonGroups = productGroupData.single_product.data.reverse();
      setProductAddonsResult(addonGroups);
      if (productGroupData.single_product_loaded) {
        setProductAddonsloaded(true);
      }
      
      addonGroups.map((item, index) => {
        selectedAddons[item.addOnGroupId] = true;
      })
      setIndexState(0);
      setCheckedItems(selectedAddons);
     
    }
  }, [productGroupData]);

  useMemo(() => {
    if (productGroupData && productGroupData.add_product_group && productGroupData.add_product_group_loaded && saveState == 'add') {
      setSaveState('');
      dispatch(fetchsingleproduct(state.productId));
    }
  }, [productGroupData])

  useMemo(() => {
    if (productGroupData && productGroupData.delete_product_group && productGroupData.delete_product_group_loaded && saveState == 'delete') {
      setSaveState('');
      dispatch(fetchsingleproduct(state.productId));
    }
  }, [productGroupData])

  const handleChange2 = event => {
    console.log("===>"+event.target.checked)
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
    if (event.target.checked === true) {
      setSaveState('add');
      dispatch(updateProductAddons(state.productId, event.target.name))
    }
    else {
      setSaveState('delete');
      dispatch(deleteProductAddons(state.productId, event.target.name))
    }

  };

  const Checkbox = ({ type = "checkbox", id, name, checked = false, onChange }) => {

    return (
      <input type={type} id={id} name={name} checked={checked} onChange={onChange} className="stv-radio-button" />
    );
  };

  return (
    <>

      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) => goToBack()}>Back</button>
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li><Link to="/product">All Products</Link></li>
          {/* <li><Link to="/edit_category">{state.catName}</Link></li> */}
          <li onClick={(e) => goToBack()}>{productValues.name}</li>
          <li>AddonGroup</li>
        </ul>
      </div>


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
            {/* <div className="card-body"> */}
            <div className="heading-layout1">
              <div className="item-title">
                <h3>Manage Addon Group</h3>

              </div>

            </div>
            {editProductResp && editProductResp.type && editProductResp.type == 'success' ? <div style={{ textAlign: 'center', color: 'green' }}>{editProductResp.message}</div>
              : <div style={{ textAlign: 'center', color: 'red' }}>{editProductResp.message}</div>
            }
            <div className="card-body">

            <div className="row">

              <div className="col-lg-6 col-12 form-group">
                <span className="">


                  {allGroupResult && allGroupResult.length > 0 && allGroupResult.map((addonGroup) => {

                    return (
                      <label htmlFor={addonGroup.addOnGroupId} key={addonGroup.addOnGroupId} className="lableforaddon">

                        <Checkbox
                          id={addonGroup.addOnGroupId}
                          name={addonGroup.addOnGroupId}
                          checked={checkedItems[addonGroup.addOnGroupId]}
                          onChange={handleChange2}
                        />
                        <span className="btn-fill-1md radius-30 text-dark bg-dodger-blue unselect">{addonGroup.name} <span className="delete-addon"><i className="fa fa-times" aria-hidden="true"></i></span></span>

                      </label>
                    );
                  })
                  }
                </span>
              </div>

              <div className="col-lg-6 col-12 form-group">
                <div className='pad-10'>
                  {
                    !productAddonsloaded ? (<div style={{textAlign: 'center'}}>
                        <Loader
                           className="student-detail-loader"
                           type="ThreeDots"
                           color="#fea801"
                           height={150}
                           width={150}
                        />
                    </div>) :
                    productAddonsloaded && productAddonsResult && productAddonsResult.length > 0 && productAddonsResult.map((addonGroup, index) => {
                      return (<><div className="card-body2 mg-b-10"><div className='mg-b-10'>{index === indexState ? <span onClick={(e)=>hideAddons()}><i className='fas fa-caret-down'></i> {addonGroup.name}</span> : <span onClick={(e)=>showAddons(index)}><i className='fas fa-caret-right'></i> {addonGroup.name}</span>} </div>          
                        <table className={index === indexState ? 'table data-table text-nowrap' : 'd-none table data-table text-nowrap'}>
                          <tbody><thead></thead>
                            {addonGroup.addons && addonGroup.addons.length > 0 &&
                              addonGroup.addons.map((addon) => {
                                  return (<tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                    <td className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                    <td className='no-border'>{addon.name}</td>
                                    <td className='no-border'>$ {addon.unitPrice}</td>
                                    <td className='no-border'><i className="fas fa-ellipsis-v"></i></td>
                                  </tr>)
                              })
                            }
                          </tbody>
                        </table></div>
                      </>)
                    })
                  }
                </div>
              </div>
            </div>



            </div>



          </div>
        </div>
      </div>


    </>

  );
}

export default EditAddonGroup;
