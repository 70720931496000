import React, {useState,useEffect,useMemo} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { fetchAllDriver } from '../Redux/AllDriver/AllDriverActions';
import { deleteHoliday } from '../Redux/AddTiming/AddTimingActions'
import { Link } from 'react-router-dom';
import { fetchAllNotices, punchTicket } from '../Redux/AllNotices/AllNoticeActions';
import { Card, Col, Form, Pagination } from 'react-bootstrap';

function Events() {

  const dispatch = useDispatch()  // for accessing the redux function

  const [allEvents,setAllEvents] = useState([])
  const allEventsData = useSelector(state =>state.AllNotice);
  console.log(allEventsData);
  
  // fetch allBook  api hook start
  useEffect(() =>{
    dispatch(fetchAllNotices(totalPages,currentPage))
  },[dispatch])
  // fetch allBook api hook End

  useMemo(() =>{
    if(allEventsData && allEventsData.all_notice && allEventsData.all_notice.data && allEventsData.all_notice.data.length >= 0){
        setAllEvents(allEventsData.all_notice.data)
    }
  },[allEventsData])

  const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);

const [data1, setData1] = useState();
const [pageSize, setPageSize] = useState(10);
const [productData,setProductData] = useState('');

useEffect(() => {
  if (data1 && data1.length > 0) {
  setTotalPages(Math.ceil(allEventsData.all_notice.total / pageSize));
  }
  }, [data1, pageSize]);

  useMemo(() =>{
    if(allEventsData && allEventsData.all_notice && allEventsData.all_notice.data && allEventsData.all_notice.data.length >= 0){
      setProductData(allEventsData.all_notice.data)
      setData1(allEventsData.all_notice.data)   
    } else {
      setProductData('')
    }
   
  },[allEventsData])
  const onPageChange = (currentPage) => {
    setCurrentPage(currentPage);
  // dispatch(fetchAllProducts(totalPages,currentPage))
  dispatch(fetchAllNotices(totalPages,currentPage));
  };
 
 
const renderPagination = () => {
  let items = [];
  for (let i = 0; i < totalPages; i++) {
  items.push(
  <Pagination.Item
  key={i}
  active={i === currentPage}
  onClick={() => onPageChange(i)}
  >
  {i+1}
  </Pagination.Item>
  );
  }
  return (
  <Pagination style={{ overflowX: "auto" }}>
  <Pagination.Prev
  disabled={currentPage === 1}
  onClick={() => onPageChange(currentPage - 1)}
  />
  {items}
  <Pagination.Next
  disabled={currentPage === totalPages}
  onClick={() => onPageChange(currentPage + 1)}
  />
  </Pagination>
  );
  };

  // ticket punch
  const [ticket_number_error, setTicketNumber_error] = useState(false);
  const [pin_error, setPin_error] = useState(false);
  const [inputValues,setInputValues] = useState({
    card_number:"",
    pin:""
})
const [show,setShow] = useState(false);
  const ticketHandler = (event,item) =>{
    event.preventDefault()
    const exam_info = inputValues;
    dispatch(punchTicket(exam_info));
    setShow(true)
    localStorage.getItem("event_id")
    console.log(localStorage.getItem("event_id"));
  }

  const handleTicketChange = event => {
    const ticket = event.target.value;
  if (ticket.length === 16) {
      setTicketNumber_error(false);
    } else {
      setTicketNumber_error(true);
    }
  };

  const handlePinChange = event => {
    const pin = event.target.value;
  if (pin.length === 4) {
      setPin_error(false);
    } else {
      setPin_error(true);
    }
  };
  function formatDate(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const date = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
    // Format the time with minutes but without seconds
    const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
  
    return `${month} ${date}, ${year} ${formattedTime}`;
  }

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleModalOpen = (item) => {
    setSelectedEvent(item);
    console.log(item.event_id);
    localStorage.setItem("event_id",item.event_id)
  };


    return (
      <div className="col-8-xxxl col-12" style={{marginLeft:"-1%"}}>
   
        <div className="card-body">
        <div className="heading-layout1 mg-b-17">
            <div className="item-title">
              <h3>Specials</h3>
            </div>

          </div>
         
          <div className="container"> 
<div className="row"> 
{allEvents.map((item,index) => {
return (  
<div className="col-md-4 col-sm-6" key={item.id}> 
<div className="card"> 
{/* <div className="card-header btn-fill-lg bg-blue-dark">{item.start_time}</div>  */}
<div className="card-body"> 
<img class="card-img-top" src={item.image} alt="Card image cap" style={{borderRadius:"10px"}}/>
<br/><br/>
<h3 className="card-title text-center"><Link to="#" style={{color:"#042954"}}>{item.name}</Link></h3> 
<div className="card-text text-center" style={{maxHeight: "100px", /* adjust this value as needed */
overflowY: "auto"}}>{item.description}</div>
<div className="card-text text-center">Start Time: {formatDate(item.start_time)}</div>
<div className="card-text text-center">End Time: {formatDate(item.end_time)}</div> 
<div className="card-text text-center">
{item.event_type && item.event_type == 'SPECIAL_DEAL' ? <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" data-toggle="modal" data-target="#exampleModal" onClick={() => handleModalOpen(item)}>Punch Ticket</button> : <></>}
</div>
</div>
</div>
</div>
)
})}
</div>
</div>

</div>
<div className='row'>
        <div className="col-12-xxxl col-12">     
         <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
           
            </div>
       
          </div>   



          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" style={{border:"1px solid black"}}>
    <div className="modal-content">
      <div className="modal-header" style={{marginLeft:"20px", marginRight:"20px"}}>
      {/* <div className="heading-layout1 bg-darkblue"> */}
                      <div className="item-title">
                        <h3>Redeem ETicket</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ position: 'absolute', top: '15px', right: '15px', background: 'red', border: 'none', outline: 'none', cursor: 'pointer' }}>
            <span aria-hidden="true" style={{ color: 'white' }}>&times;</span>
          </button>
                      {/* </div> */}
                    
                    </div>
     
      </div>
      <div className="modal-body" style={{marginLeft:"20px", marginRight:"20px"}}>
      <form className="new-added-form" onSubmit={(e) =>ticketHandler(e)}>
                      <div className="row">
                        <div className="col-12-xxxl col-12 form-group">
                        <Form.Group
            as={Col}
            controlId="formBasicTelephone"
          >
            <Form.Label>Ticket Number*</Form.Label>
            <Form.Control
              type="number"
              pattern="[0-9]*"
              maxlength="16"
              placeholder="Enter Ticket Number"
              value={inputValues.card_number}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  card_number: e.target.value
                })
              }
              onBlur={e => handleTicketChange(e)}
              required
            />
              {ticket_number_error && ticket_number_error === true ? (
              <span className="phone-error">
                Ticket Number must be 16 digits
              </span>
            ) : null}
             <br/>
          </Form.Group>
                       
                         </div>
                        
                        <div className="col-12-xxxl col-12 form-group">
                        <Form.Group
            as={Col}
            controlId="formBasicTelephone"
          >
            <Form.Label>Pin*</Form.Label>
            <Form.Control
              type="number"
              pattern="[0-9]*"
              maxlength="4"
              placeholder="Enter Ticket Number"
              value={inputValues.pin}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  pin: e.target.value
                })
              }
              onBlur={e => handlePinChange(e)}
              required
            />
              {pin_error && pin_error === true ? (
              <span className="phone-error">
                Pin must be 4 digits
              </span>
            ) : null}
          </Form.Group>
          </div>
                       
                        <div className="col-12 form-group mg-t-20">
                          <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float mg-l-10">Punch Ticket</button>
                        </div>
                      </div>
                    </form>
      </div>
     
    </div>
  </div>
</div>


     
    </div>

      


    );
}

export default Events;
