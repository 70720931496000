import {
  BANNER_UPLOAD_REQUEST,
  BANNER_UPLOAD_SUCCESS,
  BANNER_UPLOAD_FALIURE,
  LOGO_UPLOAD_REQUEST,
  LOGO_UPLOAD_SUCCESS,
  LOGO_UPLOAD_FALIURE,
  GET_UPLOAD_REQUEST,
  GET_UPLOAD_SUCCESS,
  GET_UPLOAD_FALIURE
} from './BannerLogoConstants'

const intialstate = {
  upload_loading : false,
  upload_banner : null,
  upload_banner_error : '',
  get_loading : false,
  success_status : false,
  get_bannerlogo : null,
  get_error : ''
}

const BannerLogoReducers = (state = intialstate,action) =>{
  switch(action.type){
    case BANNER_UPLOAD_REQUEST : return{
      ...state,
      upload_loading: true
    }
    case BANNER_UPLOAD_SUCCESS : return{
      ...state,
      upload_loading: false,
      upload_banner: action.payload
    }
    case BANNER_UPLOAD_FALIURE : return{
      ...state,
      upload_loading: false,
      upload_banner_error:action.payload
    }
    case LOGO_UPLOAD_REQUEST : return{
      ...state,
      upload_loading: true
    }
    case LOGO_UPLOAD_SUCCESS : return{
      ...state,
      upload_loading: false,
      upload_banner: action.payload
    }
    case LOGO_UPLOAD_FALIURE : return{
      ...state,
      upload_loading: false,
      upload_banner_error:action.payload
    }
    case GET_UPLOAD_REQUEST : return{
      ...state,
      get_loading: true,
      success_status: false,
      get_bannerlogo: ''
    }
    case GET_UPLOAD_SUCCESS : return{
      ...state,
      get_loading: false,
      success_status: true,
      get_bannerlogo: action.payload
    }
    case GET_UPLOAD_FALIURE : return{
      ...state,
      get_loading: false,
      success_status: false,
      get_error:action.payload
    }
    default: return state
  }

}

export default BannerLogoReducers
