import {
  PUBLISH_DATA_REQUEST,
  PUBLISH_DATA_SUCCESS,
  PUBLISH_DATA_FALIURE
} from './PublishDataConstants'

const intialstate = {
  publish_data_loading : false,
  publish_data : [],
  publish_data_error : '',
  publish_data_success : false
}

const PublishDataReducers = (state = intialstate,action) =>{
  switch(action.type){
    case PUBLISH_DATA_REQUEST : return{
      ...state,
      publish_data_loading:true
    }
    case PUBLISH_DATA_SUCCESS : return{
      ...state,
      publish_data_loading:false,
      publish_data_success:true,
      publish_data:action.payload
    }
    case PUBLISH_DATA_FALIURE : return{
      ...state,
      publish_data_error:action.payload
    }
    default: return state
  }

}

export default PublishDataReducers
