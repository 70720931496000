import {
  ON_DEMAND_DELIVERY_REQUEST,
  ON_DEMAND_DELIVERY_SUCCESS,
  ON_DEMAND_DELIVERY_FALIURE,
  DOORDASH_DELIVERY_REQUEST,
  DOORDASH_DELIVERY_SUCCESS,
  DOORDASH_DELIVERY_FALIURE,
  AUTO_ACCEPT_DELIVERY_REQUEST,
  AUTO_ACCEPT_DELIVERY_SUCCESS,
  AUTO_ACCEPT_DELIVERY_FALIURE,
  ON_DEMAND_DELIVERY_UPDATE_SUCCESS,
  DOORDASH_DELIVERY_UPDATE_SUCCESS,
  AUTO_ACCEPT_DELIVERY_UPDATE_SUCCESS,
  ON_DEMAND_DELIVERY_UPDATE_FALIURE,
  DOORDASH_DELIVERY_UPDATE_FALIURE,
  AUTO_ACCEPT_DELIVERY_UPDATE_FALIURE,
  ON_DEMAND_DELIVERY_UPDATE_REQUEST,
  DOORDASH_DELIVERY_UPDATE_REQUEST,
  AUTO_ACCEPT_DELIVERY_UPDATE_REQUEST
} from './DeliverySettingsConstants'
import {config} from '../config'

export const onDemandDeliveryRequest = (on_demand_delivery) =>{
  return{
    type : ON_DEMAND_DELIVERY_REQUEST,
    payload : on_demand_delivery
  }
}

export const onDemandDeliverySuccess = (on_demand_delivery_success) =>{
  return{
    type : ON_DEMAND_DELIVERY_SUCCESS,
    payload : on_demand_delivery_success
  }
}

export const onDemandDeliveryFaliure = (error) =>{
  return{
    type : ON_DEMAND_DELIVERY_FALIURE,
    payload : error
  }
}

export const doordashDeliveryRequest = (doordash_request) =>{
  return{
    type : DOORDASH_DELIVERY_REQUEST,
    payload : doordash_request
  }
}

export const doordashDeliverySuccess = (doordash_success) =>{
  return{
    type : DOORDASH_DELIVERY_SUCCESS,
    payload : doordash_success
  }
}

export const doordashDeliveryFaliure = (error) =>{
  return{
    type : DOORDASH_DELIVERY_FALIURE,
    payload : error
  }
}

export const autoAcceptDeliveryRequest = (auto_accept_delivery) =>{
  return{
    type : AUTO_ACCEPT_DELIVERY_REQUEST,
    payload : auto_accept_delivery
  }
}

export const autoAcceptDeliverySuccess = (auto_accept_delivery_success) =>{
  return{
    type : AUTO_ACCEPT_DELIVERY_SUCCESS,
    payload : auto_accept_delivery_success
  }
}

export const autoAcceptDeliveryFaliure = (error) =>{
  return{
    type : AUTO_ACCEPT_DELIVERY_FALIURE,
    payload : error
  }
}

export const onDemandDeliveryUpdateRequest = (on_demand_delivery_update_req) =>{
  return{
    type : ON_DEMAND_DELIVERY_UPDATE_REQUEST,
    payload : on_demand_delivery_update_req
  }
}

export const doordashDeliveryUpdateRequest = (doordash_delivery_update_req) =>{
  return{
    type : DOORDASH_DELIVERY_UPDATE_REQUEST,
    payload : doordash_delivery_update_req
  }
}

export const autoAcceptDeliveryUpdateRequest = (auto_accept_delivery_update_req) =>{
  return{
    type : AUTO_ACCEPT_DELIVERY_UPDATE_REQUEST,
    payload : auto_accept_delivery_update_req
  }
}

export const onDemandDeliveryUpdateSuccess = (on_demand_delivery_update) =>{
  return{
    type : ON_DEMAND_DELIVERY_UPDATE_SUCCESS,
    payload : on_demand_delivery_update
  }
}

export const doordashDeliveryUpdateSuccess = (doordash_delivery_update) =>{
  return{
    type : DOORDASH_DELIVERY_UPDATE_SUCCESS,
    payload : doordash_delivery_update
  }
}

export const autoAcceptDeliveryUpdateSuccess = (auto_accept_delivery_update) =>{
  return{
    type : AUTO_ACCEPT_DELIVERY_UPDATE_SUCCESS,
    payload : auto_accept_delivery_update
  }
}

export const onDemandDeliveryUpdateFaliure = (on_demand_delivery_faliure) =>{
  return{
    type : ON_DEMAND_DELIVERY_UPDATE_FALIURE,
    payload : on_demand_delivery_faliure
  }
}

export const doordashDeliveryUpdateFaliure = (doordash_delivery_update) =>{
  return{
    type : DOORDASH_DELIVERY_UPDATE_FALIURE,
    payload : doordash_delivery_update
  }
}

export const autoAcceptDeliveryUpdateFaliure = (auto_accept_delivery_update) =>{
  return{
    type : AUTO_ACCEPT_DELIVERY_UPDATE_FALIURE,
    payload : auto_accept_delivery_update
  }
}

export const onDemandDelivery = () =>{
    return(dispatch) => {
      dispatch(onDemandDeliveryRequest())
      const url =`${config.api_root}/api/v1/users/on_demand_delivery?user_id=${config.user_id}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(on_demand_delivery_res =>{
      const on_demand_delivery_success = on_demand_delivery_res
      // console.log('Result On Demand_success==>'+on_demand_delivery_success.status);
      dispatch(onDemandDeliverySuccess(on_demand_delivery_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(onDemandDeliveryFaliure(errorMsg))
    })
    }
}

export const doordashDelivery = () =>{
  return(dispatch) => {
    dispatch(doordashDeliveryRequest())
    const url =`${config.api_root}/api/v1/users/doordash_on_demand_delivery?user_id=${config.user_id}`;
    const request_option = {
    method: "GET",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(doordash_on_demand_delivery_res =>{
    const doordash_on_demand_delivery_success = doordash_on_demand_delivery_res
    dispatch(doordashDeliverySuccess(doordash_on_demand_delivery_success))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(doordashDeliveryFaliure(errorMsg))
  })
  }
}

export const autoAcceptDelivery = () =>{
  return(dispatch) => {
    dispatch(autoAcceptDeliveryRequest())
    const url =`${config.api_root}/api/v1/users/auto_acceptance_on_demand_delivery?user_id=${config.user_id}`;
    const request_option = {
    method: "GET",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(auto_acceptance_delivery_res =>{
    const auto_acceptance_delivery_success = auto_acceptance_delivery_res
    dispatch(autoAcceptDeliverySuccess(auto_acceptance_delivery_success))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(autoAcceptDeliveryFaliure(errorMsg))
  })
  }
}

export const onDemandDeliveryUpdate = (onDemandStatus) =>{
  return(dispatch) => {
    let req = {
      on_demand_success: false,
      on_demand_status: onDemandStatus
    }
    dispatch(onDemandDeliveryUpdateRequest(req))
    const url =`${config.api_root}/api/v1/users/on_demand_delivery?user_id=${config.user_id}&status=${onDemandStatus}`;
    const request_option = {
    method: "PATCH",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(on_demand_delivery_res => {
    const on_demand_delivery_success = on_demand_delivery_res
    let resp = {
        on_demand_success: on_demand_delivery_success.success,
        on_demand_status: onDemandStatus
    }
    // console.log('Result On Demand Update==>'+resp.on_demand_status);
    dispatch(onDemandDeliveryUpdateSuccess(resp))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(onDemandDeliveryUpdateFaliure(errorMsg))
  })
  }
}

export const doordashDeliveryUpdate = (doordashstatus) =>{
 return(dispatch) => {
    let req = {
       doordash_success: false,
       doordash_status: doordashstatus
    }
    dispatch(doordashDeliveryUpdateRequest(req))
    const url =`${config.api_root}/api/v1/users/doordash_on_demand_delivery?user_id=${config.user_id}&status=${doordashstatus}`;
    const request_option = {
    method: "PATCH",
    headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer '+ config.token,
          'Content-Type': 'application/json'
      }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(doordash_on_demand_delivery_res => {
    const doordash_on_demand_delivery_success = doordash_on_demand_delivery_res
    let resp = {
      doordash_success: doordash_on_demand_delivery_success.success,
      doordash_status: doordashstatus
    }
    dispatch(doordashDeliveryUpdateSuccess(resp))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(doordashDeliveryUpdateFaliure(errorMsg))
  })
 }
}

export const autoAcceptDeliveryUpdate = (autoacceptstatus) =>{
return(dispatch) => {
  let req = {
    auto_success: false,
    auto_status: autoacceptstatus
  }
  dispatch(autoAcceptDeliveryUpdateRequest(req))
  const url =`${config.api_root}/api/v1/users/auto_acceptance_on_demand_delivery?user_id=${config.user_id}&status=${autoacceptstatus}`;
  const request_option = {
  method: "PATCH",
  headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer '+ config.token,
          'Content-Type': 'application/json'
      }
}
fetch(url, request_option)
.then(response => response.json())
.then(auto_acceptance_delivery_res => {
  const auto_acceptance_delivery_success = auto_acceptance_delivery_res
  let resp = {
    auto_success: auto_acceptance_delivery_success.success,
    auto_status: autoacceptstatus
  }
  dispatch(autoAcceptDeliveryUpdateSuccess(resp))
})
.catch(error => {
  const errorMsg = error
  dispatch(autoAcceptDeliveryUpdateFaliure(errorMsg))
})
}
}
