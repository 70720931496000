import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGalleries } from '../Redux/Gallery/GalleryActions'

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from 'react-router-dom';

function Galleries() {

  const galleryData = useSelector(state => state.Gallery)
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  const [isLoaded, setLoaded] = useState(null)
  const [galleries, setGalleries] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  // add data of allFees api into constant,hook start
  useEffect(() => {
    dispatch(fetchGalleries());
  },[])

  

  useMemo(() => {
    if (galleryData && !galleryData.is_loading) {
      setLoaded(true)
      if (galleryData.get_galleries && galleryData.get_galleries.request_status) {
         setGalleries(galleryData.get_galleries.data)
      } else if (galleryData.error) {
         setErrorMessage(galleryData.error)
      }
    } else {
      setLoaded(false)
    }
  }, [galleryData])


  return (
    <>
      <div className="breadcrumbs-area">
        <h3>Galleries</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Galleries</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}


      <div className="card-body" style={{background:'#dfe1e2'}}>

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>


        {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }


        <div className="pad-5 bg-darkblue height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">Galleries</a>
            </li>
          </ul>
        </div>
        <div className="tab-content single-info-details justify-content-center">
          <div role="tabpanel" className='tab-pane active show' id="billing">
          {isLoaded == false ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> : <span></span> }

           <div className="row">
              
              {
                galleries && galleries.map((gallery)=> (
                  <div className="col-4-xxxl col-lg-6 col-12 form-group mg-20 grid">
                    <div className='divcorners'>
                    <div className="imgcorners" id="#banner_img" style={{background: `url(${gallery.data}) no-repeat center`}} type="image">
                       
                    </div><span style={{color:'#000000'}}>{gallery.imageText}</span>
                    </div>
                   </div>
                  )
                )
              }
              
            
          </div> 

            <br/>
          </div>       


        </div>



      </div>

    </>
  );
};

export default Galleries;
