import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector,useDispatch } from 'react-redux'

import moment from 'moment';

import { downloadRport, downloadTodayRport } from '../Redux/DownloadReport/DownloadReportActions';


function DownloadReport(){
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)
const download_report = useSelector(state =>state.DownloadReport)

const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [allDownloadReports,setDownloadReports] = useState([])

  const [inputValues,setInputValues] = useState({
                                          from_date:"",
                                          to_date :"",

  })
 
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)


const format = 'DD-MM-YYYY';

const now = moment().hour(0).minute(0);

  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())


},[])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
   useMemo(()=>{
     if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.error){
       setError(addExam_data.add_timing.error)
     }
     else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.success){
        setSuccessStatus(addExam_data.add_timing.success)
     }
   },[addExam_data])
// add data of addExam api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(download_report && download_report.download_report){
       setDownloadReports(download_report.download_report)
     }
   },[download_report])
// add data of allsubject api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
  
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End

// examHandler function start
   const examHandler = (event) =>{
   event.preventDefault()
   const report_info = inputValues;
     dispatch(downloadRport(report_info))
    //  dispatch(fetchAllNotices())
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


// resetHandler function start
   const handleDateChange = (value) =>{
     const selectedData = moment(value).format('DD-MM-YYYY');

    setInputValues({...inputValues,from_date:selectedData})
    setStartDate(value)
   }

   const downloadToday = (event) =>{
     event.preventDefault()
     const todayDate = new Date();
     const currentDate =  moment(todayDate).format('DD-MM-YYYY');
    dispatch(downloadTodayRport(currentDate))
   }


   const handleClosingTimeChange = (value) =>{

     const selectedData = moment(value).format('DD-MM-YYYY');

    setInputValues({...inputValues,to_date:selectedData})
    setEndDate(value)
  
   }
// resetHandler function start

// component function end


        return (
          <div className="row equal">
            <div className="col-12">
                <div className="card height-auto">
                {addExam_data && addExam_data.add_timing_loading === false ? successStatus === false || successStatus === null  ? (
                  <div className="card-body">

                    <form className="new-added-form" onSubmit={(e) =>examHandler(e)}>
                      <div className="row">

                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Start Date</label>
                        <DatePicker selected={startDate} className="form-control" onChange={(date) => handleDateChange(date)} />
                          {error != null && error.from_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.from_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>End Date</label>
                        <DatePicker selected={endDate} className="form-control" onChange={(date) => handleClosingTimeChange(date)} />
                          {error != null && error.to_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.to_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>


                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>

                          <button type="submit" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Generate Report</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={(e) =>resetHandler(e)}>Reset</button>
                          <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float" onClick={(e) =>downloadToday(e)}>Download Today's Report</button>
                        </div>
                      </div>
                    </form>
                    {download_report && download_report.download_report_loading === false && allDownloadReports && allDownloadReports.object && allDownloadReports.object.error ? (<div className="reporterror">{allDownloadReports.object.error}</div>):null}
                    {download_report && download_report.download_report_loading === false && allDownloadReports && allDownloadReports.object && allDownloadReports.object.report_link ? (<div className="reportsuccess">Report Generated Successfully. <a href={allDownloadReports.object.report_link}>Download Report</a></div>):null}

                                      </div>
                ) : (<div className="card-body">
                      <div className="success-greeting">
                      <h2>Thank You!</h2>
                      <h2>Report Generated Successfully!</h2>
                      </div>
                  </div>):(<div className="card-body">
                      <div className="Student-Loader">
                      <Loader
                      className = "student-detail-loader"
                    type="MutatingDots"
                    color="#fea801"
                    height={150}
                    width={150}

       />
                      </div>
                    </div>)}
                </div>
              </div>

              </div>

        );
}

export default DownloadReport;
