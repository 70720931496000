import {
  ADD_CONNECTION_SUCCESS,
  ADD_CONNECTION_FALIURE
} from './AddConnectionConstants'

export const addConnectionSuccess = (connection) =>{
  return{
    type : ADD_CONNECTION_SUCCESS,
    payload : connection
  }
}

export const addConnectionFaliure = (error) =>{
  return{
    type : ADD_CONNECTION_FALIURE,
    payload : error
  }
}

export const addConnection = (connection) =>{
    return(dispatch) => {
      dispatch(addConnectionSuccess(connection))
    }
}

