import {
  ADD_TIMING_REQUEST,
  ADD_TIMING_SUCCESS,
  ADD_TIMING_FALIURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FALIURE
} from './AddTimingConstants'

const intialstate = {
  add_timing_loading : false,
  add_timing : [],
  add_timing_error : '',
  add_timing_success : false,
  add_addon_loading : false,
  add_addon : [],
  add_addon_error : '',
  add_addon_success : false
}

const AddTimingReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ADD_TIMING_REQUEST : return{
      ...state,
      add_timing_loading:true
    }
    case ADD_TIMING_SUCCESS : return{
      ...state,
      add_timing_loading:false,
      add_timing_success:true,
      add_timing:action.payload
    }
    case ADD_TIMING_FALIURE : return{
      ...state,
      add_timing_error:action.payload
    }
    case ADD_GROUP_REQUEST : return{
      ...state,
      add_addon_loading:true
    }
    case ADD_GROUP_SUCCESS : return{
      ...state,
      add_addon_loading:false,
      add_addon_success:true,
      add_addon:action.payload
    }
    case ADD_GROUP_FALIURE : return{
      ...state,
      add_addon_loading:false,
      add_addon_error:action.payload
    }
    default: return state
  }

}

export default AddTimingReducers
