export const FETCH_ALLCATEGORIES_REQUEST = 'FETCH_ALLCATEGORIES_REQUEST'
export const FETCH_ALLCATEGORIES_SUCCESS = 'FETCH_ALLCATEGORIES_SUCCESS'
export const FETCH_ALLCATEGORIES_FALIURE = 'FETCH_ALLCATEGORIES_FALIURE'
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FALIURE = 'FETCH_PRODUCTS_FALIURE'
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FALIURE = 'UPDATE_CATEGORY_FALIURE'
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FALIURE = 'UPDATE_PRODUCT_FALIURE'
export const UPDATE_PRODUCT_PRICE_REQUEST = 'UPDATE_PRODUCT_PRICE_REQUEST'
export const UPDATE_PRODUCT_PRICE_SUCCESS = 'UPDATE_PRODUCT_PRICE_SUCCESS'
export const UPDATE_PRODUCT_PRICE_FALIURE = 'UPDATE_PRODUCT_PRICE_FALIURE'
export const FETCH_SINGLEPRODUCTS_REQUEST = 'FETCH_SINGLEPRODUCTS_REQUEST'
export const FETCH_SINGLEPRODUCTS_SUCCESS = 'FETCH_SINGLEPRODUCTS_SUCCESS'
export const FETCH_SINGLEPRODUCTS_FALIURE = 'FETCH_SINGLEPRODUCTS_FALIURE'
export const STOCK_PRODUCT_REQUEST = 'STOCK_PRODUCT_REQUEST'
export const STOCK_PRODUCT_SUCCESS = 'STOCK_PRODUCT_SUCCESS'
export const STOCK_PRODUCT_FALIURE = 'STOCK_PRODUCT_FALIURE'
export const STATUS_PRODUCT_REQUEST = 'STATUS_PRODUCT_REQUEST'
export const STATUS_PRODUCT_SUCCESS = 'STATUS_PRODUCT_SUCCESS'
export const STATUS_PRODUCT_FALIURE = 'STATUS_PRODUCT_FALIURE'
export const ADD_PRODUCT_GROUP_REQUEST = 'ADD_PRODUCT_GROUP_REQUEST'
export const ADD_PRODUCT_GROUP_SUCCESS = 'ADD_PRODUCT_GROUP_SUCCESS'
export const ADD_PRODUCT_GROUP_FALIURE = 'ADD_PRODUCT_GROUP_FALIURE'
export const DELETE_PRODUCT_GROUP_REQUEST = 'DELETE_PRODUCT_GROUP_REQUEST'
export const DELETE_PRODUCT_GROUP_SUCCESS = 'DELETE_PRODUCT_GROUP_SUCCESS'
export const DELETE_PRODUCT_GROUP_FALIURE = 'DELETE_PRODUCT_GROUP_FALIURE'
