export const ON_DEMAND_DELIVERY_REQUEST = 'ON_DEMAND_DELIVERY_REQUEST'
export const ON_DEMAND_DELIVERY_SUCCESS = 'ON_DEMAND_DELIVERY_SUCCESS'
export const ON_DEMAND_DELIVERY_FALIURE = 'ON_DEMAND_DELIVERY_FALIURE'
export const DOORDASH_DELIVERY_REQUEST = 'DOORDASH_DELIVERY_REQUEST'
export const DOORDASH_DELIVERY_SUCCESS = 'DOORDASH_DELIVERY_SUCCESS'
export const DOORDASH_DELIVERY_FALIURE = 'DOORDASH_DELIVERY_FALIURE'
export const AUTO_ACCEPT_DELIVERY_REQUEST = 'AUTO_ACCEPT_DELIVERY_REQUEST'
export const AUTO_ACCEPT_DELIVERY_SUCCESS = 'AUTO_ACCEPT_DELIVERY_SUCCESS'
export const AUTO_ACCEPT_DELIVERY_FALIURE = 'AUTO_ACCEPT_DELIVERY_FALIURE'
export const DOORDASH_DELIVERY_UPDATE_SUCCESS = 'DOORDASH_DELIVERY_UPDATE_SUCCESS'
export const ON_DEMAND_DELIVERY_UPDATE_SUCCESS = 'ON_DEMAND_DELIVERY_UPDATE_SUCCESS'
export const AUTO_ACCEPT_DELIVERY_UPDATE_SUCCESS = 'AUTO_ACCEPT_DELIVERY_UPDATE_SUCCESS'
export const ON_DEMAND_DELIVERY_UPDATE_FALIURE = 'ON_DEMAND_DELIVERY_UPDATE_FALIURE'
export const DOORDASH_DELIVERY_UPDATE_FALIURE = 'DOORDASH_DELIVERY_UPDATE_FALIURE'
export const AUTO_ACCEPT_DELIVERY_UPDATE_FALIURE = 'AUTO_ACCEPT_DELIVERY_UPDATE_FALIURE'
export const ON_DEMAND_DELIVERY_UPDATE_REQUEST = 'ON_DEMAND_DELIVERY_UPDATE_REQUEST'
export const DOORDASH_DELIVERY_UPDATE_REQUEST = 'DOORDASH_DELIVERY_UPDATE_REQUEST'
export const AUTO_ACCEPT_DELIVERY_UPDATE_REQUEST = 'AUTO_ACCEPT_DELIVERY_UPDATE_REQUEST'
