import {
  FETCH_ALLPRODUCTS_REQUEST,
  FETCH_ALLPRODUCTS_SUCCESS,
  FETCH_ALLPRODUCTS_ADD_SUCCESS,
  FETCH_ALLPRODUCTS_FALIURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FALIURE,
  STOCK_PRODUCT_REQUEST,
  STOCK_PRODUCT_SUCCESS,
  STOCK_PRODUCT_FALIURE,
 UPDATE_ALLPRODUCT_REQUEST,
 UPDATE_ALLPRODUCT_SUCCESS,
 UPDATE_ALLPRODUCT_FALIURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FALIURE,
  UPDATE_PRODUCT_PRICE_REQUEST,
  UPDATE_PRODUCT_PRICE_SUCCESS,
  UPDATE_PRODUCT_PRICE_FALIURE,
  FETCH_SINGLEPRODUCTS_REQUEST,
  FETCH_SINGLEPRODUCTS_SUCCESS,
  FETCH_SINGLEPRODUCTS_FALIURE,
  ADD_PRODUCT_GROUP_REQUEST,
  ADD_PRODUCT_GROUP_SUCCESS,
  ADD_PRODUCT_GROUP_FALIURE,
  DELETE_PRODUCT_GROUP_REQUEST,
  DELETE_PRODUCT_GROUP_SUCCESS,
  DELETE_PRODUCT_GROUP_FALIURE
} from './AllProductsConstants'

const intialstate = {
  all_products_loading : false,
  all_products : [],
  all_categories_error : '',
  all_product_loaded : true,
  stock_product : '',
  stock_product_loading : false,
  stock_product_error : '',
  all_product : [],
  all_products_error : '',
  
  edit_category_loaded : false,
  edit_category : '',
  edit_category_error : '',
  edit_product_loaded : false,
  edit_product : '',
  edit_product_error : '',
  edit_product_price_loaded : false,
  edit_product_price : '',
  edit_product_price_error : '',
  single_product_loading : false,
  single_product_loaded : false,
  single_product : [],
  single_product_error : '',
  add_product_group_loaded : false,
  add_product_group : '',
  add_product_group_error : '',
  delete_product_group_loaded : false,
  delete_product_group : '',
  delete_product_group_error : ''
}

const AllProductsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_ALLPRODUCTS_REQUEST : return{
      ...state,
      all_products_loading:true
    }
    case FETCH_ALLPRODUCTS_SUCCESS : return{
      ...state,
      all_products_loading:false,
      all_products:action.payload
    }
    case FETCH_ALLPRODUCTS_ADD_SUCCESS : return{
      ...state,
      all_products_loading:false,
      all_products:action.payload,
      is_added: true,
      all_products_error: null,
      
    }
    case FETCH_ALLPRODUCTS_FALIURE : return{
      ...state,
      all_products_loading:false,
      
      all_products_error:action.payload
    }
    case FETCH_PRODUCTS_REQUEST : return{
      ...state,
      all_products_loading:false,
      
    }
    case FETCH_PRODUCTS_SUCCESS : return{
      ...state,
      all_products_loading:false,
     
      all_products:action.payload
    }
    case FETCH_PRODUCTS_FALIURE : return{
      ...state,
    
      all_products_error:action.payload
    }
    case STOCK_PRODUCT_REQUEST : return{
      ...state,
      stock_product_loading:true
    }
    case STOCK_PRODUCT_SUCCESS : return{
      ...state,
      stock_product_loading:false,
      stock_product:action.payload
    }
    case STOCK_PRODUCT_FALIURE : return{
      ...state,
      stock_product_loading:false,
      stock_product_error:action.payload
    }
    case FETCH_SINGLEPRODUCTS_REQUEST : return{
      ...state,
      single_product_loading:true,
      single_product_loaded:false
    }
    case FETCH_SINGLEPRODUCTS_SUCCESS : return{
      ...state,
      single_product_loading:false,
      single_product_loaded:true,
      single_product:action.payload
    }
    case FETCH_SINGLEPRODUCTS_FALIURE : return{
      ...state,
      single_product_loaded:false,
      single_product_error:action.payload
    }
    case UPDATE_ALLPRODUCT_REQUEST : return {
      ...state,
      edit_category_loaded: false
    }
    case UPDATE_ALLPRODUCT_SUCCESS : return {
      ...state,
      edit_category_loaded: true,
      edit_category:action.payload
    }
    case UPDATE_ALLPRODUCT_FALIURE : return {
      ...state,
      edit_category_loaded: true,
      edit_category_error:action.payload
    }
    case UPDATE_PRODUCT_REQUEST : return {
      ...state,
      edit_product_loaded: false
    }
    case UPDATE_PRODUCT_SUCCESS : return {
      ...state,
      edit_product_loaded: true,
      edit_product: action.payload
    }
    case UPDATE_PRODUCT_FALIURE : return {
      ...state,
      edit_product_loaded: true,
      edit_product_error: action.payload
    }
    case UPDATE_PRODUCT_PRICE_REQUEST : return {
      ...state,
      edit_product_price_loaded: false
    }
    case UPDATE_PRODUCT_PRICE_SUCCESS : return {
      ...state,
      edit_product_price_loaded: true,
      edit_product_price: action.payload
    }
    case UPDATE_PRODUCT_PRICE_FALIURE : return {
      ...state,
      edit_product_price_loaded: true,
      edit_product_price_error: action.payload
    }
    case ADD_PRODUCT_GROUP_REQUEST : return {
      ...state,
      add_product_group_loaded: false
    }
    case ADD_PRODUCT_GROUP_SUCCESS : return {
      ...state,
      add_product_group_loaded: true,
      add_product_group: action.payload
    }
    case ADD_PRODUCT_GROUP_FALIURE : return {
      ...state,
      add_product_group_loaded: true,
      add_product_group_error: action.payload
    }
    case DELETE_PRODUCT_GROUP_REQUEST : return {
      ...state,
      delete_product_group_loaded: false
    }
    case DELETE_PRODUCT_GROUP_SUCCESS : return {
      ...state,
      delete_product_group_loaded: true,
      delete_product_group: action.payload
    }
    case DELETE_PRODUCT_GROUP_FALIURE : return {
      ...state,
      delete_product_group_loaded: true,
      delete_product_group_error: action.payload
    }
    default: return state
  }

}

export default AllProductsReducers
