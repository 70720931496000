import React from 'react';
import Holidays from './Holidays';
import AddHoliday from './AddHoliday';
import { Link } from 'react-router-dom';


function AllHolidays() {
        return (           
          <>
          <div className="breadcrumbs-area">
            <h3>Holidays</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Holidays</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
            <div className="row dashboard-card-six">
              <Holidays />
                <AddHoliday />

              </div>
          </>       
        );
}

export default AllHolidays;
