import React, {useState,useEffect,useMemo} from 'react';
import { MDBBadge  } from 'mdbreact';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { useSelector,useDispatch } from 'react-redux'

import { fetchAllCategories, updateCategory } from '../Redux/AllCategories/AllCategoriesActions';
import { Link } from "react-router-dom";
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";

function AllExpense(){
  // store data access start

const allCategoriesData = useSelector(state =>state.AllCategories)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [allExpenseResult,setAllExpenseResult] = useState([])
  const [allCategoriesResult,setAllCategoriesResult] = useState([])
  const [allExpenseInfo,setAllExpenseInfo] = useState([])
  const [categoryStatus,setCategoryStatus] = useState('ACTIVE')
  const [currentcatstaus,setCurrentcatstaus] = useState(true)
  const [activestate,setActivestate] = useState('')
  const [row,setRow] = useState([])
  const [editCatMode,setEditCatMode] = useState(false)
  const [editSelectedCat,setEditSelectedCat] = useState(null)
  const [inputValues,setInputValues] = useState({
                                          catID:null,
                                          catName:"",
                                          catDes:"",
                                          orderIndex:"",
                                          catStatus:"",
  })
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 20,
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Expense Type',
        field: 'expensetype',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Phone',
        field: 'phone',
        sort: 'asc',
        width: 1000
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 100
      }
    ],
    rows: []
  });
  // component all states define End

   //hooks start
   // fetch allExpense  api hook start
   useEffect(() =>{
     dispatch(fetchAllCategories())
   },[dispatch])
// fetch allExpense  api hook End

// add data of allAssignBook api into constant,hook start
   useMemo(() =>{
     if(allCategoriesData && allCategoriesData.all_categories){
       setAllCategoriesResult(allCategoriesData.all_categories.data)
     }
   },[allCategoriesData])
// add data of allAssignBook api into constant,hook End

// when allExpenseResult data change than data add into constant,hook start
   useMemo(() =>{
     if(allExpenseResult && allExpenseResult.data){
       setAllExpenseInfo(allExpenseResult.data)
     }
   },[allExpenseResult])
// when allExpenseResult data change than data add into constant,hook End

//when allExpenseInfo data change than data add into constant,hook start
useMemo(()=>{
  if(allExpenseInfo && allExpenseInfo.length > 0){
    let arrray = []
    allExpenseInfo.map((item,index)=>{
      let new_object = {
        id:item.Expenseid,
        name: item.Name,
        expensetype:item.ExpenseType,
        amount:item.Amount,
        status:(
        <MDBBadge pill color={item.Status == "Paid" ? 'success' : 'danger'} className={`p-7 ${item.Status == "Paid" ? 'pl-17 pr-17' : 'pl-10 pr-10'}`} key={index} searchvalue={item}>
          {item.Status}
        </MDBBadge>
      ),
        phone:item.Phone,
        email:item.Email,
        date:item.Date
      }
   
      arrray.push(new_object)
    })
 
    setRow(arrray)
  }

},[allExpenseInfo])
//when allExpenseInfo data change than data add into constant,hook end

//when row data change than data add into constant,hook start
useMemo(() =>{
  if(row && row.length > 0){
    setDatatable({...datatable,rows:row})
  }
},[row])
//when row data change than data add into constant,hook end
   //hooks end

// component function start

  const callbackFunction = (childData) => {
    setActivestate(childData)
}
// component function End

// examHandler function start
   const editCatHandler = (event) =>{
   event.preventDefault()
   const category_info = inputValues;
   dispatch(updateCategory(category_info))
   setEditCatMode(false);
   setEditSelectedCat(null);
     //dispatch(addTiming(exam_info))
    //  dispatch(fetchAllRestTimings())
 }
// examHandler function End

// examHandler function start
   const editCatStatusClick = (e) =>{
    
  setInputValues({...inputValues, catStatus:e.target.value});
  
 }
// examHandler function End

// examHandler function start
   const editCatClick = (item) =>{
   
  setInputValues({catID:item.categoryId,
  catName:item.categoryName,
  catDes:item.descriptions,
  orderIndex:item.orderIndex,
  catStatus:item.status});
   setEditCatMode(true);
   setEditSelectedCat(item.categoryId);
  
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start

const onChangeValue = (e) => {
    setCategoryStatus(e.target.value);
    setCurrentcatstaus(!currentcatstaus)
  }



        return (
            <div id="wrapper" className={activestate ? 'wrapper bg-ash sidebar-collapsed': 'wrapper bg-ash'}>
        {/* Header Menu Area Start Here */}
        <Header parentCallback = {() =>callbackFunction()}/>
        {/* Header Menu Area End Here */}
        {/* Page Area Start Here */}
        <div className="dashboard-page-one">
          {/* Sidebar Area Start Here */}
          <Sidebar />
          {/* Sidebar Area End Here */}
          <div className="dashboard-content-one">
            {/* Breadcubs Area Start Here */}
            <div className="breadcrumbs-area">
              <h3>Categories</h3>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                <a href="/">All Categories</a>
                </li>
              </ul>
            </div>

            <div className="catstatusdiv" onChange={(e)=>onChangeValue(e)}>
       <input type="radio" value="ACTIVE" name="status" checked={currentcatstaus}/> Active Categories
       <input type="radio" value="INACTIVE" name="status" /> Inactive Categories
     </div>
            {/* Breadcubs Area End Here */}
            {/* Expanse Table Area Start Here */}
            <div className="row">
            <div className="col-12-xxxl col-12">
            <div className="mainbox">
            {/* Edit Cat Data Starts*/}

            {editCatMode && editCatMode === true && editSelectedCat !== null  ? (
              <div className="card-body">
                <div className="heading-layout1">
                  <div className="item-title">
                    <h3>Edit Category</h3>

                  </div>

                </div>
                <form className="new-added-form" onSubmit={(e) =>editCatHandler(e)}>
                  <div className="row">
                    <div className="col-12-xxxl col-lg-6 col-12 form-group">
                      <label>Category Name</label>
                      <input type="text" placeholder className="form-control" value={inputValues.catName} onChange={(e) =>setInputValues({...inputValues,catName:e.target.value})} required/>
                    </div>
                    <div className="col-12-xxxl col-lg-6 col-12 form-group">
                      <label>Category Description</label>
                      <input type="text" placeholder className="form-control" value={inputValues.catDes} onChange={(e) =>setInputValues({...inputValues,catDes:e.target.value})} required/>
                    </div>
                    <div className="col-12-xxxl col-lg-6 col-12 form-group">
                      <label>Order Index</label>
                      <input type="number" placeholder className="form-control" value={inputValues.orderIndex} onChange={(e) =>setInputValues({...inputValues,orderIndex:e.target.value})} required/>
                    </div>
                    <div className="col-12-xxxl col-lg-6 col-12 form-group">
                    <FormGroup>
                        <FormLabel>Select Status</FormLabel>
                        <FormControl
                          required
                          type="text"
                          onChange={(e) => editCatStatusClick(e)}
                          as="select"
                        >
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>

                        </FormControl>
                      </FormGroup>
                    </div>
                    <div className="col-12 form-group mg-t-8">
                      <button type="submit" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Save</button>
                      <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>resetHandler(e)}>Reset</button>
                    </div>
                  </div>
                </form>
              </div>
            ) : null}
            {/* Edit Cat Data Ends */}


            {allCategoriesData && allCategoriesData.all_categories_loading === true ?
              (<div className={editCatMode && editCatMode === true ? 'card-body hidden' : 'card-body'}>
              <div className="Student-Loader">
              <Loader
              className = "student-detail-loader"
            type="ThreeDots"
            color="#fea801"
            height={150}
            width={150}

/>
              </div>
            </div>) : (<div className={editCatMode && editCatMode === true ? 'table-responsive expenses-table-box hidden' : 'table-responsive expenses-table-box'}>

          <table className="table data-table text-nowrap">
              <thead>
                <tr>

                  <th>Category Name</th>
                  <th>Category Status</th>
                  <th>Products</th>
                  <th>Action</th>


                </tr>
              </thead>
              <tbody>
              {allCategoriesResult && allCategoriesResult.length > 0 && allCategoriesResult.filter(list => list.status === categoryStatus).map((item,index)=>{
                if(item.status === categoryStatus) {
                return (
                  <tr>

                    <td>{item.categoryName}</td>

                    <td>{item.status}</td>

                    <td>          <Link
            to={{
              pathname: "/products",
              state: { cat_Id: item.categoryId, cat_Name:item.categoryName }
            }}
          ><button className="fw-btn-fill btn-gradient-yellow"><i className="fas fa-eye"></i> View Products</button></Link></td>
                    <td><a onClick={e=>editCatClick(item)}><i className="fas fa-edit"></i> </a></td>
                    <td className="hidden">
                      <div className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          <span className="flaticon-more-button-of-three-dots" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" style={{display:'none'}}>
                          <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red" />Close</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green" />Edit</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel" />Refresh</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }
              })}


              </tbody>
            </table>
          </div>)}
            </div>
            </div>
            </div>
            {/* Expanse Table Area End Here */}
            <Footer />
          </div>
        </div>
        {/* Page Area End Here */}
      </div>
        );
}

export default AllExpense;
