import React,{useState}from 'react';
import { Link } from 'react-router-dom';
import AddEvent from './AddEvent';
import { Card } from 'react-bootstrap';
import Events from './Events';
import Coupons from './Coupons';
import AddCoupons from './AddCoupons';
import AllAddonGroups from './AllAddonGroups';
import AddAddonGroup from './AddAddonGroup';



function AllCoupons() {
        return (        
          <div>
          <div className="breadcrumbs-area">
            <h3>Coupons</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Coupons</li>
            </ul>
          </div>


         
          <div className="row">
          <Coupons/>
          <AddCoupons />
            </div>
      
          
          </div>
        );
}

export default AllCoupons;
