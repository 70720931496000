import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';

import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector,useDispatch } from 'react-redux'

import { addTiming } from '../Redux/AddTiming/AddTimingActions'
import {fetchAllRestTimings} from '../Redux/AllRestTimings/AllRestTimingsActions'
import moment from 'moment';
function AddTiming(){
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [inputValues,setInputValues] = useState({
                                          openingTime:"00:00",
                                          closingTime:"18:00",
                                          timingName:"",
                                          openingDay:"",
                                          closingDay:"",
                                          timingType:""

  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)




  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
   useMemo(()=>{
     if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.error){
       setError(addExam_data.add_timing.error)
     }
     else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.success){
        setSuccessStatus(addExam_data.add_timing.success)
     }
   },[addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
     
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End

// examHandler function start
   const examHandler = (event) =>{
   event.preventDefault()
   const exam_info = inputValues;
     dispatch(addTiming(exam_info))
      dispatch(fetchAllRestTimings())
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


// resetHandler function start
   const handleTimeChange = (value) =>{

    const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
    setInputValues({...inputValues,openingTime:selectedTime})
   
   }

   const handleClosingTimeChange = (value) =>{

    const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
    setInputValues({...inputValues,closingTime:selectedTime})
    
   }
// resetHandler function start

// component function end

        return (
          <>

<div className="card-body mx-width-600">

<div className="heading-layout1">
  <div className="item-title">

  </div>

</div>




<div className="pad-5 bg-darkblue height-50">
  <ul className="nav nav-tabs justify-content-center" role="tablist">
    <li>
      <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">Contact Us Details</a>
    </li>
  </ul>
</div>



<div className="tab-content single-info-details mx-width-600">
  <div role="tabpanel" className='tab-pane active show' id="billing">
 
  
                   
  
                    <form className="new-added-form" onSubmit={(e) =>examHandler(e)}>
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Your Name *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.timingName} onChange={(e) =>setInputValues({...inputValues,timingName:e.target.value})} required/>
                          {error != null && error.timingName ? (<h6 className="addStudent-error">*{JSON.stringify(error.timingName).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Your Email *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.timingName} onChange={(e) =>setInputValues({...inputValues,timingName:e.target.value})} required/>
                          {error != null && error.timingName ? (<h6 className="addStudent-error">*{JSON.stringify(error.timingName).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Your Phone Number *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.timingName} onChange={(e) =>setInputValues({...inputValues,timingName:e.target.value})} required/>
                          {error != null && error.timingName ? (<h6 className="addStudent-error">*{JSON.stringify(error.timingName).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Message *</label>
                          <input type="textarea" placeholder className="form-control" value={inputValues.timingName} onChange={(e) =>setInputValues({...inputValues,timingName:e.target.value})} required/>
                          {error != null && error.timingName ? (<h6 className="addStudent-error">*{JSON.stringify(error.timingName).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>


                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                          <button type="submit" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Submit</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        </div>
                      </div>
                    </form>
                
           
              
  </div>       
 

</div>




</div>
    

              </>
        );
}

export default AddTiming;
