import React, { useState, useEffect, useMemo } from 'react';
import { Link,useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from 'react-redux'


import { Button, Pagination } from 'react-bootstrap';
import { fetchAllProducts, fetchsingleProductFaliure, fetchsingleProductRequest, fetchsingleProductSuccess } from '../Redux/AllProduct/AllProductsActions';
import EditProduct from './EditProduct';

import { fetchProductsFaliure, fetchProductsRequest, fetchProductsSuccess, fetchcategory, fetchsingleproduct } from '../Redux/AllCategories/AllCategoriesActions';
import { config } from '../Redux/config';
import DirectEditProduct from './DirectEditProduct';



function Product() {

  const history = useHistory();

  const categoryadd = () => {  
    history.push({
       pathname: "/addcategory",  
    })
  }


  const productadd = () => {  
    history.push({
       pathname: "/addproduct",  
    })
  }


  
  const [currentPage, setCurrentPage] = useState(localStorage.getItem("product_page") || 0);
  const [totalPages, setTotalPages] = useState(0);
  const [data1, setData1] = useState();
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch() 
  useEffect(() => {
    console.log("currentPage==>"+currentPage)
    dispatch(fetchAllProducts(totalPages, currentPage))
  }, [dispatch])

  
  const allProductsData = useSelector(state => state.AllProduct);
  console.log(allProductsData);
 
  const [productData,setProductData] = useState('');

  useMemo(() =>{
    if(allProductsData && allProductsData.all_products && allProductsData.all_products.data){
      setProductData(allProductsData.all_products.data)
      setData1(allProductsData.all_products.data)   
    } else {
      setProductData('')
    }
   
  },[allProductsData])



  useEffect(() => {
    if (data1 && data1.length > 0) {
    setTotalPages(Math.ceil(allProductsData.all_products.total / pageSize));
    }
    }, [data1, pageSize]);

    // try loader start

    const [loading, setLoading] = useState(false);

    // const onPageChange = (category_info,currentPage) => {
    //   // set loading to true
    //   setLoading(true);
    //   // dispatch the fetchAllProducts action
    //   dispatch(fetchAllProducts(category_info,totalPages, currentPage));
    //   };

  //   const onPageChange = (category_info,currentPage) => {
  //     setCurrentPage(currentPage);
  //     setLoading(true);
  // dispatch(fetchAllProducts(category_info,totalPages,currentPage))
  //     };
    
    const onPageChange = (currentPage) => {
    setCurrentPage(currentPage);
    localStorage.setItem("product_page",currentPage)
dispatch(fetchAllProducts(totalPages,currentPage))
    };


    // try loader ends

    const renderData = () => {
      if (data1) {
      const slicedData = data1.slice(0, pageSize);
      return slicedData.map((item, index) => {
      return (
        
      <tr key={item.productId} onClick={(e)=>editProductClick(item)} data-toggle="modal" data-target="#exampleModal1">
      <td>
      <img src={item.image} height={50} width={50} />
      </td>
      <td>{item.name}</td>
      <td>{item.descriptions}</td>
     
      {/* {item.categoryObj.length > 0 ? (
<td>
<span>{item.categoryObj.map(obj => obj.categoryName).join(", ")}</span>
</td>
) : (
<td></td>
)} */}

{item.categoryObj ? (
item.categoryObj.length > 0 ? (
<td>
<span>{item.categoryObj.map(obj => obj.categoryName).join(", ")}</span>
</td>
) : (
<td></td>
)
) : (

<td></td>
)}

      <td>${item.price}</td>
      {/* <td>

<DirectEditProduct/>
</td> */}
      </tr>
      );
      });
      } else {
      return <tr><td colSpan="6"><div className="Student-Loader">
      <Loader
      className = "student-detail-loader"
    type="ThreeDots"
    color="#fea801"
    height={150}
    width={150}

/>
      </div></td></tr>;
      }
      };
      
      const renderPagination = () => {
      let items = [];
      for (let i = 0; i < totalPages; i++) {
        if (i == currentPage || i == 0 || i == (parseInt(currentPage)-1) || i == (parseInt(currentPage)+1) || i == (totalPages-1)) items.push(
      <Pagination.Item
      key={i}
      active={i == currentPage}
      onClick={() => onPageChange(i)}
      >
      {(i == totalPages-1)?`...${(i+1)}`: i == 0 ?`${(i+1)}...`:(i+1)}
      </Pagination.Item>
      );
      }
      return (
      <Pagination style={{ overflowX: "auto" }}>
      <Pagination.Prev
      disabled={currentPage === 0}
      onClick={() => onPageChange(currentPage - 1)}
      />
      {items}
      <Pagination.Next
      disabled={currentPage === totalPages-1}
      onClick={() => onPageChange(currentPage + 1)}
      />
      </Pagination>
      );
      };
      // end


  const [allProductsResult, setAllProductsResult] = useState([])
  const [data,setData] = useState([])
  useMemo(() =>{
    if(allProductsData && allProductsData.all_products && allProductsData.all_products.data && allProductsData.all_products.data.length > 0){
      setAllProductsResult(allProductsData.all_products.data)
      setData(allProductsData.all_products.data)
    }
    else if(allProductsData && allProductsData.all_products && allProductsData.all_products.data && allProductsData.all_products.data.length > 0){
      setAllProductsResult(allProductsData.all_products.data)
      setData(allProductsData.all_products.data)
    }
  },[allProductsData])

  // pagination


// 
const { state } = useLocation()
//  dispatch(fetchsingleproduct(item.productId));

const [show,setShow] = useState(false);
// dispatch(fetchsingleproduct(item.productId));
  




const editProductClick = (item) => {
  // Use item.productId instead of state.productId
 
  // console.log(dispatch(fetchsingleproduct(item.productId)));
  // dispatch(fetchsingleproduct(state.productId));
  history.push({
    pathname: "/direct_edit_product",
  state: editProduct(item),
  // productId: item.productId,
  });
  // dispatch(fetchcategory(item.productId));
 
  // dispatch(fetchsingleproduct(productId)); // Make sure productId is properly passed here
  // console.log("Dispatching fetchsingleproduct with productId:", productId);
  console.log(editProduct(item));
  console.log(item);
  console.log(item.productId);
  setShow(true);
  };


const [productAddonsResult, setProductAddonsResult] = useState([]);
const [productAddonsloaded, setProductAddonsloaded] = useState('')


const [indexState, setIndexState] = useState(0)
const editProduct = (item,catID) => {
  let product = {
   
    productId: item.productId,
    name: item.name,
    description: item.descriptions,
    shortDescription: item.shortDescription,
    price: item.price,
    image: item.image,
    ... (item.categoryObj && item.categoryObj[0] && { categoryName: item.categoryObj[0].categoryName }),
    stockStatus: item.inStock,
    productStatus: item.productStatus
  };
  return product;
 
}


useMemo(() => {
  if (productData && productData.single_product) {
    setIndexState(0)
    setProductAddonsloaded(false)
    setProductAddonsResult(productData.single_product.data);
    if (productData.single_product_loaded) {
      setProductAddonsloaded(true);
    }
  }
}, [productData])

// message




  return (
 <>

  <div className="breadcrumbs-area">
 
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>All Products</li>
        </ul>
        <div className="heading-layout1">
                  <div className="item-title">
                    <h3>Add Category & Add Product</h3>

                  </div>

                </div>  
<div className='row'>
        <div className="col-6-xxxl col-12 col-md-6">     
             <Button onClick={(e)=>categoryadd()} className="btn-fill-lg bg-blue-dark btn-hover-yellow" size="lg">Add Categories</Button>
          </div>
     
          <div className="col-6-xxxl col-12 col-md-6">
           <Button onClick={(e)=>productadd()} className="btn-fill-lg bg-blue-dark btn-hover-yellow float-right" size="lg">Add Products</Button>
          </div>
          </div>
      </div>


   {/* product list  */}
   {allProductsData && allProductsData.all_products_loading === true ?
                            (
                              <div className="">
                              <div className="Student-Loader">
                              <Loader
                              className = "student-detail-loader"
                            type="ThreeDots"
                            color="#fea801"
                            height={150}
                            width={150}

               />
                              </div>
                            </div>
                              
                        
                          )  : 
                              (
                                <div className="table-responsive expenses-table-box">
                                <div style={{marginTop:"10px;"}}>
                   <table id="example" class="table table-striped table-bordered" style={{width:"100%"}}>
    <thead style={{
        backgroundColor: "#042954",
        color: "#fff"
    }}>
        <tr>
       
     <th class="th-sm">Image
    
    </th>
          <th class="th-sm" style={{width:"10%"}}>Product Name
    
          </th>
          <th class="th-sm">Description
    
          </th>
         
          <th class="th-sm">Category
    
          </th>
          <th class="th-sm" style={{width:"5%"}}>Price
    
          </th>
          
        </tr>
      </thead>
      <tbody>{renderData()}</tbody>
        </table>


    </div> 
                             
    
                                
    
                              </div>
                            )
                              }

    

<div className='row'>
        <div className="col-12-xxxl col-12">     
         <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
        
            </div>
       
          </div>

  
    </>

  );
}

export default Product;

