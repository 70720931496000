import {
  REST_SHUT_REQUEST,
  REST_SHUT_SUCCESS,
  REST_SHUT_FALIURE,
  REST_POST_SHUT_REQUEST,
  REST_POST_SHUT_SUCCESS,
  REST_POST_SHUT_FALIURE,
  REST_POST_SHUT2_REQUEST
} from './RestShutConstants'
import {config} from '../config'

export const restShutRequest = (rest_shut_info) =>{
  return{
    type : REST_SHUT_REQUEST,
    payload : rest_shut_info
  }
}

export const restShutSuccess = (rest_shut_success) =>{
  return{
    type : REST_SHUT_SUCCESS,
    payload : rest_shut_success
  }
}

export const restShutFaliure = (error) =>{
  return{
    type : REST_SHUT_FALIURE,
    payload : error
  }
}

export const restPostShutRequest = (rest_shut_info) =>{
  return{
    type : REST_POST_SHUT_REQUEST,
    payload : rest_shut_info
  }
}

export const restPostShut2Request = (rest_shut_info) =>{
  return{
    type : REST_POST_SHUT2_REQUEST,
    payload : rest_shut_info
  }
}

export const restPostShutSuccess = (rest_shut_success) =>{
  return{
    type : REST_POST_SHUT_SUCCESS,
    payload : rest_shut_success
  }
}

export const restPostShutFaliure = (error) =>{
  return{
    type : REST_POST_SHUT_FALIURE,
    payload : error
  }
}

export const restShut = (rest_shut_info) =>{
    return(dispatch) => {
      dispatch(restShutRequest(rest_shut_info))
      const url =`${config.api_root}/api/v1/users/shut?user_id=${config.user_id}`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(rest_shut_res =>{
      const rest_shut_success = rest_shut_res
      dispatch(restShutSuccess(rest_shut_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(restShutFaliure(errorMsg))
    })
    }
}

export const restShut2Update = (rest_shut_info) =>{
  return(dispatch) => {
    dispatch(restPostShut2Request(rest_shut_info))
  }
}

export const restShutUpdate = (rest_shut_info) =>{
    return(dispatch) => {
      dispatch(restPostShutRequest(rest_shut_info))
      const url =`${config.api_root}/api/v1/users/shut?user_id=${config.user_id}&shut=${rest_shut_info}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(rest_shut_res =>{
      const rest_shut_success = rest_shut_res
      if(rest_shut_success.success==='true'){
        let res = {
          success:rest_shut_success.success,
          status: rest_shut_info
        }
        dispatch(restPostShutSuccess(res))
      }
    })
    .catch(error => {
      const errorMsg = error
      dispatch(restPostShutFaliure(errorMsg))
    })
    }
}
