import React, {useState,useEffect,useRef,useMemo} from 'react';

import PrintCommon from './PrintCommon';
import {wordwrap} from './Commons';

import { useSelector,useDispatch } from 'react-redux';
import { fetchAllBook,acceptOrder, declineOrder } from '../Redux/AllBook/AllBookActions';
import { restShut, restShutUpdate } from '../Redux/RestShut/RestShutActions';
import { deliveryShut, deliveryShutUpdate } from '../Redux/DeliveryShut/DeliveryShutActions';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";

import moment from 'moment';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function Orders(){

const [restOnOff,setRestOnOff] = useState(false)
const [deliveryOnOff,setDeliveryOnOff] = useState(false)
const allBookData = useSelector(state =>state.AllBook)
const restStateData = useSelector(state =>state.RestShutStatus)
const deliveryStateData = useSelector(state =>state.DeliveryShutStatus)
const [startDate, setStartDate] = useState();
const [endDate, setEndDate] = useState();
const [error,setError] = useState(null)
// store data access End
const dispatch = useDispatch()  // for accessing the redux function
const history = useHistory();
const [allBookResult,setBookResult] = useState([])

const [currentorderTab,setCurrentorderTab] = useState(2)
const [pageNumber, setPageNumber] = useState(0)
  const [singleOrder, setSingleOrder] = useState([])
  const listInnerRef = useRef();


// fetch allBook  api hook start
useEffect(() =>{
  dispatch(fetchAllBook(0, false))
  dispatch(restShut())
  dispatch(deliveryShut())
},[dispatch])
// fetch allBook api hook End



// add data of allFees api into constant,hook start
useMemo(() =>{
  if(allBookData && allBookData.all_book && allBookData.all_book && !allBookData.all_book_loading){
    setError('');
    if(allBookData.is_added){
      allBookData.all_book.forEach((item) => {
        if(allBookResult.indexOf(item) == -1){
          setBookResult(allBookResult.concat(allBookData.all_book));
        }
      });
    } else if(!allBookData.is_added) {
      setBookResult(allBookData.all_book)
    }
  }
},[allBookData])
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(restStateData && restStateData.rest_shut && restStateData.rest_shut.status){
    setRestOnOff(restStateData.rest_shut.status)
  }
},[restStateData])
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(deliveryStateData && deliveryStateData.delivery_shut && deliveryStateData.delivery_shut.status){
    setDeliveryOnOff(deliveryStateData.delivery_shut.status)
  }
},[deliveryStateData])
// add data of allFees api into constant,hook End

const onScroll = () => {
  if (listInnerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
   
    if (Math.ceil(scrollTop + clientHeight) == scrollHeight) {
     
      if(startDate && endDate){
        dispatch(fetchAllBook(pageNumber+1, true, {
           dateFilter: {
             startDate: moment(startDate).format('DD-MM-YYYY'),
             endDate: moment(endDate).format('DD-MM-YYYY'),
           },
           filter: currentorderTab
        }))
      } else {
        dispatch(fetchAllBook(pageNumber+1, true, {
          filter: currentorderTab
        }))
      }
      setPageNumber(pageNumber+1);
      // This will be triggered after hitting the last element.
      // API call should be made here while implementing pagination.
    }
  }
};

const handleDateChange = (value) =>{
  const selectedData = moment(value).format('DD-MM-YYYY');

 //setInputValues({...inputValues,from_date:selectedData})
 setStartDate(value)
}

const handleEndDateChange = (value) =>{

  const selectedData = moment(value).format('DD-MM-YYYY');

 //setInputValues({...inputValues,to_date:selectedData})
 setEndDate(value)

}


const handlefilterSelect = (e) => {
  setPageNumber(0);
  if(e === 'all') {
    setCurrentorderTab(0);
  }
  else {
    setCurrentorderTab(e);
  }
  if(startDate && endDate) {
    if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
    else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
    else dispatch(fetchAllBook(0, false, {
      dateFilter: {
        startDate: moment(startDate).format('DD-MM-YYYY'),
        endDate: moment(endDate).format('DD-MM-YYYY'),
      },
      filter: e
    }))
  } else {
     dispatch(fetchAllBook(0, false, {filter:e}))
  }
}

const handleOrderSelect = (orderNo) => {
  history.push({
    pathname: "/order_detail",
    state: {order: orderNo, isNotify: false}
  })
}


const setrestStatus = (event) =>{
  setRestOnOff(!restOnOff)
    dispatch(restShutUpdate(!restOnOff))
}

const setdeliveryStatus = (event) =>{
  setDeliveryOnOff(!deliveryOnOff)
    dispatch(deliveryShutUpdate(!deliveryOnOff))
}

const handleacceptOrder = (order_id) =>{
  dispatch(acceptOrder(order_id))
}

const filterOrders = () =>{
  setPageNumber(0);
  if(startDate && endDate){
   if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
   else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
   else dispatch(fetchAllBook(0, false, {
      dateFilter: {
        startDate: moment(startDate).format('DD-MM-YYYY'),
        endDate: moment(endDate).format('DD-MM-YYYY'),
      },
      filter: currentorderTab
    }))
  }
}

const clearOrders = () =>{
  setStartDate();
  setEndDate();
  dispatch(fetchAllBook(0, false, {filter:currentorderTab}))
}

const handledeclineOrder = (order_id) =>{
dispatch(declineOrder(order_id))
}

        return (

          <div className="dashboard-content-one">

        {/* Dashboard Content Start Here */}
      <div className="row gutters-20">
        <div className="col-12 col-xl-12 col-12-xxxl">
          <div className="card dashboard-card-one pd-b-20">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                <h3>{singleOrder && singleOrder.length > 0 ? 'Order Details' : 'All Orders'}</h3>
                </div>
                <div className="dropdown">
                  <a className="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false"></a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red" />Close</a>
                    <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green" />Edit</a>
                    <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel" />Refresh</a>
                  </div>
                </div>
              </div>

              {allBookData.all_book_error ? (<span style={{color:'red'}}>{allBookData.all_book_error.error}</span>): (<></>)}
              {error ? (<span style={{color:'red'}}>{error}</span>): (<></>)}

              <div className="row">
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                         <label>Start Date</label>
                        <DatePicker selected={startDate} className="form-control" onChange={(date) => handleDateChange(date)} />
                          {error != null && error.from_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.from_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                          <label>End Date</label>
                        <DatePicker selected={endDate} className="form-control" onChange={(date) => handleEndDateChange(date)} />
                          {error != null && error.to_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.to_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-6-xxxl col-lg-4 col-12 form-group mg-t-35">
                       <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>filterOrders()}>Apply Filter</button>             
                       <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow mg-l-10" onClick={(e) =>clearOrders()}>Clear</button>
                    </div>
               </div>

              <div className="earning-report1">
              
                  <div className="card">
                      <div className="card-body">

                        <div className="table-box-wrap">

                            <div className="row gutters-8">


                              <div className="col-lg-8 form-group filterbtn">
                                <button type="submit" onClick={e=>handlefilterSelect(2)} className={currentorderTab === 2 ? "btn btn-outline-primary active" : "btn btn-outline-primary"}>New</button>
                                <button type="submit" onClick={e=>handlefilterSelect(4)} className={currentorderTab === 4 ? "btn btn-outline-primary active" : "btn btn-outline-primary"}>Completed</button>
                                <button type="submit" onClick={e=>handlefilterSelect(17)} className={currentorderTab === 17 ? "btn btn-outline-primary active" : "btn btn-outline-primary"}>Cancelled</button>
                              </div>
                              <div className="col-lg-4 form-group">

                              </div>
                            </div>


                          {allBookData && allBookData.all_book_loading === true ?
                          (
                          <div style={{marginLeft:'40%'}}>
                            <Loader
                              className = "student-detail-loader"
                              type="ThreeDots"
                              color="#fea801"
                              height={80}
                              width={150}

                            /></div>
                          ) : <></>}
                        <div className="table-responsive expenses-table-box">
                       <div onScroll={onScroll} ref={listInnerRef} className="table-height">

                          <table className="table data-table text-nowrap">
                              <thead>
                                <tr>
                                  <th>
                                    Order ID
                                  </th>
                                  <th>Order Total</th>
                                  <th>Order Status</th>
                                  <th>Name</th>
                                  <th>E-Mail</th>
                                  <th>Order Date</th>

                                </tr>
                              </thead>
                              <tbody>
                              {allBookResult && allBookResult.length > 0 && allBookResult.map((item,index)=>{
                                return (
                                  <tr>
                                    <td>
                                    <a className="orderclick" onClick={e=>handleOrderSelect(item.order_id)}>{item.order_id}</a>
                                    </td>

                                    <td>${item.order_total ? item.order_total : ''}</td>
                                    {item.order_status === 'Processing' ? <td className="badge badge-pill badge-primary d-block mg-t-8">New</td> : null}
                                    {item.order_status === 'Order AHead' ? <td className="badge badge-pill badge-primary d-block mg-t-8 futureorder">Order Ahead</td> : null}
                                    {item.order_status === 'Complete' ? <td className="badge badge-pill badge-success d-block mg-t-8">Completed</td> : null}
                                    {item.order_status === 'Cancelled' ? <td className="badge badge-pill badge-danger d-block mg-t-8">Cancelled</td> : null}
                                    {item.order_status === 'Pending Payment' ? <td className="badge badge-pill badge-secondary d-block mg-t-8">Pending Payment</td> : null}
                                    {item.order_status !== 'Order AHead' && item.order_status !== 'Processing' && item.order_status !== 'Complete' && item.order_status && 'Cancelled' && item.order_status !== 'Pending Payment' ? 'N/A' : null}
                                    <td>{item.billing_details.first_name} {item.billing_details.last_name}</td>
                                    <td>{item.billing_details.email}</td>
                                    <td>{item.order_date}</td>
                                    <td className="hidden">
                                      <div className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                          <span className="flaticon-more-button-of-three-dots" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" style={{display:'none'}}>
                                          <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red" />Close</a>
                                          <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green" />Edit</a>
                                          <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel" />Refresh</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}


                              </tbody>
                            </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                
              </div>

            </div>
          </div>
        </div>



      </div>
      {/* Dashboard Content End Here */}

      </div>
        );
}

export default Orders;
