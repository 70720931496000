import {
  WAKE_UP_REQUEST,
  WAKE_UP_SUCCESS,
  WAKE_UP_FALIURE
} from './WakeUpSettingsConstants'

const intialstate = {
  wake_up_loaded : true,
  wake_up : '',
  wake_up_error : '',
  wake_up_success : false
}

const WakeUpSettingsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case WAKE_UP_REQUEST : return{
      ...state,
      wake_up_loaded:false
    }
    case WAKE_UP_SUCCESS : return{
      ...state,
      wake_up_loaded:true,
      wake_up_success:true,
      wake_up:action.payload
    }
    case WAKE_UP_FALIURE : return{
      ...state,
      wake_up_loaded:true,
      wake_up_error:action.payload
    }
    default: return state
  }

}

export default WakeUpSettingsReducers
